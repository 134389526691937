import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[orgbrainBlurOnInit]',
  standalone: true,
})
export class BlurOnInitDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef, private cftf: ConfigurableFocusTrapFactory) {
    console.log('orgbrainBlurOnInit');
  }

  ngAfterViewInit() {
    console.log('orgbrainBlurOnInit ngAfterViewInit');
    const focusTrap = this.cftf.create(this.elementRef.nativeElement);
    // eslint-disable-next-line promise/catch-or-return
    Promise.resolve().then(() => focusTrap.focusLastTabbableElement());
  }
}

// Clean up the focus trap
