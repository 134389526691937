import { CompanyAcronymsEnum, Role, RoleByTime } from '@orgbrain/roles';
import { MongoId } from './chatChannel';
import { CredentialEnum, IdentityType } from './session';
import { IUser } from './user';

export enum ImplicitVotingSettings {
  IMPLICIT_FOR = 'IMPLICIT_FOR',
  NO_IMPLICIT_FOR = 'NO_IMPLICIT_FOR',
}

export enum AgendaItemStylesSettings {
  AGENDA_STYLE_NUMBER = 'AGENDA_STYLE_NUMBER',
  AGENDA_STYLE_ITEM_NUMBER = 'AGENDA_STYLE_ITEM_NUMBER',
  AGENDA_STYLE_CONSECUTIVE_NUMBER = 'AGENDA_STYLE_CONSECUTIVE_NUMBER',
  AGENDA_STYLE_ITEM_CONSECUTIVE_NUMBER = 'AGENDA_STYLE_ITEM_CONSECUTIVE_NUMBER',
  AGENDA_STYLE_NUMBER_MEETINGN_YEAR = 'AGENDA_STYLE_NUMBER_MEETINGN_YEAR',
  AGENDA_STYLE_ITEM_NUMBER_MEETINGN_YEAR = 'AGENDA_STYLE_ITEM_NUMBER_MEETINGN_YEAR',
  AGENDA_STYLE_ITEM_CONSECUTIVE_NUMBER_MEETINGN_YEAR = 'AGENDA_STYLE_ITEM_CONSECUTIVE_NUMBER_MEETINGN_YEAR',
  AGENDA_STYLE_ITEM_NO_NUMBER_AT_ALL = 'AGENDA_STYLE_ITEM_NO_NUMBER_AT_ALL',
}

export enum OrgLanguage {
  EN = 'EN',
  NO = 'NO',
  SE = 'SE',
}

export enum OrgJurisdiction {
  NO = 'NO',
  SE = 'SE',
  DK = 'DK',
}

export type IUserRoles = {
  _id: MongoId;
  organization: MongoId;
  user: MongoId | IUser;
  roles: Role[];
  rolesByTime: RoleByTime[];
  manualId: MongoId;
  invited: boolean;
};

export enum DiscountType {
  DISCOUNT_CASH_TOTAL = 'DISCOUNT_CASH_TOTAL',
  DISCOUNT_PERCENT_TOTAL = 'DISCOUNT_PERCENT_TOTAL',
  DISCOUNT_USER_COUNT_GENERAL = 'DISCOUNT_USER_COUNT_GENERAL',
  DISCOUNT_USER_COUNT_SUBGROUP = 'DISCOUNT_USER_COUNT_SUBGROUP',
  DISCOUNT_USER_COUNT_ROLE_BASED = 'DISCOUNT_USER_COUNT_ROLE_BASED',
}

export interface DiscountItem {
  discountType: DiscountType;
  discountAmount: number;
  discountDurationMonths: number;
  discountAddedTimestamp: Date;
  discountRole: Role;
  dicountAmountCalculated?: number;
}

export enum BillingPackageID {
  TEST = 'TEST',
  TEST2024 = 'TEST2024',
  SHAREHOLDER_BASIS = 'SHAREHOLDER_BASIS',
  SHAREHOLDER_PRO = 'SHAREHOLDER_PRO',
  DEPENDENT = 'DEPENDENT',
  SUSPENDED = 'SUSPENDED',
  GRUNDER = 'GRUNDER',
  SMALL_BUSINESS = 'SMALL_BUSINESS',
  FOUNDER = 'FOUNDER',
  SMALL_BUSINESS2 = 'SMALL_BUSINESS2',
  BASIS = 'BASIS',
  ENTERPRISE = 'ENTERPRISE',
  BOARDSCHOOL = 'BOARDSCHOOL',
  ACADEMY_2025 = 'ACADEMY_2025',
  BOARD_EVALUATION = 'BOARD_EVALUATION',
  NIF_START = 'NIF_START',
  NIF_PRO = 'NIF_PRO',
  FOUNDER2024 = 'FOUNDER2024',
  SMALL_BUSINESS2024 = 'SMALL_BUSINESS2024',
  STANDARD2024 = 'STANDARD2024',
  DD_SUBSCRIPTION = 'DD_SUBSCRIPTION',
  SIGNATURE_PACKAGE = 'SIGNATURE_PACKAGE',
  SECURITY_PRO = 'SECURITY_PRO',
}

export enum ReinvoicingRadioButtonType {
  YES = 'YES',
  NO = 'NO',
  ASK = 'ASK',
}

export interface OrderedSubscriptionDetail {
  _id?: string;
  orderedAt: Date;
  startDate: Date;
  validUntil: Date;
  isAnnualPrice: boolean;
  subscriptionEnumKey: BillingPackageID;
  userCount: number;
  ddProcessesCount?: number;
  userSubgrCount: number;
  storage: number;
  requesterId: string;
  requesterEmail: string;
  customPackagePrice?: number;
  generalInvoiceComment?: string;
  internalInvoiceComment?: string;
  customUserPrice?: number;
  customSubgroupUserPrice?: number;
  daughterCompanies?: string[];
  parentOrganization?: any;
  isEnterpriseParent?: boolean;
  reinvoicingRadioButton: ReinvoicingRadioButtonType;
  subscriptionReinvoicingDate?: Date;
  discounts?: DiscountItem[];
  isMainSubscription: boolean;
  packagePrice?: number;
  payableUsers?: number;
  payableUsersMoneySubtotal?: number;
  payableSubgroupUsers?: number;
  payableSubgroupUsersMoneySubtotal?: number;
  totalPriceForDDProcesses?: number;
  extraUsersMoneySubtotal?: number;
  extraSubgroupUsersMoneySubtotal?: number;
  totalMoneySubtotal?: number;
}

export enum BillingProductType {
  SUBSCRIPTION_PACKAGE = 'SUBSCRIPTION_PACKAGE',
  SUBSCRIPTION_PACKAGE_UPGRADE = 'SUBSCRIPTION_PACKAGE_UPGRADE',
  SUBSCRIPTION_PACKAGE_USER_EXTENSION = 'SUBSCRIPTION_PACKAGE_USER_EXTENSION',
  SUBSCRIPTION_PACKAGE_REMAINDER = 'SUBSCRIPTION_PACKAGE_REMAINDER',
  USER_PACKAGE = 'USER_PACKAGE',
  SUBGROUP_USER_PACKAGE = 'SUBGROUP_USER_PACKAGE',
  DD_PROCESSES_GROUPS = 'DD_PROCESSES_GROUPS',
  DATAROOM_STORAGE = 'DATAROOM_STORAGE',
  SIGNING_DOCUMENTS = 'SIGNING_DOCUMENTS',
}

export enum ExtraPaidService {
  SIGNATURE_CRIIPTO = 'SIGNATURE_CRIIPTO',
  AI_SERVICE = 'AI_SERVICE',
  STORAGE = 'STORAGE',
}

export interface ProductSchema {
  productType: BillingProductType | DiscountType | ExtraPaidService | CredentialEnum;
  pricePerItemNOK: number;
  priceTotalNOK: number;
  quantity: number;
  additionalData?: string;
  subscriptionId?: string;
  paymentPeriodFrom?: Date;
  paymentPeriodTo?: Date;
  stringComment?: string;
  anyData?: any;
}

export interface PaidExtraService {
  _id: string;
  userId: string;
  direntId: string;
  quantity: number;
  paidServiceType: ExtraPaidService | CredentialEnum;
  createdAt: Date;
}

export interface OrganizationSubscription {
  _id: string;
  organization: string;
  packages: OrderedSubscriptionDetail[];
  packagesModificationLog: OrderedSubscriptionDetail[];
  transientInvoiceState?: ProductSchema[];
  disposedTransientInvoiceState?: ProductSchema[];
  tripletexClientId: string;
  currency: number;
  invoiceEmail: string;
  email?: string;
  reference: string;
  invoiceSendMethod: string;
  name?: string;
  organizationNumber?: string;
  sendUpdatesFlag: boolean;
  lastPackageBeforeSwitch?: OrderedSubscriptionDetail;
  uninvoicedExtraServices?: PaidExtraService[];
  vatType?: number;
  accountManager: string;
  overdueNoticeEmail: string;
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  city: string;
  country: number;
  language: string;
  invoicesDueIn: number;
  emailAttachmentType: string;
  includedSignaturesPerYear: number;
  pricePerExtraSignature: number;
  includedStorageGb: number;
  pricePerGbPerMonth: number;
  pricePerBankIdIdentification: number;
  pricePerSmsIndetification: number;
}

export interface IOrganization {
  _id: any;
  orgId: string;
  name: string;
  clientId: string;

  createdById: MongoId;

  parentOrgNumber: string;
  parentOrgNumberReporting: string;
  organizationGroupParentId: MongoId;
  parentOrganization: MongoId;
  childOrgNumbers: string[];

  supportPermission: boolean;
  openaiPermission: boolean;
  orderId: MongoId;
  website: string;
  type?: CompanyAcronymsEnum;
  phone: string;
  city: string;
  address: string;
  postnr: string;
  established: string;
  email: string;
  invoiceEmail: string;
  roleMapTweaks: {};
  isPrivateOrg: boolean;
  extraSignaturesRequired: Role[];
  implicitVoting: ImplicitVotingSettings;

  agendaItemStyle: AgendaItemStylesSettings;
  agendaItemStyleGA: AgendaItemStylesSettings;
  agendaItemStyle3: AgendaItemStylesSettings;
  agendaItemStyle4: AgendaItemStylesSettings;
  deleted: boolean;
  language: OrgLanguage;
  jurisdiction: OrgJurisdiction;
  simplePDFFlag: boolean;
  shareClassDistributionFlag: boolean;
  showWhoHasVoted: boolean;
  showEngagementMeter: boolean;
  showAgendaItemResponsible: boolean;
  agendaItemUseStartTimeInsteadOfDuration: boolean;
  agendaItemShowTimeInMinutes?: boolean;
  showAgendaItemLogs: boolean;
  showAgendaSpeakingList: boolean;
  showAgendaComments: boolean;
  logo: any;
  activeCampaignId: string;
  demoMeetingsCreated: boolean;
  suspendedAt: Date;
  defaultFilePermissionsMap: {};
  requiredId: IdentityType[];
  roleTranslateTexts: {};
  authPolicy: {};
  earlyAccess: {};
  sportsOrgId: string;
  selectedMainPackageId: BillingPackageID;
  selectedPackage?: string;
  validFromSelectedPackage?: Date;
  validToSelectedPackage?: Date;
  // not in schema butg added for bookkeeping
  isSupport?: boolean;
  createdAt: Date;
}

/*
 const orgs = await OrganizationModel.find(}).populate({
        path: 'members',
        populate: 'user',
      })
*/
export interface IOrganizationWithRoles extends IOrganization {
  members: IUserRoles[];
}

export type OrgAndClientLogoType = {
  url: string;
  urlForEmail: string;
  folderPath: string;
  extension: string;
  name: string;
  fileSize: number;
};
