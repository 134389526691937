import { MongoId } from './mongoid';

export enum AgendaItemCategory {
  PAUSE = 'PAUSE',
  INFORMATION = 'INFORMATION',
  DISCUSSION = 'DISCUSSION',
  DECISION = 'DECISION',
  ELECTION = 'ELECTION',
  ALTERNATIVE_OPTIONS = 'ALTERNATIVE_OPTIONS',
  NO_CATEGORY = 'NO_CATEGORY',
}

export type TaskUnitI = {
  _id?: MongoId;
  __v?: any;
  id?: MongoId;
  organization: MongoId;
  meeting: MongoId;

  agendaItem: MongoId;
  title: string;
  description: string;
  completed: boolean;
  date: Date;
  sendEmailToResponsible: boolean;
  formControlForMessageInEmail: boolean;
  notifiedUserIds: MongoId;
  showInCalendar: boolean;
  showInCalendarForEverybody: boolean;
  showInMinutesPdf: boolean;
  repeatAnnually: boolean;
  hasBeenProcessedForAutoRepeat: boolean;
  taskDependsOn: string[];
  parentTask: string;
  privateTask: boolean;
  completionProgress: number;
  taskStatus: string;
  taskPriority: string;
  comments: any;
  dueDiligenceDirentId: MongoId;
  category: AgendaItemCategory;

  dueDiligenceDirentPath: string[];
  responsible: MongoId;
  extraResponsible: MongoId;
  createdBy: MongoId;
  seenUserIdsMap: any;
  startTime: Date;
  createdAt: Date;
  updatedAt: Date;

  // Not in schema ???
  iHaveSeenThisTask?: boolean;
};
