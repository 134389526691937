import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModuleInfoService {
  closedInfoBoxes$: BehaviorSubject<{ [id: string]: boolean }> = new BehaviorSubject({});

  // checks closed state for X icon
  closedX(id: string): Observable<boolean> {
    return this.closedInfoBoxes$.pipe(map((boxes) => boxes[id]));
  }

  closed(id: string, beginClosedState?: boolean): Observable<boolean> {
    const closed = localStorage.getItem(id);
    let isClosedState = !!closed && closed !== 'false';
    if (beginClosedState && !isClosedState && closed === null) {
      isClosedState = true;
    }

    this.closedInfoBoxes$.next({ ...this.closedInfoBoxes$.getValue(), [id]: isClosedState });
    return this.closedInfoBoxes$.pipe(map((boxes) => boxes[id]));
  }

  close(id: string) {
    localStorage.setItem(id, 'true');
    this.closedInfoBoxes$.next({ ...this.closedInfoBoxes$.getValue(), [id]: true });
  }

  open(id: string) {
    localStorage.setItem(id, 'false');
    this.closedInfoBoxes$.next({ ...this.closedInfoBoxes$.getValue(), [id]: false });
  }
}
