import { CompanyAcronymsEnum, Role } from '@orgbrain/roles';
import { IdentityRecord } from './session';

export interface ImportUser {
  tempPassword?: string;
  sub?: string;
  _id?: string;
  internref?: string;
  deleted?: boolean;
  phone?: string;
  roles: Role[];
  sendInvite?: boolean;
  dirty?: boolean; //  You probably want to set this to true when you create a new user.
  email: string;
  firstName: string;
  lastName: string;
  identity?: IdentityRecord; // this is optional ised for example with sports org to prestore buypass logon.
  creator?: boolean; // set this if the user is the creator of the org.
  committeeInfo?: {
    sportsCommitteeFunctionTypeId: number; // Orginial functionTypeId from sports org
    namePrefix: string; // Prefix to add to the group name
    templateOrgID: string; // orgID  of orgbrain org to clone tasks and news from.
  };
  sportsAuto: boolean; // If we want to set the user as managed by sports API.
}

// This is the subset of organization require to create an organization in th eorgbrain database.
export interface ImportOrganization {
  supportPermission: boolean;
  createByIdentity?: any;

  // I think this is used when we updarte an existing org.
  _id?: string;
  createdById: string;
  createdAt: any;
  parentOrganization?: any;
  members: ImportUser[];
  name: string;
  phone?: string;
  orderId?: string;
  organizationGroupParentId?: string;
  orgId: string;
  clientId: string;
  isPrivateOrg: boolean;
  type: CompanyAcronymsEnum;
  orgLogoBase64?: string;
  sportsOrgId?: string;
  website?: string;
  city?: string;
  address?: string;
  postnr?: string;
  established?: string;
  email: string;
  invoiceEmail: string;
  headsOfCommittee: ImportUser[];
}
