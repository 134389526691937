<div class="snack-container">
  <div class="text-content">
    <div *ngIf="data.error" class="error-icon"><mat-icon>error_outline</mat-icon></div>
    {{ data.message }}
  </div>
  <div class="mat-simple-snackbar-action" *ngIf="hasAction">
    <button mat-button (click)="action()" style="color: white">
      {{ data.action }}
    </button>
  </div>
</div>
