import { Injectable } from '@angular/core';

const SOUND_INTERVAL = 10000; // 10 seconds

@Injectable({ providedIn: 'root' })
export class DecorationService {
  originalFaviconUrl: string;

  updateFavicon(unreadCount) {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;

    if (unreadCount > 0) {
      if ('setAppBadge' in navigator) {
        navigator.setAppBadge(unreadCount);
        // Badge API supported
      }

      favicon.href = 'assets/orgbrain-favicon-filled-circle.svg';
    } else {
      favicon.href = 'assets/orgbrain-favicon.svg';
      if ('clearAppBadge' in navigator) {
        navigator.clearAppBadge();
      }
    }
  }

  playSound() {
    const sounds = ['level-up-3-199576.mp3', 'achive-sound-132273.mp3'];

    const now = Date.now();
    const lastPlay = parseInt(localStorage.getItem('lastSoundPlay'), 10);
    const lock = localStorage.getItem('soundLock');

    // Check if the sound lock is held by another tab
    if (lock && parseInt(lock) > now) {
      // Lock exists and is recent, so do nothing
      return;
    }

    // Acquire lock for this tab
    localStorage.setItem('soundLock', (now + SOUND_INTERVAL).toString());

    // Check if enough time has passed since the last sound play
    if (!lastPlay || now - lastPlay > SOUND_INTERVAL) {
      localStorage.setItem('lastSoundPlay', now.toString());

      // Play the sound in this tab
      const audio = new Audio('assets/mp3/' + sounds[0]);
      audio.volume = 0.3;
      audio.play();
    }

    // Release the lock after SOUND_INTERVAL
    setTimeout(() => {
      localStorage.removeItem('soundLock');
    }, SOUND_INTERVAL);
  }
}
