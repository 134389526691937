<div class="orgbrain-style">
  <orgbrain-dialog-title [title]="data.title" [dialogRef]="dialogRef"> </orgbrain-dialog-title>

  <div class="bodytext-1 mb-10">{{ data.bodyText }}</div>

  <mat-form-field appearance="outline" style="width: 100%">
    <textarea cdkTextareaAutosize matInput [(ngModel)]="value" cdkAutosizeMinRows="4"> </textarea>
  </mat-form-field>

  <div class="button-row-full">
    <button mat-raised-button (click)="ok()" color="primary">OK</button>
  </div>
</div>
