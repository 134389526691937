import { Injectable } from '@angular/core';

import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { printClassName } from '@orgbrain/lib-data';
import { Snack2ToastrService } from './services/snack2toastr.service';

function isString(obj) {
  return Object.prototype.toString.call(obj) === '[object String]';
}

@Injectable({ providedIn: 'root' })
export class MessageService {
  okSubject = new Subject<boolean>();

  constructor(public snack: Snack2ToastrService) {
    printClassName(' ---> ', this);
  }

  open(message, duration: any = 500) {
    if (typeof duration !== 'number') {
      duration = 500;
    }
    setTimeout(() => {
      this.snack.open(message, 'OK', { duration });
    });
  }

  close() {}

  setStatus(mess) {
    setTimeout(() => {
      this.snack.open(mess);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  error(errormessage, head?): Observable<any> {
    setTimeout(() => {
      let errorMessage;
      if (!errormessage) {
        errorMessage = 'HTTP error';
      } else if (isString(errormessage)) {
        errorMessage = errormessage;
      } else if (errormessage.message) {
        errorMessage = errormessage.message;
      } else {
        errorMessage = JSON.stringify(errormessage);
      }

      const config = new MatSnackBarConfig();
      //   config.panelClass = ['background-red'];
      config.duration = 3000000;
      config.data = config.data || {};
      Object.assign(config.data, { action: 'OK', error: true });

      this.snack.open(errorMessage, 'OK', config);
    });

    return this.okSubject.pipe(take(1));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  closeOk(status): Observable<boolean> {
    return this.okSubject;
  }

  errorOk() {
    setTimeout(() => {
      this.okSubject.next(true);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  snackInfo(message: string, keepAfterNavigationChange = false) {
    // console.log('SNACK: info' + message);

    setTimeout(() => {
      this.snack.open(message, 'OK', { duration: 500 });

      // this.keepAfterNavigationChange = keepAfterNavigationChange;
      // this.subject.next({ type: 'info', text: message });
    });
  }
}
