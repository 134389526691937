export const tripletexCurrencies = [
  { id: 1, code: 'NOK' },
  { id: 2, code: 'SEK' },
  { id: 3, code: 'DKK' },
  { id: 4, code: 'USD' },
  { id: 5, code: 'EUR' },
  { id: 6, code: 'GBP' },
  { id: 7, code: 'CHF' },
  { id: 8, code: 'JPY' },
  { id: 9, code: 'AUD' },
];

function keepSomeKeysOfArray(arr, keys) {
  return arr.map((item) => {
    let miniObject = {};
    for (const key of keys) {
      miniObject[key] = item[key];
    }

    return miniObject;
  });
}

// JSON.stringify(keepSomeKeysOfArray(tripletexCurrencies, ['id', 'code']), null, 2);
// JSON.stringify(keepSomeKeysOfArray(x, ['id', 'name', 'isoAlpha2Code']), null, 2);
