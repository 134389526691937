import { TranslationTextMap } from '@orgbrain/roles';
import { textForRoles } from './orgtype-role-translations';

const textsOrig = {
  Security: {
    default: {
      en: 'Security',
      nb: 'Sikkerhet',
      sv: 'Säkerhet',
    },
  },

  ANNUAL_GENERAL_MEETING: {
    default: {
      en: 'Annual general meetings',
      nb: 'Ordinære generalforsamlinger',
      sv: 'Årsstämma',
    },
    SBSTI: {
      en: 'Annual general meetings',
      nb: 'Ordinære generalforsamlinger',
      sv: 'Årsstämma',
    },
    fliAlike: {
      en: 'Annual meetings',
      nb: 'Årsmøter',
    },
    BRL: {
      en: 'General meetings',
      nb: 'Generalforsamlinger',
    },
    ESEK: {
      en: 'Ordinary annual meetings',
      nb: 'Ordinære årsmøter',
    },
  },
  settParvalueForShareclass: {
    default: {
      en: 'Set par value for share class',
      nb: 'Sett pålydende for aksjeklasse',
    },
  },
  NewShareclassName: {
    default: {
      nb: 'Ordinære aksjer',
      en: 'Ordinary shares',
    },
    fliAlike: {
      nb: 'Ordinær medlemskap',
      en: 'Ordinary membership',
    },
    BRL: {
      nb: 'Andel',
      en: 'Unit',
      sv: 'Enhet',
    },
    ESEK: {
      nb: 'Ordinær seksjon',
      en: 'Ordinary section',
      sv: 'Ordinarie avsnitt',
    },
  },
  VotingPowerTooltip: {
    default: {
      nb: 'Normalt har alle aksjer en stemme, men vedtektene kan angi at aksjene i en aksjeklasse har en annen stemmevekt. Endre dersom aksjene har annen stemmevekt enn 1',
      en: 'Normally, all shares have one vote, but the articles of association may specify that the shares in a share class have a different voting weight. Change if the shares have a different voting weight than 1',
    },
  },
  AmountOfShares: {
    default: {
      nb: 'Antall aksjer',
      en: 'Number of shares',
    },
    fliAlike: {
      nb: 'Antall andeler',
      en: 'Number of units',
    },
    BRL: {
      nb: 'Antall andeler',
      en: 'Number of units',
    },
    ESEK: {
      nb: 'Antall seksjoner',
      en: 'Number of sections',
    },
  },
  ALLTooltip: {
    default: {
      nb: 'Meldingen er synlig for brukere med styre- eller aksjeeiertilgang',
      en: 'The message is visible to users with board or shareholder access',
    },
    fliAlike: {
      nb: 'Meldingen er synlig for brukere med styre- eller medlemstilgang.',
      en: 'The message is visible to users with board or member access',
    },
    BRL: {
      nb: 'Meldingen er synlig for brukere med styre- eller andelseiertilgang',
      en: 'The message is visible to users with board or unitholder access',
    },
    ESEK: {
      nb: 'Meldingen er synlig for brukere med styre- eller seksjonseiertilgang',
      en: 'The message is visible to users with board or section owner access',
    },
  },
  SHAREHOLDER_MEETING: {
    default: {
      en: 'Shareholder Meetings',
      nb: 'Aksjonærmøter',
      sv: 'Aktieägarmöten',
    },
    fliAlike: {
      en: 'Member meetings',
      nb: 'Medlemsmøter',
    },
    BRL: {
      en: 'Unitholder meetings',
      nb: 'Andelseiermøter',
    },
    ESEK: {
      en: 'Section owner meetings',
      nb: 'Seksjonseiermøter',
    },
  },
  BOARD_MEETING: {
    default: {
      en: 'Board meetings',
      nb: 'Styremøter',
      sv: 'Styrelsemöten',
    },
  },
  EXTRAORDINARY_GENERAL_MEETING: {
    default: {
      en: 'Extraordinary general meetings',
      nb: 'Ekstraordinære generalforsamlinger',
      sv: 'Extra bolagsstämma',
    },
    SBSTI: {
      en: 'Extraordinary general meetings',
      nb: 'Ekstraordinære generalforsamlinger',
      sv: 'Extra bolagsstämma',
    },
    fliAlike: {
      en: 'Extraordinary annual meetings',
      nb: 'Ekstraordinære årsmøter',
    },
    ESEK: {
      en: 'Extraordinary annual meetings',
      nb: 'Ekstraordinære årsmøter',
    },
  },
  OTHER_MEETING: {
    default: {
      en: 'Meetings',
      nb: 'Møter',
      sv: 'Möten',
    },
  },
  MEETINGS: {
    default: {
      en: 'Meetings',
      nb: 'Møter',
      sv: 'Möten',
    },
  },
  Events: {
    default: {
      en: 'Events',
      nb: 'Hendelser',
      sv: 'Hendelser',
    },
  },
  'Beneficial owners': {
    default: {
      en: 'Beneficial Owners',
      nb: 'Reelle rettighetshavere',
      sv: 'Verkliga rättighetshavare',
    },
  },
  'New transaction': {
    default: {
      en: 'Transactions',
      nb: 'Transaksjoner',
      sv: 'Transaktioner',
    },
  },
  NewInvestor: {
    default: {
      en: 'New member',
      nb: 'Nytt medlem',
      sv: 'Nytt medlem',
    },
    fliAlike: {
      en: 'New member',
      nb: 'Nytt medlem',
      sv: 'Nytt medlem',
    },
    BRL: {
      en: 'New unit holder',
      nb: 'Ny andelseier',
      sv: 'Ny andelsägare',
    },
    ESEK: {
      en: 'New section owner',
      nb: 'Ny seksjonseier',
      sv: 'Ny avsnittsägare',
    },
    AS: {
      en: 'New investor',
      nb: 'Ny investor',
      sv: 'Ny investerare',
    },
    ASA: {
      en: 'New investor',
      nb: 'Ny investor',
      sv: 'Ny investerare',
    },
  },
  Transaction: {
    default: {
      en: 'Transaction',
      nb: 'Transaksjon',
      sv: 'Transaktion',
    },
  },
  'Split/splice': {
    default: {
      en: 'Split/splice',
      nb: 'Splitt/spleis',
      sv: 'Splitt/spleis',
    },
  },
  'Financial contracts': {
    default: {
      en: 'Financial Agreements',
      nb: 'Finansielle avtaler',
      sv: 'Finansielle avtaler',
    },
  },
  'Financial agreements': {
    default: {
      en: 'Financial agreements',
      nb: 'Finansielle avtaler',
      sv: 'Finansielle avtaler',
    },
  },

  Authorizations: {
    default: {
      en: 'Board Authorizations',
      nb: 'Styrefullmakter',
    },
  },

  AuthBuySharesReadMore: {
    AS: {
      en: 'Read more in sections 9-2 and 9-4 of the Norwegian Companies Act.',
      nb: 'Les mer i aksjeloven §§ 9-2 og 9-4.',
    },
    ASA: {
      en: 'Read more in section 9-2 and 9-4 of the Norwegian public Limited Liability Companies Act.',
      nb: 'Les mer i allmennaksjeloven §§ 9-2 og 9-4.',
    },
  },
  AuthClaimReadMore: {
    AS: {
      en: 'Read more in section 9-5 of the Norwegian Companies Act.',
      nb: 'Les mer i aksjeloven § 9-5.',
    },
    ASA: {
      en: 'Read more in section 9-5 of the Norwegian public Limited Liability Companies Act.',
      nb: 'Les mer i allmennaksjeloven § 9-5.',
    },
  },
  AuthConvertibleReadMore: {
    AS: {
      en: 'Read more in section 11-8 of the Norwegian Companies Act.',
      nb: 'Les mer i aksjeloven § 11-8.',
    },
    ASA: {
      en: 'Read more in section 11-8 of the Norwegian public Limited Liability Companies Act.',
      nb: 'Les mer i allmennaksjeloven § 11-8.',
    },
  },
  AuthDividendsReadMore: {
    AS: {
      en: 'Read more in section 8-2 of the Norwegian Companies Act.',
      nb: 'Les mer i aksjeloven § 8-2.',
    },
    ASA: {
      en: 'Read more in section 8-2 of the Norwegian public Limited Liability Companies Act.',
      nb: 'Les mer i allmennaksjeloven § 8-2.',
    },
  },
  AuthIssueSharesReadMore: {
    AS: {
      en: 'Read more in chapter 10 of the Norwegian Company Act.',
      nb: 'Les mer i aksjeloven kapittel 10.',
    },
    ASA: {
      en: 'Read more in the Norwegian public Limited Liability Companies Act, chapter 10.',
      nb: 'Les mer i allmennaksjeloven kapittel 10.',
    },
  },
  'Register dividends': {
    default: {
      en: 'Dividend/payout',
      nb: 'Utbytte/utbetaling',
      sv: 'Utdelning/utbetalning',
    },
  },
  DataRoom: {
    default: {
      en: 'Files for the Board',
      nb: 'Felles filer for styret',
      sv: 'Gemensamma filer för styrelsen',
    },
    SUBGR: {
      en: 'Common files',
      nb: 'Felles filer',
      sv: 'Gemensamma filer',
    },
    'SUBGR-DD': {
      en: 'Due diligence',
      nb: 'Due diligence',
      sv: 'Due diligence',
    },
  },
  importSnapshot: {
    default: {
      en: 'Import snapshot',
      nb: 'Importer øyeblikksbilde',
    },
    fliAlike: {
      en: 'Import registry',
      nb: 'Importer register',
    },
    BRL: {
      en: 'Import registry',
      nb: 'Importer register',
    },
    ESEK: {
      en: 'Import registry',
      nb: 'Importer register',
    },
  },
  downloadSnapshot: {
    default: {
      en: 'Download snapshot',
      nb: 'Last ned øyeblikksbilde',
    },
    fliAlike: {
      en: 'Download registry',
      nb: 'Last ned register',
    },
    BRL: {
      en: 'Download registry',
      nb: 'Last ned register',
    },
    ESEK: {
      en: 'Download registry',
      nb: 'Last ned register',
    },
  },
  downloadSnapshotPDF: {
    default: {
      en: 'Shareholder register as PDF',
      nb: 'Aksjeeierbok som PDF',
    },
    fliAlike: {
      en: 'Registery as PDF',
      nb: 'Register som PDF',
    },
    BRL: {
      en: 'Registery as PDF',
      nb: 'Register som PDF',
    },
    ESEK: {
      en: 'Registery as PDF',
      nb: 'Register som PDF',
    },
  },
  snapshotOrRegistry: {
    default: {
      en: 'the last snapshot',
      nb: 'siste øyeblikksbilde',
    },
    fliAlike: {
      en: 'the registry',
      nb: 'registeret',
    },
    BRL: {
      en: 'the registry',
      nb: 'registeret',
    },
    ESEK: {
      en: 'the registry',
      nb: 'registeret',
    },
  },
  createSnapshotOrRegistry: {
    default: {
      en: 'Create a snapshot',
      nb: 'Lag et øyeblikksbilde',
    },
    fliAlike: {
      en: 'Create registry',
      nb: 'Lag et register',
    },
    BRL: {
      en: 'Create registry',
      nb: 'Lag et register',
    },
    ESEK: {
      en: 'Create registry',
      nb: 'Lag et register',
    },
  },
  editLastOrRegistry: {
    default: {
      en: 'Edit last snapshot',
      nb: 'Rediger siste øyeblikksbilde',
    },
    fliAlike: {
      en: 'Edit registry',
      nb: 'Rediger registeret',
    },
    BRL: {
      en: 'Edit registry',
      nb: 'Rediger registeret',
    },
    ESEK: {
      en: 'Edit registry',
      nb: 'Rediger registeret',
    },
  },
  OPTION: {
    default: {
      en: 'Option',
      nb: 'Opsjon',
    },
  },
  WARRANT: {
    default: {
      en: 'Warrant',
      nb: 'Tegningsrett',
    },
  },
  CONVERTIBLE: {
    default: {
      en: 'Convertible',
      nb: 'Lån',
    },
  },
  NOT_EXERCISED: {
    default: {
      en: 'Not exercised',
      nb: 'Ikke utøvd',
    },
  },
  CANCELLED: {
    default: {
      en: 'Cancelled',
      nb: 'Kansellert',
    },
  },
  ownersIdentity: {
    default: {
      en: 'Owners identity',
      nb: 'Eiers identitet',
      sv: 'Ägares identitet',
    },
    BRL: {
      en: 'Unit holders identity',
      nb: 'Andelseiers identitet',
      sv: 'Andelsägares identitet',
    },
    ESEK: {
      en: 'Section owners identity',
      nb: 'Seksjonseiers identitet',
      sv: 'Sektionsägares identitet',
    },
    fliAlike: {
      en: 'Members identity',
      nb: 'Medlems identitet',
      sv: 'Medlems identitet',
    },
  },
  Investorid: {
    default: {
      en: 'Investorid',
      nb: 'Investorid',
      sv: 'Investorid',
    },
    fliAlike: {
      en: 'Memberid',
      nb: 'Medlemsid',
      sv: 'Medlemsid',
    },
    BRL: {
      en: 'Unitholderid',
      nb: 'Andelseierid',
      sv: 'Andelsägarid',
    },
  },
  ImportNewRegistryHelptext: {
    default: {
      nb: 'Slik vil registeret se ut i Orgbrain etter importen er plassert i historikken. Hvis datoen for denne importen er plassert før en annen import, vil det du prøver å importere overskrives, og dermed ikke ha noen effekt på det eksisterende eierregisteret.',
      en: 'This is how the registry will look in Orgbrain after the import is placed in the history. If the date of this import is placed before another import, what you are trying to import will be overwritten, and thus have no effect on the existing owner registry.',
    },
    fliAlike: {
      nb: 'Slik vil registeret se ut i Orgbrain etter importen er fulført.',
      en: 'This is how the registry will look in Orgbrain after the import is completed.',
    },
    BRL: {
      nb: 'Slik vil registeret se ut i Orgbrain etter importen er fulført.',
      en: 'This is how the registry will look in Orgbrain after the import is completed.',
    },
    ESEK: {
      nb: 'Slik vil registeret se ut i Orgbrain etter importen er fulført.',
      en: 'This is how the registry will look in Orgbrain after the import is completed.',
    },
  },

  NewOwner: {
    default: {
      en: 'New owner',
      nb: 'Ny eier',
      sv: 'Ny ägare',
    },
    fliAlike: {
      en: 'New member',
      nb: 'Nytt medlem',
      sv: 'Nytt medlem',
    },
    BRL: {
      en: 'New unit holder',
      nb: 'Ny andelseier',
      sv: 'Ny andelsägare',
    },
    ESEK: {
      en: 'New section owner',
      nb: 'Ny seksjonseier',
      sv: 'Ny avsnittsägare',
    },
  },
  UserContactForOwner: {
    default: {
      nb: 'Bruker knyttet til eier',
      en: 'User linked to owner',
    },
    fliAlike: {
      nb: 'Bruker knyttet til medlem',
      en: 'User linked to member',
    },
    BRL: {
      nb: 'Bruker knyttet til andelseier',
      en: 'User linked to unit holder',
    },
    ESEK: {
      nb: 'Bruker knyttet til seksjonseier',
      en: 'User linked to section owner',
    },
  },

  EXPIRED: {
    default: {
      en: 'Expired',
      nb: 'Utløpt',
    },
  },
  EXERCISED: {
    default: {
      en: 'Exercised',
      nb: 'Utøvd',
    },
  },
  Owners: {
    default: {
      en: 'Owners',
      nb: 'Eiere',
      sv: 'Ägare',
    },
    fliAlike: {
      en: 'Members',
      nb: 'Medlemmer',
      sv: 'Medlemmar',
    },
    BRL: {
      en: 'unit holders',
      nb: 'Andelseiere',
      sv: 'Andelsägare',
    },
    ESEK: {
      en: 'Section owners',
      nb: 'Seksjonseiere',
      sv: 'Sektionsägare',
    },
    SAM: {
      en: 'Co-owners',
      nb: 'Sameiere',
      sv: 'Delägare',
    },
  },
  CreateInvestorsFromSpreadsheet: {
    default: {
      en: 'Create owners from spreadsheet',
      nb: 'Opprett eiere fra regneark',
      sv: 'Skapa ägare från kalkylblad',
    },
    fliAlike: {
      en: 'Create members from spreadsheet',
      nb: 'Opprett medlemmer fra regneark',
      sv: 'Skapa medlemmar från kalkylblad',
    },
    BRL: {
      en: 'Create unit holders from spreadsheet',
      nb: 'Opprett andelseiere fra regneark',
      sv: 'Skapa andelsägare från kalkylblad',
    },
    ESEK: {
      en: 'Create section owners from spreadsheet',
      nb: 'Opprett seksjonseiere fra regneark',
      sv: 'Skapa sektionsägare från kalkylblad',
    },
  },
  ContactInformation: {
    default: {
      en: 'Contact information',
      nb: 'Eiers kontaktinformasjon',
      sv: 'Kontaktinformation',
    },

    fliAlike: {
      en: 'Contact information',
      nb: 'Medlems kontaktinformasjon',
      sv: 'Kontaktinformation',
    },

    BRL: {
      en: 'Contact information',
      nb: 'Andelseiers kontaktinformasjon',
      sv: 'Kontaktinformation',
    },
    ESEK: {
      en: 'Contact information',
      nb: 'Seksjonseiers kontaktinformasjon',
      sv: 'Kontaktinformation',
    },
  },
  Boardmatch: {
    default: {
      en: 'Boardmatch',
      nb: 'Styrematch',
      sv: 'Styrematch',
    },
    fliAlike: {
      en: 'Boardmatch',
      nb: 'Styrematch',
      sv: 'Styrematch',
    },
    BRL: {
      en: 'Boardmatch',
      nb: 'Styrematch',
      sv: 'Styrematch',
    },
    ESEK: {
      en: 'Boardmatch',
      nb: 'Styrematch',
      sv: 'Styrematch',
    },
  },
  AnOwner: {
    default: {
      en: 'An owner',
      nb: 'En eier',
      sv: 'En Ägar',
    },
    fliAlike: {
      en: 'A member',
      nb: 'Et medlem',
      sv: 'Et medlem',
    },
    BRL: {
      en: 'A unitholder',
      nb: 'En andelseier',
      sv: 'En andelsägar',
    },
    ESEK: {
      en: 'A section owner',
      nb: 'En seksjonseier',
      sv: 'En avsnittsägar',
    },
  },
  Owner: {
    default: {
      en: 'Owner',
      nb: 'Eier',
      sv: 'Ägar',
    },
    fliAlike: {
      en: 'Member',
      nb: 'Medlem',
      sv: 'Medlem',
    },
    BRL: {
      en: 'Unitholder',
      nb: 'Andelseier',
      sv: 'Andelsägar',
    },
    ESEK: {
      en: 'Section owner',
      nb: 'Seksjonseier',
      sv: 'Avsnittsägar',
    },
  },
  OwnerShares: {
    default: {
      en: 'Shares',
      nb: 'Aksjer',
      sv: 'Aktier',
    },
    fliAlike: {
      en: 'Units',
      nb: 'Andeler',
      sv: 'Röster',
    },
    BRL: {
      en: 'Units',
      nb: 'Andeler',
      sv: 'Enheter',
    },
    ESEK: {
      en: 'Sections',
      nb: 'Seksjoner',
      sv: 'Röster',
    },
  },
  OwnerShare: {
    default: {
      en: 'Share',
      nb: 'Aksje',
      sv: 'Aktie',
    },
    fliAlike: {
      en: 'Unit',
      nb: 'Andel',
      sv: 'Röst',
    },
    BRL: {
      en: 'Unit',
      nb: 'Andel',
      sv: 'Enhet',
    },
    ESEK: {
      en: 'Section',
      nb: 'Seksjon',
      sv: 'Röst',
    },
  },
  'the owner': {
    default: {
      en: 'the owner',
      nb: 'eieren',
      sv: 'ägaren',
    },
    fliAlike: {
      en: 'the member',
      nb: 'medlemet',
      sv: 'medlemet',
    },
    BRL: {
      en: 'the unitholder',
      nb: 'andelseieren',
      sv: 'andelsägaren',
    },
    ESEK: {
      en: 'the section owner',
      nb: 'seksjonseieren',
      sv: 'sektionsägaren',
    },
  },
  'the owners': {
    default: {
      en: 'the owners',
      nb: 'eierene',
      sv: 'ägarene',
    },
    fliAlike: {
      en: 'the members',
      nb: 'medlemmene',
      sv: 'medlemmene',
    },
    BRL: {
      en: 'the unit holders',
      nb: 'andelseierene',
      sv: 'andelsägarene',
    },
    ESEK: {
      en: 'the section owners',
      nb: 'seksjonseierene',
      sv: 'sektionsägarene',
    },
  },
  'an owner': {
    default: {
      en: 'an owner',
      nb: 'en eier',
      sv: 'en ägar',
    },
    fliAlike: {
      en: 'a member',
      nb: 'et medlem',
      sv: 'et medlem',
    },
    BRL: {
      en: 'a unitholder',
      nb: 'en andelseier',
      sv: 'en andelsägar',
    },
    ESEK: {
      en: 'a section owner',
      nb: 'en seksjonseier',
      sv: 'en avsnittsägar',
    },
  },
  'Share numbers': {
    default: {
      en: 'Share numbers',
      nb: 'Aksjenummer',
    },
    fliAlike: {
      en: 'Membership number',
      nb: 'Medlemsnummer',
    },
    BRL: {
      en: 'Share number',
      nb: 'Andelsnummer',
    },
    ESEK: {
      en: 'Section number',
      nb: 'Seksjonsnummer',
    },
  },
  Shareclass: {
    default: {
      en: 'Shareclass',
      nb: 'Aksjeklasse',
    },
    fliAlike: {
      en: 'Member category',
      nb: 'Medlemskategori',
    },
    BRL: {
      en: 'Share category',
      nb: 'Andelskategori',
    },
    ESEK: {
      en: 'Section category',
      nb: 'Seksjonskategori',
    },
  },

  Shareclasses: {
    default: {
      en: 'Shareclasses',
      nb: 'Aksjeklasser',
    },
    fliAlike: {
      en: 'Member categories',
      nb: 'Medlemskategorier',
    },
    BRL: {
      en: 'Share categories',
      nb: 'Andelskategorier',
    },
    ESEK: {
      en: 'Section categories',
      nb: 'Seksjonskategorier',
    },
  },
  'Total number of owners': {
    default: {
      en: 'Total number of owners',
      nb: 'Totalt antall eiere',
      sv: 'Totalt antal ägare',
    },
    fliAlike: {
      en: 'Total number of members',
      nb: 'Totalt antall medlemmer',
      sv: 'Totalt antal medlemmar',
    },
    BRL: {
      en: 'Total number of unit holders',
      nb: 'Totalt antall andelseiere',
      sv: 'Totalt antal andelsägare',
    },
    ESEK: {
      en: 'Total number of section owners',
      nb: 'Totalt antall seksjonseiere',
      sv: 'Totalt antal sektionsägare',
    },
  },
  'Select owner': {
    default: {
      en: 'Select owner',
      nb: 'Velg eier',
      sv: 'Välj ägare',
    },
    fliAlike: {
      en: 'Select member',
      nb: 'Velg medlem',
      sv: 'Välj medlem',
    },
  },

  // ROLES orgbrain-db/libs/roles/src/lib/roles.ts END
  Shareholder: {
    default: {
      en: 'Shareholder',
      nb: 'Aksjonær',
      sv: 'Aktieägare',
    },
    fliAlike: {
      en: 'Member',
      nb: 'Medlem',
      sv: 'Medlem',
    },
    BRL: {
      en: 'Unitholder',
      nb: 'Andelseier',
      sv: 'Andelsägare',
    },
    ESEK: {
      en: 'Section owner',
      nb: 'Seksjonseier',
      sv: 'Avdelningsägare',
    },
    SAM: {
      en: 'Co-owner',
      nb: 'Sameier',
      sv: 'Delägar',
    },
  },
  SHAREHOLDEROROWNER: {
    default: {
      en: 'Investor/owner',
      nb: 'Investor/eier',
      sv: 'Investerare/ägare',
    },
    fliAlike: {
      en: 'registered member',
      nb: 'registrerte medlemmer',
      sv: 'registrerade medlemmar',
    },
    BRL: {
      en: 'Unit investor',
      nb: 'Andelsinvestor',
      sv: 'Enhetsinvesterare',
    },
    ESEK: {
      en: 'Section investor',
      nb: 'Seksjonsinvestor',
      sv: 'Sektionsinvesterare',
    },
  },
  Shareholders: {
    default: {
      en: 'Shareholders',
      nb: 'Aksjeeiere',
      sv: 'Aktieägare',
    },
    fliAlike: {
      en: 'Members',
      nb: 'Medlemmer',
      sv: 'Medlemmar',
    },
    BRL: {
      en: 'Unit holders',
      nb: 'Andelseiere',
      sv: 'Andelsägare',
    },
    ESEK: {
      en: 'Section owners',
      nb: 'Seksjonseiere',
      sv: 'Sektionsägare',
    },
  },
  'No shareholders to invite': {
    default: {
      en: 'No shareholders to invite',
      nb: 'Ingen aksjonærer å invitere',
      sv: 'Inga aktieägare att bjuda in',
    },
    fliAlike: {
      en: 'No members to invite',
      nb: 'Ingen medlemmer å invitere',
      sv: 'Inga medlemmar att bjuda in',
    },
  },
  Invite: {
    default: {
      en: 'Invite',
      nb: 'Inviter',
      sv: 'Bjud in',
    },
  },
  'Meeting invitation': {
    default: {
      en: 'Meeting invitation',
      nb: 'Møteinvitasjon',
      sv: 'Mötesinbjudan',
    },
    fliAlike: {
      en: 'Invite members',
      nb: 'Inviter medlemmer',
      sv: 'Bjud in medlemmar',
    },
    BRL: {
      en: 'Invite unit holders',
      nb: 'Inviter andelseiere',
      sv: 'Bjud in andelsägare',
    },
    ESEK: {
      en: 'Invite section owners',
      nb: 'Inviter seksjonseiere',
      sv: 'Bjud in sektionens ägare',
    },
  },
  AddNewShareRecord: {
    default: {
      en: 'New share record',
      nb: 'Registrer aksjepost',
      sv: 'Ny aktierekord',
    },
    fliAlike: {
      en: 'New membership',
      nb: 'Nytt medlemskap',
      sv: 'Nytt medlemskap',
    },
    BRL: {
      en: 'New unit',
      nb: 'Registrer ny andel',
      sv: 'Ny enhet',
    },
    ESEK: {
      en: 'New section',
      nb: 'Ny seksjon',
      sv: 'Nytt avsnitt',
    },
  },
  Sharerecords: {
    default: {
      en: 'Share records',
      nb: 'Aksjeposter',
      sv: 'Aktierekorder',
    },
    fliAlike: {
      en: 'Memberships',
      nb: 'Medlemskaper',
      sv: 'Medlemskaper',
    },
    BRL: {
      en: 'Units',
      nb: 'Andeler',
      sv: 'Enheter',
    },
    ESEK: {
      en: 'Number of sections',
      nb: 'Antall seksjoner',
      sv: 'Avsnitter',
    },
  },
  'Create investor': {
    default: {
      en: 'Create investor',
      nb: 'Opprette investor',
      sv: 'Skapa ägare',
    },
    fliAlike: {
      en: 'Create member',
      nb: 'Opprette medlem',
      sv: 'Skapa medlem',
    },
  },
  'Share classes': {
    default: {
      en: 'Share classes',
      nb: 'Aksjeklasser',
      sv: 'Dela klasser',
    },
    fliAlike: {
      en: 'Member types',
      nb: 'Medlemskategorier',
      sv: 'Medlemstyper',
    },
    BRL: {
      en: 'Unit categories',
      nb: 'Andelskategorier',
      sv: 'Enhetskategorier',
    },
    ESEK: {
      en: 'Section owner categories',
      nb: 'Seksjonseierkategorier',
      sv: 'Kategori ägarkategorier',
    },
  },
  'Share class': {
    default: {
      en: 'Share class',
      nb: 'Aksjeklasse',
      sv: 'Andelsklass',
    },
    fliAlike: {
      en: 'Member type',
      nb: 'Medlemskategori',
      sv: 'Medlemstyp',
    },
    BRL: {
      en: 'Unit category',
      nb: 'Andelskategori',
      sv: 'Enhetskategori',
    },
    ESEK: {
      en: 'Section owner category',
      nb: 'Seksjonseierkategori',
      sv: 'Kategori ägare kategori',
    },
  },
  'Transfer of shares': {
    default: {
      en: 'Transfer of shares',
      nb: 'Overføring av aksjer',
    },
  },
  'Share register': {
    default: {
      en: 'Shareholder Register',
      nb: 'Aksjeeierbok',
      sv: 'Aktieregister',
    },
    fliAlike: {
      en: 'Membership register',
      nb: 'Medlemsregister',
      sv: 'Medlemskapsregister',
    },
    BRL: {
      en: 'Unit register',
      nb: 'Andelseierregister',
      sv: 'Enhetsregister',
    },
    ESEK: {
      en: 'Section owner register',
      nb: 'Seksjonseierregister',
      sv: 'Avsnittsregistret',
    },
  },
  'Share registery': {
    default: {
      en: 'Shareholder register',
      nb: 'Aksjeeierboken',
      sv: 'Aktieregister',
    },
    fliAlike: {
      en: 'Membership register',
      nb: 'Medlemsregisteret',
      sv: 'Medlemskapsregister',
    },
    BRL: {
      en: 'Unit register',
      nb: 'Andelseierregisteret',
      sv: 'Enhetsregister',
    },
    ESEK: {
      en: 'Section owner register',
      nb: 'Seksjonseierregisteret',
      sv: 'Avsnittsregistret',
    },
  },
  ShareRegister: {
    default: {
      en: 'Share register',
      nb: 'Aksjeregister',
      sv: 'Aktieregister',
      fliAlike: {
        en: 'Membership register',
        nb: 'Medlemsregister',
        sv: 'Medlemskapsregist',
      },
      BRL: {
        en: 'Unit register',
        nb: 'Andelseierregister',
        sv: 'Enhetsregister',
      },
      ESEK: {
        en: 'Section owner register',
        nb: 'Seksjonseierregister',
        sv: 'Avsnittsregistrer',
      },
    },
  },
  'Bank no': {
    default: {
      en: 'Dividend account',
      nb: 'Utbyttekonto',
      sv: 'Utdelningskonto',
    },
    AS: {
      en: 'Dividend account',
      nb: 'Utbyttekonto',
      sv: 'Utdelningskonto',
    },
    ASA: {
      en: 'Dividend account',
      nb: 'Utbyttekonto',
      sv: 'Utdelningskonto',
    },
  },
  'Notes/Share no.': {
    default: {
      en: 'Notes/Share no.',
      nb: 'Merknader/Aksjenummer',
      sv: 'Anteckningar / Aktienr.',
    },
    fliAlike: {
      en: 'Member no.',
      nb: 'Medlemsnummer',
      sv: 'Medlem nr.',
    },
    BRL: {
      en: 'Unit number',
      nb: 'Andelsnummer',
      sv: 'Enhetsnummer',
    },
    ESEK: {
      en: 'Section number',
      nb: 'Seksjonsnummer',
      sv: 'Avsnittsnummer',
    },
  },
  'Share no.': {
    default: {
      en: 'Share no.',
      nb: 'Aksjenummer',
      sv: 'Aktienr.',
    },
    fliAlike: {
      en: 'Member no.',
      nb: 'Medlemsnummer',
      sv: 'Medlem nr.',
    },
    BRL: {
      en: 'Unit number',
      nb: 'Andelsnummer',
      sv: 'Enhetsnummer',
    },
    ESEK: {
      en: 'Section number',
      nb: 'Seksjonsnummer',
      sv: 'Avsnittsnummer',
    },
  },
  'Number of shares': {
    default: {
      en: 'Number of shares',
      nb: 'Antall aksjer',
      sv: 'Antal delningar',
    },
    fliAlike: {
      en: 'Units',
      nb: 'Andeler',
      sv: 'Röstetal',
    },
    BRL: {
      en: 'Units',
      nb: 'Andeler',
      sv: 'Röstetal',
    },
    ESEK: {
      en: 'Number of sections',
      nb: 'Antall seksjoner',
      sv: 'Röstetal',
    },
  },
  Shares: {
    default: {
      en: 'Shares',
      nb: 'Aksjer',
      sv: 'Aktier',
    },
    fliAlike: {
      en: 'Membership',
      nb: 'Medlemskap',
      sv: 'Medlemskap',
    },
    BRL: {
      en: 'Units',
      nb: 'Andeler',
      sv: 'Enheter',
    },
    ESEK: {
      en: 'Sections',
      nb: 'Seksjoner',
      sv: 'Avsnitt',
    },
  },
  SharesVotes: {
    default: {
      en: 'Shares',
      nb: 'Aksjer',
      sv: 'Aktier',
    },
    fliAlike: {
      en: 'Votes',
      nb: 'Stemmer',
      sv: 'Röster',
    },
  },
  MaxVotesLimit: {
    default: {
      nb: 'Stemmetak',
      en: 'Votes limit',
      sv: 'Rösttak',
    },
  },
  maxVoteLimitTooltip: {
    default: {
      nb: 'Dette feltet brukes dersom vedtektene begrenser hvor mange aksjer en aksjeeier maksimalt kan stemme for',
      en: 'This field is used if the articles of association limit how many shares a shareholder can vote for',
      sv: 'Detta fält används om stadgarna begränsar hur många aktier en aktieägare maximalt kan rösta för',
    },
    fliAlike: {
      nb: 'Dette feltet brukes dersom vedtektene begrenser hvor mange stemmer et medlem maksimalt kan stemme for',
      en: 'This field is used if the articles of association limit how many votes a member can vote for',
      sv: 'Detta fält används om stadgarna begränsar hur många röster ett medlem maximalt kan rösta för',
    },
    ESEK: {
      nb: 'Dette feltet brukes dersom vedtektene begrenser hvor mange stemmer en seksjonseier maksimalt kan stemme for',
      en: 'This field is used if the articles of association limit how many votes a section owner can vote for',
      sv: 'Detta fält används om stadgarna begränsar hur många röster en sektionsägare maximalt kan rösta för',
    },
    BRL: {
      nb: 'Dette feltet brukes dersom vedtektene begrenser hvor mange stemmer en andelseier maksimalt kan stemme for',
      en: 'This field is used if the articles of association limit how many votes a unit holder can vote for',
      sv: 'Detta fält används om stadgarna begränsar hur många röster en andelsägare maximalt kan rösta för',
    },
  },
  SharesVotes2: {
    default: {
      en: 'Shares/Votes',
      nb: 'Aksjer/Stemmer',
      sv: 'Aktier/Röster',
    },
    fliAlike: {
      en: 'Votes',
      nb: 'Stemmer',
      sv: 'Röster',
    },
  },
  Votes: {
    default: {
      en: 'Votes',
      nb: 'Stemmer',
      sv: 'Röster',
    },
  },
  About: {
    default: {
      en: 'About',
      nb: 'Om',
      sv: 'Om',
    },
  },
  Candidate: {
    default: {
      en: 'Candidate',
      nb: 'Kandidat',
      sv: 'Kandidat',
    },
  },
  Organization: {
    default: {
      en: 'Organization',
      nb: 'Organisasjon',
      sv: 'Organisasjon',
    },
  },
  AboutBoardmatch: {
    default: {
      en: 'About Boardmatch',
      nb: 'Om Styrematch',
      sv: 'Om Styrematch',
    },
  },
  YouCandidate: {
    default: {
      en: 'You as candidate',
      nb: 'Deg som kandidat',
      sv: 'Dig som kandidat',
    },
  },
  YourOrganization: {
    default: {
      en: 'Your organization',
      nb: 'Din organisasjon',
      sv: 'Din organisasjon',
    },
  },
  'Nobody got elected': {
    default: {
      en: 'Nobody got elected',
      nb: 'Ingen ble valgt',
      sv: 'Ingen blev vald',
    },
  },
  'Total in company': {
    default: {
      en: 'Total in company',
      nb: 'Totalt i selskapet',
      sv: 'Totalt i företaget',
    },
    fliAlike: {
      en: 'Total in organization',
      nb: 'Totalt i organisasjon',
      sv: 'Totalt i organisationen',
    },
  },
  'of attending SC': {
    default: {
      en: 'of attending share capital',
      nb: 'av fremmøtt aksje&shy;kapital',
      sv: 'för att delta i SC',
    },
    fliAlike: {
      en: 'of attending',
      nb: 'av fremmøtte',
      sv: 'av att delta',
    },
  },
  'of represented votes': {
    default: {
      en: 'of represented votes',
      nb: 'av representerte stemmer',
      sv: 'av representerade röster',
    },
  },
  'The meeting ended.': {
    default: {
      en: 'The meeting ended.',
      nb: 'Møtet ble avsluttet.',
      sv: 'Mötet avslutades.',
    },
  },
  'List of attending shareholders': {
    default: {
      en: 'List of attending shareholders',
      nb: 'Liste over fremmøtte aksjeeiere',
      sv: 'Lista över deltagande aktieägare',
    },
    fliAlike: {
      en: 'List of attending members',
      nb: 'Liste over fremmøtte medlemmer',
      sv: 'Lista över deltagande medlemmar',
    },
  },
  'Save owner': {
    default: {
      en: 'Save owner',
      nb: 'Lagre eier',
      sv: 'Spara ägaren',
    },
    fliAlike: {
      en: 'Save member',
      nb: 'Lagre bruker',
      sv: 'Spara medlem',
    },
  },
  'Add new owner': {
    default: {
      en: 'Add new owner',
      nb: 'Legg til ny aksjeeier',
      sv: 'Lägg till ny ägare',
    },
    fliAlike: {
      en: 'Add new member',
      nb: 'Legg til nytt medlem',
      sv: 'Lägg till ny medlem',
    },
  },
  AddShareholder: {
    default: {
      en: 'Add shareholder',
      nb: 'Legg til aksjeeier',
      sv: 'Lägg till aktieägare',
    },
    BRL: {
      en: 'Add unit holder',
      nb: 'Legg til andelseier',
      sv: 'Lägg till andelsägare',
    },
    fliAlike: {
      en: 'Add member',
      nb: 'Legg til medlem',
      sv: 'Lägg till medlem',
    },
  },
  MaxVotesWheightTooltip: {
    default: {
      nb: 'Normalt har alle aksjer en stemme, men vedtektene kan angi at aksjene i en aksjeklasse har en annen stemmevekt. Endre dersom aksjene har annen stemmevekt enn 1',
      en: 'Normally, all shares have one vote, but the articles of association may specify that the shares in a share class have a different voting weight. Change if the shares have a different voting weight than 1',
    },
    FLIALIKE: {
      nb: 'Normalt har alle medlemmer en stemme, men vedtektene kan angi at medlemmene i en medlemskategori har en annen stemmevekt. Endre dersom medlemmene har annen stemmevekt enn 1',
      en: 'Normally, all members have one vote, but the articles of association may specify that the members in a member category have a different voting weight. Change if the members have a different voting weight than 1',
    },
  },
  voteRoofTooltip: {
    default: {
      nb: 'Dette feltet brukes dersom vedtektene begrenser hvor mange aksjer en aksjeeier maksimalt kan stemme for',
      en: 'This field is used if the articles of association limit how many shares a shareholder can vote for',
    },
    FLIALIKE: {
      nb: 'Dette feltet brukes dersom vedtektene begrenser hvor mange stemmer et medlem maksimalt kan stemme for',
      en: 'This field is used if the articles of association limit how many votes a member can vote for',
    },
    BRL: {
      nb: 'Dette feltet brukes dersom vedtektene begrenser hvor mange stemmer en andelseier maksimalt kan stemme for',
      en: 'This field is used if the articles of association limit how many votes a unit holder can vote for',
    },
  },
  editSnapshotInfoText: {
    default: {
      nb: 'Et øyeblikksbilde viser status på aksjeeierboken på et konkret tidspunkt, for eksempel hvordan eierskapet er akkurat nå.',
      en: 'A snapshot shows the status of the shareholder register at a specific point in time, for example how ownership is right now.',
      sv: 'En ögonblicksbild visar statusen på aktieägarregistret vid en specifik tidpunkt, till exempel hur ägandet är just nu.',
    },
    fliAlike: {
      nb: 'I tabellen nedenfor kan du justere status for ett eller flere medlemmer. Du kan også laste opp et helt nytt medlemsregister.',
      en: 'In the table below you can adjust the status for one or more members. You can also upload a completely new membership register.',
      sv: 'I tabellen nedan kan du justera status för en eller flera medlemmar. Du kan också ladda upp ett helt nytt medlemsregister.',
    },
    BRL: {
      nb: 'Et øyeblikksbilde viser status på andelseierboken på et konkret tidspunkt, for eksempel hvordan eierskapet er akkurat nå.',
      en: 'A snapshot shows the status of the unit register at a specific point in time, for example how ownership is right now.',
      sv: 'En ögonblicksbild visar statusen på enhetsregistret vid en specifik tidpunkt, till exempel hur ägandet är just nu.',
    },
  },

  'Add owner': {
    default: {
      en: 'Add investor',
      nb: 'Ny investor',
      sv: 'Ny ägare',
    },
    fliAlike: {
      en: 'Add member',
      nb: 'Nytt medlem',
      sv: 'Nytt medlem',
    },
  },
  'Share capital': {
    default: {
      en: 'Share capital',
      nb: 'Aksjekapital',
      sv: 'Aktiekapital',
    },
  },
  'Number of participants': {
    default: {
      en: 'Total representatives entitled to vote',
      nb: 'Totalt representerte stemmeberettigede',
      sv: 'Totalt antal rösträtt',
    },
    fliAlike: {
      en: 'Number of participants',
      nb: 'Antall deltakere',
      sv: 'Antal deltagare',
    },
  },
  'of total votes': {
    default: {
      en: 'of total votes',
      nb: 'av totale stemmer',
      sv: 'av de totala rösterna',
    },
  },
  ofVotesShares: {
    default: {
      en: 'of shares/votes',
      nb: 'av aksjer/stemmer',
      sv: 'av aktier/röster',
    },
    fliAlike: {
      en: 'of total votes',
      nb: 'av totale stemmer',
      sv: 'av de totala rösterna',
    },
  },
  'of total capital': {
    default: {
      en: 'of total capital',
      nb: 'av total kapital',
      sv: 'av det totala kapitalet',
    },
  },
  LongInviteButtonTooltipText: {
    default: {
      en: 'Invite participants to a meeting using a list. After inviting participants, you have the opportunity to invite additional participants until the start of the meeting. Do this by going to the section “Participants” underneath the agenda.',
      nb: 'Her kan du invitere deltakerne til møte fra en liste. Etter at du har invitert til møte vil du frem til møtestart ha mulighet til å invitere ytterligere deltakere ved å gå til seksjon «Deltakere» som ligger under agenda.',
      sv: 'Här kan du bjuda in deltagarna till ett möte från en lista. Efter att du har bjudit in till ett möte har du möjlighet att bjuda in ytterligare deltagare fram till mötesstart genom att gå till avsnittet "Deltagare" som finns under agendan.',
    },
  },
  'Select participants from list': {
    default: {
      en: 'Select participants from list',
      nb: 'Velg deltakere fra liste',
    },
  },
  'Create agenda as PDF': {
    default: {
      en: 'Create agenda as PDF',
      nb: 'Lag agenda som PDF',
    },
  },
  'Create meeting book as PDF': {
    default: {
      en: 'Create meeting book as PDF',
      nb: ' Lag møtebok som PDF',
    },
  },
  'A share class is necessary for creating a share register': {
    default: {
      en: 'A share class is necessary for creating a share register',
      nb: 'En aksjeklasse er nødvendig for å lage et aksjeregister',
      sv: 'En andelsklass är nödvändig för att skapa ett aktieregister',
    },
    fliAlike: {
      en: 'A membership category is necessary for creating a membership register',
      nb: 'En medlemskategori er nødvendig for å lage et medlemsregister',
      sv: 'En medlemskategori är nödvändig för att skapa ett medlemsregister',
    },
  },
  'Board members': {
    default: {
      en: 'Board members',
      nb: 'Styremedlemmer',
      sv: 'Styrelsemedlemmar',
    },
    SUBGR: {
      en: 'Members',
      nb: 'Medlemmer',
      sv: 'Medlemmar',
    },
    'SUBGR-DD': {
      en: 'Members',
      nb: 'Medlemmer',
      sv: 'Medlemmar',
    },
  },
  'Private setting': {
    AS: {
      en: 'Allow shareholders to see the entire shareholder register, including other shareholders.',
      nb: 'Tillat aksjeeiere å se hele aksjeeierboka inkludert andre aksjonærer ',
      sv: 'Låt dem med rollen som aktieägare se alla andra med samma roll',
    },
    ASA: {
      en: 'Allow shareholders to see the entire shareholder register, including other shareholders.',
      nb: 'Tillat aksjeeiere å se hele aksjeeierboka inkludert andre aksjonærer ',
      sv: 'Låt dem med rollen som aktieägare se alla andra med samma roll',
    },
    fliAlike: {
      en: 'Allow members to see the full member list including all members',
      nb: 'Tillat de med rollen medlem å se alle andre med samme rolle',
      sv: 'Tillåt ägare att se alla andra med samma roll',
    },
    BRL: {
      en: 'Allow unit holders to see the full register including other unit holders',
      nb: 'Tillat andelseiere å se hele andelsregisteret inkludert andre andelseiere',
      sv: 'Tillåt andelsägare att se hela registret inklusive andra andelsägare',
    },
    ESEK: {
      en: 'Allow section owners to see the full section register including other section owners',
      nb: 'Tillat seksjonseiere å se hele seksjonseierregisteret inkludert andre seksjonseiere',
      sv: 'Tillåt sektionsägare att se hela sektionsregistret inklusive andra sektionsägare',
    },
    default: {
      en: 'Allow owners to see other owners',
      nb: 'Tillat eiere å se andre eiere',
      sv: 'Tillåt ägare att se andra ägare',
    },
  },
  'Ordinary shares': {
    default: {
      en: 'Ordinary shares',
      nb: 'Ordinære aksjer',
      sv: 'Vanliga aktier',
    },
    fliAlike: {
      en: 'Ordinary member',
      nb: 'Ordinært medlem',
      sv: 'Vanlig medlem',
    },
    BRL: {
      en: 'Primary unit',
      nb: 'Primærandel',
      sv: 'Primär enhet',
    },
    ESEK: {
      en: 'Primary section',
      nb: 'Primærseksjon',
      sv: 'Primär sektion',
    },
  },
  companyInfo: {
    default: {
      en: 'Share data',
      nb: 'Aksjedata',
      sv: 'Aktiedata',
    },
    BRL: {
      en: 'Unit data',
      nb: 'Andelsdata',
      sv: 'Bostadsföreningens info',
    },
    ESEK: {
      en: 'Section data',
      nb: 'Seksjonsdata',
      sv: 'Info om delägande',
    },
    fliAlike: {
      en: 'Member data',
      nb: 'Medlemsdata',
      sv: 'Medlemsdata',
    },
  },
  DoYouWishToDeleteShareclassDialog: {
    default: {
      nb: 'Ønsker du å slette aksjeklassen?',
      en: 'Do you wish to delete the share class?',
      sv: 'Vill du radera aktieklassen?',
    },
    fliAlike: {
      nb: 'Ønsker du å slette medlemskategorien?',
      en: 'Do you wish to delete the member category?',
      sv: 'Vill du radera medlemstypen?',
    },
    BRL: {
      nb: 'Ønsker du å slette andelskategorien?',
      en: 'Do you wish to delete the unit category?',
      sv: 'Vill du radera enhetskategorin?',
    },
    ESEK: {
      nb: 'Ønsker du å slette seksjonseierkategorien?',
      en: 'Do you wish to delete the section owner category?',
      sv: 'Vill du radera sektionsägarkategorin?',
    },
  },
  YouAreAboutToDeleteShareclassDialogText: {
    default: {
      nb: 'Du er i ferd med å slette aksjeklassen',
      en: 'You are about to delete the share class',
      sv: 'Du är på väg att radera aktieklassen',
    },
    fliAlike: {
      nb: 'Du er i ferd med å slette medlemskategorien',
      en: 'You are about to delete the member category',
      sv: 'Du är på väg att radera medlemstypen',
    },
    BRL: {
      nb: 'Du er i ferd med å slette andelskategorien',
      en: 'You are about to delete the unit category',
      sv: 'Du är på väg att radera enhetskategorin',
    },
    ESEK: {
      nb: 'Du er i ferd med å slette seksjonseierkategorien',
      en: 'You are about to delete the section owner category',
      sv: 'Du är på väg att radera sektionsägarkategorin',
    },
  },

  OrdinaryShareclass: {
    default: {
      nb: 'Ordinære aksjer',
      en: 'Ordinary shares',
      sv: 'Vanlig aktieklass',
    },
    fliAlike: {
      en: 'Ordinary member category',
      nb: 'Vanlig medlemskategori',
      sv: 'Vanlig medlemstyp',
    },
    BRL: {
      en: 'Primary unit category',
      nb: 'Primær andelskategori',
      sv: 'Primär enhetskategori',
    },
    ESEK: {
      en: 'Primary section category',
      nb: 'Primær seksjonkategori',
      sv: 'Primär sektionskategori',
    },
  },
  shareNotesText: {
    default: {
      nb: 'Internt notat',
      en: 'Internal note',
      sv: 'Internt not',
    },
    alternative: {
      en: 'Notes',
      nb: 'Merknader',
      sv: 'Anteckningar',
    },
  },
  shareSort: {
    default: {
      en: 'Ownership',
      nb: 'Eierandel',
    },
    fliAlike: {
      en: 'Share',
      nb: 'Andel',
    },
    BRL: {
      en: 'Share',
      nb: 'Andel',
    },
    ESEK: {
      en: 'Share',
      nb: 'Andel',
    },
  },
  shareSortTooltip: {
    default: {
      en: 'Sort by number of shares for each shareholder',
      nb: 'Sorter etter antall aksjer for hver aksjonær',
    },
    fliAlike: {
      en: 'Sort by number of shares for each shareholder',
      nb: 'Sorter etter antall aksjer for hver aksjonær',
    },
    BRL: {
      en: 'Sort by number of shares for each shareholder',
      nb: 'Sorter etter antall aksjer for hver aksjonær',
    },
    ESEK: {
      en: 'Sort by number of shares for each shareholder',
      nb: 'Sorter etter antall andeler for hver medlemskategori',
    },
  },

  ParValueHint: {
    default: {
      en: 'This is the default value for the par value of the shares',
      nb: 'Dette er start verdien for pålydende verdi av aksjene',
      sv: 'Detta är startvärdet för aktiernas nominella värde',
    },
  },
  'Company name': {
    default: {
      en: 'Company name',
      nb: 'Selskapsnavn',
      sv: 'Företagsnamn',
    },
    fliAlike: {
      en: 'Member name',
      nb: 'Medlemsnavn',
      sv: 'Medlemsnamn',
    },
    BRL: {
      en: 'Company name',
      nb: 'Foretaksnavn',
      sv: 'Företagsnamn',
    },
    ESEK: {
      en: 'Company name',
      nb: 'Foretaksnavn',
      sv: 'Företagsnamn',
    },
  },
  no_invited_shareholders: {
    default: {
      en: 'No invited shareholders yet',
      nb: 'Enda ingen inviterte aksjeeiere',
      sv: 'Inga inbjudna aktieägare ännu',
    },
    fliAlike: {
      en: 'No invited members yet',
      nb: 'Enda ingen inviterte medlemmer',
      sv: 'Inga inbjudna medlemmar än',
    },
    BRL: {
      en: 'No invited unit holders yet',
      nb: 'Enda ingen inviterte andelseiere',
      sv: 'Inga inbjudna andelsägare ännu',
    },
    ESEK: {
      en: 'No invited section owners yet',
      nb: 'Enda ingen inviterte seksjonseiere',
      sv: 'Inga inbjudna sektionsägare ännu',
    },
  },
  Anonymous_shareholder: {
    default: {
      en: 'Anonymous shareholder',
      nb: 'Annonym aksjeeier',
      sv: 'Anonym aktieägare',
    },
    fliAlike: {
      en: 'Anonymous member',
      nb: 'Anonym medlem',
      sv: 'Anonym medlem',
    },
    BRL: {
      en: 'Anonymous unitholder',
      nb: 'Anonym andelseier',
      sv: 'Anonym andelsägar',
    },
    ESEK: {
      en: 'Anonym section owner',
      nb: 'Anonym seksjonseier',
      sv: 'Anonym sektionsägar',
    },
  },
  Delete_shares: {
    default: {
      en: 'Delete shares',
      nb: 'Slett aksjer',
      sv: 'Sletta aksje',
    },
    BRL: {
      en: 'Delete units',
      nb: 'Slett andeler',
      sv: 'Sletta andelar',
    },
    fliAlike: {
      en: 'Delete member units',
      nb: 'Slett medlem andeler',
      sv: 'Sletta medlem',
    },
    ESEK: {
      en: 'Delete sections',
      nb: 'Slett seksjonser',
      sv: 'Sletta sektions',
    },
  },
  Other_shareholders: {
    default: {
      en: 'Other',
      nb: 'Andre',
      sv: 'Andra',
    },
  },
  BoardGroupBoard: {
    default: {
      en: 'Board',
      nb: 'Styret',
      sv: 'Styrelsen',
    },
  },
  BoardGroupCustom: {
    default: {
      en: 'Custom',
      nb: 'Tilpasset',
      sv: 'Beställnings',
    },
  },
  ORDINARY_MAJORITY: {
    default: {
      en: 'Majority of votes cast (§ 5-17)',
      nb: 'Flertall av avgitte stemmer (§ 5-17)',
      sv: 'Majoriteten av de avgivna rösterna (§ 5-17)',
    },
    alternative: {
      en: 'Majority requirements',
      nb: 'Flertallskrav',
      sv: 'Majoritetskrav',
    },
  },
  STATUTORY_CHANGE: {
    default: {
      en: 'Minimum 2/3 of votes cast and 2/3 of shares represented (§ 5-18)',
      nb: 'Minst 2/3 av aksjer representert og 2/3 av avgitte stemmer (§ 5-18)',
      sv: 'Minst 2/3 av de avgivna rösterna och 2/3 av de representerade aktierna (§ 5-18)',
    },
    alternative: {
      en: 'At least 2/3 of those represented and of the votes cast',
      nb: 'Minst 2/3 av av de som er representert og avgitte stemmene',
      sv: 'Minst 2/3 av de representerade och av de avgivna rösterna',
    },
  },
  QUALIFIED_MAJORITY: {
    default: {
      en: 'Minimum 9/10 of shares represented and 2/3 of votes cast (§ 5-19)',
      nb: 'Minst 9/10 av aksjer representert og 2/3 av avgitte stemmer (§ 5-19)',
      sv: 'Minst 9/10 representerade aktier och 2/3 av de avgivna rösterna (§ 5-19)',
    },
    alternative: {
      en: 'At least 9/10 of those represented and 2/3 of the votes cast',
      nb: 'Minst 9/10 av de som er representert og 2/3 av avgitte stemmer',
      sv: 'Minst 9/10 av de representerade och 2/3 av de avgivna rösterna',
    },
  },
  UNANIMITY: {
    default: {
      en: 'Unanimity, all shareholders (§ 5-20)',
      nb: 'Enstemmighet, alle aksjonærer (§ 5-20)',
      sv: 'Enighet, alla aktieägare (§ 5-20)',
    },
    alternative: {
      en: 'Unanimity from all those entitled to vote',
      nb: 'Enstemmighet fra samtlige stemmeberettigede',
      sv: 'Enighet från alla röstberättigade',
    },
  },
  INVESTIGATION_PROPOSITION: {
    default: {
      en: 'Minimum 1/10 of shares represented (§ 5-25)',
      nb: 'Minst 1/10 av aksjer representert (§ 5-25)',
      sv: 'Minst 1/10 aktier representerade (§ 5-25)',
    },
    alternative: {
      en: 'At least 1/10 of the votes represented',
      nb: 'Minst 1/10 av stemmer representert',
      sv: 'Minst 1/10 av de representerade rösterna',
    },
  },
  LongTextProxyLegal: {
    default: {
      en: 'I/we authorize the person above to vote for my/our shares. If necessary, the proxy may transfer the proxy to a third party. By filling in the information below, I submit my electronic signature on this authorization.',
      nb: 'Jeg/vi gir med dette fullmakt til at ovennevnte kan stemme for mine/​våre aksjer. Om nødvendig kan fullmektig overføre fullmakten videre til en tredjemann. Ved å fylle ut opplysninger under legger jeg inn min elektroniske signatur på denne fullmakt.',
      sv: 'Jag / vi bemyndigar personen ovan att rösta på mina / våra aktier. Om det behövs kan fullmakten överföra fullmakten till tredje part. Genom att fylla i informationen nedan skickar jag in min elektroniska signatur på detta tillstånd.',
    },
    alternative: {
      en: 'I hereby authorize the above to vote on my behalf on the topics under consideration. If necessary, the proxy can transfer the power of attorney to a third party. By filling in the information below, I submit my electronic signature on this power of attorney.',
      nb: 'Jeg gir med dette fullmakt til at ovennevnte kan stemme på vegne av meg på de saker som er til behandling. Om nødvendig kan fullmektig overføre fullmakten videre til en tredjemann. Ved å fylle ut opplysninger under legger jeg inn min elektroniske signatur på denne fullmakt.',
      sv: 'Jag bemyndigar härmed ovanstående att rösta på mina vägnar i de ärenden som behandlas. Om nödvändigt kan fullmakten överföra fullmakten till tredje part. Genom att fylla i informationen nedan skickar jag in min elektroniska signatur på denna fullmakt.',
    },
  },
  VotingTieTextHelper: {
    default: {
      en: 'The deciding vote of the meeting chair in case of a tie (§ 5-17)',
      nb: 'Møteleders avgjørende stemme dersom stemmetallet er likt (§ 5-17)',
      sv: 'Mötesordförandens avgörande röst vid oavgjort (§ 5-17)',
    },
    alternative: {
      en: 'The deciding vote of the meeting chair in case of a tie',
      nb: 'Møteleders avgjørende stemme dersom stemmetallet er likt',
      sv: 'Avgörande omröstning för mötesordförande vid oavgjort',
    },
  },
  sale: {
    default: {
      en: 'Purchase/sale',
      nb: 'Kjøp/salg',
    },
  },
  sharedelete: {
    default: {
      en: 'Deletion',
      nb: 'Slett',
    },
  },
  inheritance_without_tax_continuity: {
    default: {
      en: 'Inheritance without tax continuity',
      nb: 'Arv uten skattemessig kontinuitet',
    },
  },
  gift_without_tax_continuity: {
    default: {
      en: 'Gift without tax continuity',
      nb: 'Gave uten skattemessig kontinuitet',
    },
  },
  inheritance_gift_without_tax_continuity: {
    default: {
      en: 'Inheritance/gift without tax continuity',
      nb: 'Arv/gave uten skattemessig kontinuitet',
    },
  },
  taxable_inheritance_gift_with_tax_continuity: {
    default: {
      en: 'Taxable inheritance/gift with tax continuity',
      nb: 'Avgiftspliktig arv/gave m. skattemessig kontinuitet',
    },
  },
  gift_sale: {
    default: {
      en: 'Gift sale',
      nb: 'Gavesalg',
    },
  },
  group_internal_transaction: {
    default: {
      en: 'Intra-group transaction',
      nb: 'Konsernintern overføring',
    },
  },
  transaction_with_tax_continuity: {
    default: {
      en: 'Transaction with tax continuity',
      nb: 'Overføring med skattemessig kontinuitet',
    },
  },
  division_divorce: {
    default: {
      en: 'Distribution between spouses upon divorce',
      nb: 'Fordeling mellom ektefeller ved skilsmisse',
    },
  },
  establish: {
    default: {
      en: 'First issue',
      nb: 'Stiftelse',
    },
  },
  parvaluechange: {
    default: {
      en: 'Change of par value',
      nb: 'Endring av pålydende',
    },
  },
  share_issue: {
    default: {
      en: 'Share issue',
      nb: 'Nyemisjon',
    },
  },
  share_issue_converting_of_claim: {
    default: {
      en: 'Share issue converting of claim',
      nb: 'Nyemisjon ved konvertering av fordring',
    },
  },
  share_issue_Conerninternal_transfer: {
    default: {
      en: 'Share issue Conerninternal transfer',
      nb: 'Nyemisjon ved konsernintern overføring',
    },
  },
  share_issue_employee_shares: {
    default: {
      en: 'Share issue employeeshares',
      nb: 'Nyemisjon ansattaksjer',
    },
  },
  emission: {
    default: {
      en: 'Share issue',
      nb: 'Kapitalforhøyelse',
    },
  },
  split: {
    default: {
      en: 'Split',
      nb: 'Splitt',
    },
  },
  splice: {
    default: {
      en: 'Splice',
      nb: 'Spleis',
    },
  },
  CreateNewShareIssue: {
    default: {
      en: 'New Share Issue',
      nb: 'Opprett ny emisjon',
    },
  },
  'New share issue': {
    default: {
      en: 'New Share Issue',
      nb: 'Emisjon',
    },
  },
  'Share Issues': {
    default: {
      en: 'Share Issues',
      nb: 'Kapitalforhøyelser',
    },
  },
  Investors: {
    default: {
      en: 'Investors',
      nb: 'Investorer',
    },
    fliAlike: {
      en: 'Members',
      nb: 'Medlemmer',
      sv: 'Medlemmar',
    },
    BRL: {
      en: 'unit holders',
      nb: 'Andelseiere',
      sv: 'Andelsägare',
    },
    ESEK: {
      en: 'Section owners',
      nb: 'Seksjonseiere',
      sv: 'Sektionsägare',
    },
  },
  Investments: {
    default: {
      en: 'Investments',
      nb: 'Investeringer',
    },
  },
  Sharenumbers: {
    default: {
      en: 'Share numbers',
      nb: 'Aksjenummer',
    },
    fliAlike: {
      en: 'Member numbers',
      nb: 'Medlemsnummer',
      sv: 'Medlemsnummer',
    },
    BRL: {
      en: 'Unit numbers',
      nb: 'Andelsnummer',
      sv: 'Andelsnummer',
    },
    ESEK: {
      en: 'Section numbers',
      nb: 'Seksjonsnummer',
      sv: 'Sektionsnummer',
    },
  },

  Investor: {
    default: {
      en: 'Investor',
      nb: 'Investor',
    },
    fliAlike: {
      en: 'Member',
      nb: 'Medlem',
      sv: 'Medlem',
    },
    BRL: {
      en: 'Unit holder',
      nb: 'Andelseier',
      sv: 'Andelsägare',
    },
    ESEK: {
      en: 'Section owner',
      nb: 'Seksjonseier',
      sv: 'Sektionsägare',
    },
  },
  import: {
    default: {
      en: 'Snapshot',
      nb: 'Øyeblikksbilde',
    },
  },
  AddInvestorsFromSpreadsheet: {
    default: {
      en: 'Add investors from spreadsheet',
      nb: 'Legg til investorer fra regneark',
    },
    fliAlike: {
      en: 'Add members from spreadsheet',
      nb: 'Legg til medlemmer fra regneark',
    },
    BRL: {
      en: 'Add unit holders from spreadsheet',
      nb: 'Legg til andelseiere fra regneark',
    },
    ESEK: {
      en: 'Add section owners from spreadsheet',
      nb: 'Legg til seksjonseiere fra regneark',
    },
  },
  AddInvestor: {
    default: {
      en: 'Add investor',
      nb: 'Legg til investor',
    },
    fliAlike: {
      en: 'Add member',
      nb: 'Legg til medlem',
    },
    BRL: {
      en: 'Add unit holder',
      nb: 'Legg til andelseier',
    },
    ESEK: {
      en: 'Add section owner',
      nb: 'Legg til seksjonseier',
    },
  },
  settShareValueForInvestor: {
    default: {
      nb: 'Sett inn verdi for antall aksjer for...',
      en: 'Set value amount of shares for...',
    },
    fliAlike: {
      nb: 'Sett inn verdi for antall andeler for...',
      en: 'Set value amount of units for...',
    },
    BRL: {
      nb: 'Sett inn verdi for antall andeler for...',
      en: 'Set value amount of units for...',
    },
    ESEK: {
      nb: 'Sett inn verdi for antall seksjoner for...',
      en: 'Set value amount of sections for...',
    },
  },
  DownloadInvestors: {
    default: {
      en: 'Download investors',
      nb: 'Last ned investorer',
    },
    fliAlike: {
      en: 'Download members',
      nb: 'Last ned medlemmer',
    },
    BRL: {
      en: 'Download unit holders',
      nb: 'Last ned andelseiere',
    },
    ESEK: {
      en: 'Download section owners',
      nb: 'Last ned seksjonseiere',
    },
  },

  transaction: {
    default: {
      en: 'Transaction',
      nb: 'Transaksjon',
    },
  },
  dividend: {
    default: {
      en: 'Dividend',
      nb: 'Utbytte',
    },
  },
  edit: {
    default: {
      en: 'Snapshot',
      nb: 'Øyeblikksbilde',
    },
  },
  'Create investor form spreadsheet': {
    default: {
      en: 'Create investor from spreadsheet',
      nb: 'Opprett investor fra regneark',
    },
    fliAlike: {
      en: 'Create member from spreadsheet',
      nb: 'Opprett medlem fra regneark',
    },
    BRL: {
      en: 'Create unit holder from spreadsheet',
      nb: 'Opprett andelseier fra regneark',
    },
    ESEK: {
      en: 'Create section owner from spreadsheet',
      nb: 'Opprett seksjonseier fra regneark',
    },
  },
  YouHaveReceivedSharesInAnEmissionIn: {
    default: {
      en: 'Notice of entry in the shareholder register in',
      nb: 'Melding om innføring i aksjeeierbok i',
    },
  },
  InTheShareRegisterOf: {
    default: {
      en: 'in the shareholder register of',
      nb: 'i aksjeeierboken til',
    },
  },
  FollowingHasBeenRegisteredOn: {
    default: {
      en: 'Following has been registered on',
      nb: 'Følgende er blitt registrert på',
    },
  },

  PaymentInformationForEquityIssue: {
    default: {
      en: 'Payment information for equity issue in',
      nb: 'Betalingsinformasjon for kapitalforhøyelse i',
    },
  },
  'owners and investors': {
    default: {
      en: 'owners and investors',
      nb: 'eiere og investorer',
    },
    fliAlike: {
      en: 'members',
      nb: 'medlemmer',
      sv: 'medlemmar',
    },
    BRL: {
      en: 'unitholders',
      nb: 'andelseiere',
      sv: 'andelsägare',
    },
    ESEK: {
      en: 'section owners',
      nb: 'seksjonseiere',
      sv: 'avsnittsägare',
    },
  },
  'investors or owners': {
    default: {
      en: 'investors/owners',
      nb: 'investorer/eiere',
    },
    fliAlike: {
      en: 'members',
      nb: 'medlemmer',
      sv: 'medlemmar',
    },
    BRL: {
      en: 'unitholders',
      nb: 'andelseiere',
      sv: 'andelsägare',
    },
    ESEK: {
      en: 'section owners',
      nb: 'seksjonseiere',
      sv: 'avsnittsägare',
    },
  },
  BoardChat: {
    default: {
      en: 'Board Chat',
      nb: 'Styrechat',
    },
    SUBGR: {
      en: 'Group chat',
      nb: 'Gruppechat',
    },
    'SUBGR-DD': {
      en: 'Group chat',
      nb: 'Gruppechat',
    },
  },
  MINUTES: {
    default: {
      en: 'Minutes',
      nb: 'Protokoll',
      sv: 'Protokoll',
    },
    SUBGR: {
      en: 'Meeting notes',
      nb: 'Referat',
    },
    'SUBGR-DD': {
      en: 'Meeting notes',
      nb: 'Referat',
    },
  },
  MinuteNotes: {
    default: {
      en: 'Minute notes',
      nb: 'Protokoll-notat',
    },
    SUBGR: {
      en: 'Meeting notes',
      nb: 'Referat',
    },
    'SUBGR-DD': {
      en: 'Meeting notes',
      nb: 'Referat',
    },
  },
  MinutesText: {
    default: {
      en: 'Minutes text',
      nb: 'Protokolltekst',
    },
    SUBGR: {
      en: 'Meeting notes text',
      nb: 'Referattekst',
    },
    'SUBGR-DD': {
      en: 'Meeting notes text',
      nb: 'Referattekst',
    },
  },

  REQUIRES_MINUTES: {
    default: {
      en: 'Requires minutes',
      nb: 'Mangler protokoll',
      sv: 'Kräver protokoll',
    },
    SUBGR: {
      en: 'Requires meeting notes',
      nb: 'Mangler referat',
    },
    'SUBGR-DD': {
      en: 'Requires meeting notes',
      nb: 'Mangler referat',
    },
  },
  REQUIRES_DISCUSSION: {
    default: {
      en: 'Requires comments',
      nb: 'Protokoll på høring',
      sv: 'Protokoll från förhandlingen',
    },
    SUBGR: {
      en: 'Requires comments',
      nb: 'Referat på høring',
    },
    'SUBGR-DD': {
      en: 'Requires comments',
      nb: 'Referat på høring',
    },
  },
  GO_TO_MINUTES: {
    default: {
      en: 'Go to minutes',
      nb: 'Gå til protokoll',
    },
    SUBGR: {
      en: 'Create meeting notes',
      nb: 'Lag referat',
    },
    'SUBGR-DD': {
      en: 'Create meeting notes',
      nb: 'Lag referat',
    },
  },
  GO_TO_MINUTES_TEXT: {
    default: {
      en: 'Go to the minutes section to prepare for comments or directly for signing.',
      nb: 'Gå til protokoll-seksjonen for å følge opp høringsprossessen og sende den godkjente protokollen til signering.',
    },
    SUBGR: {
      en: 'Go to the meeting notes section to prepare for comments or directly for signing.',
      nb: 'Gå til referat-seksjonen for å følge opp høringsprossessen og sende den godkjente referatet til signering.',
    },
    'SUBGR-DD': {
      en: 'Go to the meeting notes section to prepare for comments or directly for signing.',
      nb: 'Gå til referat-seksjonen for å følge opp høringsprossessen og sende den godkjente referatet til signering.',
    },
  },
  TIME_DISPLAY_IN_MINUTES: {
    default: {
      en: 'Time display in the minutes',
      nb: 'Visning av tid i protokollene',
    },
    SUBGR: {
      en: 'Time display in the meeting notes',
      nb: 'Visning av tid i referatet',
    },
    'SUBGR-DD': {
      en: 'Time display in the meeting notes',
      nb: 'Visning av tid i referatet',
    },
  },
  GO_TO_MINUTES_TEXT2: {
    default: {
      en: 'Go to the minutes section to follow up the review process and send the approved minutes for signing.',
      nb: 'Gå til protokoll-seksjonen for å følge opp høringsprossessen og sende den godkjente protokollen til signering.',
    },
    SUBGR: {
      en: 'Go to the meeting notes section to follow up the review process and send the approved version for signing.',
      nb: 'Gå til referat-seksjonen for å følge opp høringsprossessen og sende den godkjente versjon til signering.',
    },
    'SUBGR-DD': {
      en: 'Go to the meeting notes section to follow up the review process and send the approved version for signing.',
      nb: 'Gå til referat-seksjonen for å følge opp høringsprossessen og sende den godkjente versjon til signering.',
    },
  },
  requiesMinutesTooltip: {
    default: {
      en: 'The minutes must be drafted. The draft can either be sent for review, or directly for signing.',
      nb: 'Det må lages et utkast til protokoll. Utkastet kan enten sendes på høring, eller direkte til signering.',
    },
    SUBGR: {
      en: 'The meeting notes must be drafted. The draft can either be sent for review, or directly for signing.',
      nb: 'Det må lages et utkast til referat. Utkastet kan enten sendes på høring, eller direkte til signering.',
    },
    'SUBGR-DD': {
      en: 'The meeting notes must be drafted. The draft can either be sent for review, or directly for signing.',
      nb: 'Det må lages et utkast til referat. Utkastet kan enten sendes på høring, eller direkte til signering.',
    },
  },
  requiesDiscussionTooltip: {
    default: {
      en: 'Once the feedback from the review has been considered, the latest version of the minutes can be sent for signing.',
      nb: 'Når tilbakemeldingene etter høringen er tatt hensyn til kan siste versjon av protokollen sendes til signering.',
    },
    SUBGR: {
      en: 'Once the feedback from the review has been considered, the latest version of the meeting notes can be sent for signing.',
      nb: 'Når tilbakemeldingene etter høringen er tatt hensyn til kan siste versjon av referatet sendes til signering.',
    },
    'SUBGR-DD': {
      en: 'Once the feedback from the review has been considered, the latest version of the meeting notes can be sent for signing.',
      nb: 'Når tilbakemeldingene etter høringen er tatt hensyn til kan siste versjon av referatet sendes til signering.',
    },
  },
  completedDiscussionTooltip: {
    default: {
      en: 'The meeting has ended, and the signed minutes are available.',
      nb: 'Møtet er avsluttet og signert protokoll foreligger.',
    },
    SUBGR: {
      en: 'The meeting has ended, and the signed meeting notes are available.',
      nb: 'Møtet er avsluttet og signert referat foreligger.',
    },
    'SUBGR-DD': {
      en: 'The meeting has ended, and the signed meeting notes are available.',
      nb: 'Møtet er avsluttet og signert referat foreligger.',
    },
  },
  EditMinutesPdf: {
    default: {
      en: 'Edit minutes PDF',
      nb: 'Rediger protokoll-PDF',
    },
    SUBGR: {
      en: 'Edit meeting notes PDF',
      nb: 'Rediger referat-PDF',
    },
    'SUBGR-DD': {
      en: 'Edit meeting notes PDF',
      nb: 'Rediger referat-PDF',
    },
  },
  UnsignedProtokolButton: {
    default: {
      en: 'Unsigned minutes',
      nb: 'Usignert protokoll',
    },
    SUBGR: {
      en: 'Unsigned meeting notes',
      nb: 'Usignert referat',
    },
    'SUBGR-DD': {
      en: 'Unsigned meeting notes',
      nb: 'Usignert referat',
    },
  },
  UnsignedProtokolTooltip: {
    default: {
      en: 'Here you can upload an unsigned minutes (PDF) that will replace the minutes created by the system.',
      nb: 'Her kan du laste opp en usignert protokoll (PDF) som vil erstatte protokoll som er laget av systemet.',
    },
    SUBGR: {
      en: 'Here you can upload an unsigned meeting notes (PDF) that will replace the meeting notes created by the system.',
      nb: 'Her kan du laste opp en usignert referat (PDF) som vil erstatte referat som er laget av systemet.',
    },
    'SUBGR-DD': {
      en: 'Here you can upload an unsigned meeting notes (PDF) that will replace the meeting notes created by the system.',
      nb: 'Her kan du laste opp en usignert referat (PDF) som vil erstatte referat som er laget av systemet.',
    },
  },
  SignedProtokolButton: {
    default: {
      en: 'Signed minutes',
      nb: 'Signert protokoll',
    },
    SUBGR: {
      en: 'Signed meeting notes',
      nb: 'Signert referat',
    },
    'SUBGR-DD': {
      en: 'Signed meeting notes',
      nb: 'Signert referat',
    },
  },
  SignedProtokolTooltip: {
    default: {
      en: 'Here you can upload a signed minutes (PDF) that will replace the minutes created by the system.',
      nb: 'Her kan du laste opp en signert protokoll (PDF) som vil erstatte protokoll som er laget av systemet.',
    },
    SUBGR: {
      en: 'Here you can upload a signed meeting notes (PDF) that will replace the meeting notes created by the system.',
      nb: 'Her kan du laste opp en signert referat (PDF) som vil erstatte referat som er laget av systemet.',
    },
    'SUBGR-DD': {
      en: 'Here you can upload a signed meeting notes (PDF) that will replace the meeting notes created by the system.',
      nb: 'Her kan du laste opp en signert referat (PDF) som vil erstatte referat som er laget av systemet.',
    },
  },
  TooltipOnEditMinutes: {
    default: {
      en: 'Here you can edit the PDF minutes that the system has generated. This can, for example, be useful if it is desired to change the format or similar that is system generated. Changing the text should normally be done under the "minutes text" section of the agenda item.',
      nb: 'Her kan du redigere PDF-protokollen som systemet har generert. Dette kan for eksempel være nyttig dersom det er ønskelig å endre format eller lignende som er systemgenerert. Endring av tekst bør normalt gjøres under seksjonen “Protokolltekst” på agendapunktet.',
    },
    SUBGR: {
      en: 'Here you can edit the PDF meeting notes that the system has generated. This can, for example, be useful if it is desired to change the format or similar that is system generated. Changing the text should normally be done under the "meeting notes text" section of the agenda item.',
      nb: 'Her kan du redigere PDF-referatet som systemet har generert. Dette kan for eksempel være nyttig dersom det er ønskelig å endre format eller lignende som er systemgenerert. Endring av tekst bør normalt gjøres under seksjonen “referattekst” på agendapunktet.',
    },
    'SUBGR-DD': {
      en: 'Here you can edit the PDF meeting notes that the system has generated. This can, for example, be useful if it is desired to change the format or similar that is system generated. Changing the text should normally be done under the "meeting notes text" section of the agenda item.',
      nb: 'Her kan du redigere PDF-referatet som systemet har generert. Dette kan for eksempel være nyttig dersom det er ønskelig å endre format eller lignende som er systemgenerert. Endring av tekst bør normalt gjøres under seksjonen “referattekst” på agendapunktet.',
    },
  },
  MinutesCompletion: {
    default: {
      en: 'Minutes completion',
      nb: 'Ferdigstilling av protokoll',
    },
    SUBGR: {
      en: 'Meeting notes completion',
      nb: 'Ferdigstilling av referat',
    },
    'SUBGR-DD': {
      en: 'Meeting notes completion',
      nb: 'Ferdigstilling av referat',
    },
  },
  CreateNewMinutesDraft: {
    default: {
      en: 'Create new minutes draft',
      nb: 'Lag nytt utkast til protokoll',
    },
    SUBGR: {
      en: 'Create new meeting notes draft',
      nb: 'Lag nytt utkast til referat',
    },
    'SUBGR-DD': {
      en: 'Create new meeting notes draft',
      nb: 'Lag nytt utkast til referat',
    },
  },
  MINUTES_ATTACHMENTS: {
    default: {
      en: 'Minutes attachments',
      nb: 'Vedlegg til protokoll',
      sv: 'Bilagor till protokoll',
    },
    SUBGR: {
      en: 'Meeting notes attachments',
      nb: 'Vedlegg til referat',
    },
    'SUBGR-DD': {
      en: 'Meeting notes attachments',
      nb: 'Vedlegg til referat',
    },
  },
  IfresolutionDiffersTooltip: {
    default: {
      en: 'If the resolution deviates from what is described in the proposed resolution, enter the resolution here. This text will appear in the minutes',
      nb: 'Dersom vedtak avviker fra det som er beskrevet i forslag til vedtak, legger du inn vedtaket her. Denne teksten vil vises i protokollen.',
    },
    SUBGR: {
      en: 'If the resolution deviates from what is described in the proposed resolution, enter the resolution here. This text will appear in the meeting notes',
      nb: 'Dersom vedtak avviker fra det som er beskrevet i forslag til vedtak, legger du inn vedtaket her. Denne teksten vil vises i referatet.',
    },
    'SUBGR-DD': {
      en: 'If the resolution deviates from what is described in the proposed resolution, enter the resolution here. This text will appear in the meeting notes',
      nb: 'Dersom vedtak avviker fra det som er beskrevet i forslag til vedtak, legger du inn vedtaket her. Denne teksten vil vises i referatet.',
    },
  },
  ShowFollowupInMinutesPdf: {
    default: {
      en: 'Show in minutes',
      nb: 'Vis i protokoll',
    },
    SUBGR: {
      en: 'Show in meeting notes',
      nb: 'Vis i referat',
    },
    'SUBGR-DD': {
      en: 'Show in meeting notes',
      nb: 'Vis i referat',
    },
  },
  CommentsToProtokoll: {
    default: {
      en: 'Show in minutes',
      nb: 'Vis i protokoll',
    },
    SUBGR: {
      en: 'Show in meeting notes',
      nb: 'Vis i referat',
    },
    'SUBGR-DD': {
      en: 'Show in meeting notes',
      nb: 'Vis i referat',
    },
  },
  CreateNewMinutesDraftTooltip: {
    default: {
      en: 'Review the minutes PDF, if necessary, adjust the text by going to the minutes text in the agenda section above. The minutes can be sent for review, or directly for signing.',
      nb: 'Se gjennom PDF-protokollen, og juster eventuelt teksten ved å gå til protokolltekst som du finner i seksjonen agenda ovenfor. Protokoll kan sendes på høring, eller direkte til signering.',
    },
    SUBGR: {
      en: 'Review the meeting notes PDF, if necessary, adjust the text by going to the meeting notes text in the agenda section above. The meeting notes can be sent for review, or directly for signing.',
      nb: 'Se gjennom PDF-referatet, og juster eventuelt teksten ved å gå til referattekst som du finner i seksjonen agenda ovenfor. Referatet kan sendes på høring, eller direkte til signering.',
    },
    'SUBGR-DD': {
      en: 'Review the meeting notes PDF, if necessary, adjust the text by going to the meeting notes text in the agenda section above. The meeting notes can be sent for review, or directly for signing.',
      nb: 'Se gjennom PDF-referatet, og juster eventuelt teksten ved å gå til referattekst som du finner i seksjonen agenda ovenfor. Referatet kan sendes på høring, eller direkte til signering.',
    },
  },
  orgbrainHelpPROTOKOLLtextTooltip: {
    default: {
      en: 'Enter text from the agenda item. This text will be included in the meeting minutes.',
      nb: 'Her legger du inn teksten fra agendapunktet som skal inn i møteprotokollen',
    },
    SUBGR: {
      en: 'Enter text from the agenda item. This text will be included in the meeting notes.',
      nb: 'Her legger du inn teksten fra agendapunktet som skal inn i referattet',
    },
    'SUBGR-DD': {
      en: 'Enter text from the agenda item. This text will be included in the meeting notes.',
      nb: 'Her legger du inn teksten fra agendapunktet som skal inn i referattet',
    },
  },

  MissingMinutesWarning: {
    default: {
      en: 'Missing minutes. Do you want to create new one and continue?',
      nb: 'Mangler protokoll. Vil du lage en ny og fortsette?',
    },
    SUBGR: {
      en: 'Missing meeting notes. Do you want to create new one and continue?',
      nb: 'Mangler referat. Vil du lage en ny og fortsette?',
    },
    'SUBGR-DD': {
      en: 'Missing meeting notes. Do you want to create new one and continue?',
      nb: 'Mangler referat. Vil du lage en ny og fortsette?',
    },
  },

  MINUTES_ATTACHMENTS_TOOLTIP_BUTTON: {
    default: {
      en: 'Here you can upload PDF attachments that will be placed behind the minutes that the system has generated.',
      nb: 'Her kan du laste opp PDF-vedlegg som vil legge seg bak den protokoll som systemet har generert.',
    },
    SUBGR: {
      en: 'Here you can upload PDF attachments that will be placed behind the meeting notes that the system has generated.',
      nb: 'Her kan du laste opp PDF-vedlegg som vil legge seg bak den referat som systemet har generert.',
    },
    'SUBGR-DD': {
      en: 'Here you can upload PDF attachments that will be placed behind the meeting notes that the system has generated.',
      nb: 'Her kan du laste opp PDF-vedlegg som vil legge seg bak den referat som systemet har generert.',
    },
  },

  CreateNewMinutesHearingTooltip: {
    default: {
      en: 'Follow up on the review process. Comments can be added directly to the minutes PDF by clicking "Comment on Draft." Once the feedback from the review has been considered, the final version of the minutes can be sent for signing.',
      nb: 'Følge opp høringsprosessen. Kommentarer kan legges direkte i PDF-protokollen ved å eller klikke «Kommenter utkast». Når tilbakemeldingene etter høringen er tatt hensyn til kan siste versjon av protokollen sendes til signering.',
    },
    SUBGR: {
      en: 'Follow up on the review process. Comments can be added directly to the meeting notes PDF by clicking "Comment on Draft." Once the feedback from the review has been considered, the final version of the meeting notes can be sent for signing.',
      nb: 'Følge opp høringsprosessen. Kommentarer kan legges direkte i PDF-referatet ved å eller klikke «Kommenter utkast». Når tilbakemeldingene etter høringen er tatt hensyn til kan siste versjon av referatet sendes til signering.',
    },
    'SUBGR-DD': {
      en: 'Create new meeting notes draft',
      nb: 'Lag nytt utkast til referat',
    },
  },

  MinutesCompletionTooltip1: {
    default: {
      en: 'The meeting is over. Click “Next” or directly on step 2 “Create a new minutes draft” to create a minutes draft.',
      nb: 'Møtet er avsluttet, og du kan lage utkast til protokoll ved å klikke på «Neste» eller direkte på steg 2 «Lag nytt utkast til protokoll».',
    },
    SUBGR: {
      en: 'The meeting is over. Click “Next” or directly on step 2 “Create a new meeting notes draft” to create a meeting notes draft.',
      nb: 'Møtet er avsluttet, og du kan lage utkast til referat ved å klikke på «Neste» eller direkte på steg 2 «Lag nytt utkast til referat».',
    },
    'SUBGR-DD': {
      en: 'The meeting is over. Click “Next” or directly on step 2 “Create a new meeting notes draft” to create a meeting notes draft.',
      nb: 'Møtet er avsluttet, og du kan lage utkast til referat ved å klikke på «Neste» eller direkte på steg 2 «Lag nytt utkast til referat».',
    },
  },

  THIS_CHOICE_DETERMINES_MINUTES_TOOLTIP: {
    default: {
      en: 'This choice determines whether the minutes should contain a time indication for the agenda items. Whether it is duration and/or start time that is used is controlled by the previous setting.',
      nb: 'Dette valget avgjør om protokollene skal inneholde tidsangivelse for agendapunktene. Om det er varighet og/eller starttid som benyttes er styrt av foregående innstilling.',
    },
    SUBGR: {
      en: 'This choice determines whether the meeting notes should contain a time indication for the agenda items. Whether it is duration and/or start time that is used is controlled by the previous setting.',
      nb: 'Dette valget avgjør om referatet skal inneholde tidsangivelse for agendapunktene. Om det er varighet og/eller starttid som benyttes er styrt av foregående innstilling.',
    },
    'SUBGR-DD': {
      en: 'This choice determines whether the meeting notes should contain a time indication for the agenda items. Whether it is duration and/or start time that is used is controlled by the previous setting.',
      nb: 'Dette valget avgjør om referatet skal inneholde tidsangivelse for agendapunktene. Om det er varighet og/eller starttid som benyttes er styrt av foregående innstilling.',
    },
  },
  SHOW_START_TIME_DURATION_MINUTES: {
    default: {
      en: 'Show start time/duration for the agenda items in the minutes',
      nb: 'Vis starttid/varighet for agendapunktene i protokollene',
    },
    SUBGR: {
      en: 'Show start time/duration for the agenda items in the meeting notes',
      nb: 'Vis starttid/varighet for agendapunktene i referatet',
    },
    'SUBGR-DD': {
      en: 'Show start time/duration for the agenda items in the meeting notes',
      nb: 'Vis starttid/varighet for agendapunktene i referatet',
    },
  },

  MinutesErrMsg1: {
    default: {
      en: 'No decision text or minute notes',
      nb: 'Ingen vedtakstekst eller protokoll-notater',
    },
    SUBGR: {
      en: 'No decision text or meeting notes',
      nb: 'Ingen vedtakstekst eller referat',
    },
    'SUBGR-DD': {
      en: 'No decision text or meeting notes',
      nb: 'Ingen vedtakstekst eller referat',
    },
  },
  MinutesErrMsg2: {
    default: {
      en: 'No minute notes',
      nb: 'Ingen protokoll-notater',
    },
    SUBGR: {
      en: 'No meeting notes',
      nb: 'Ingen referat',
    },
    'SUBGR-DD': {
      en: 'No meeting notes',
      nb: 'Ingen referat',
    },
  },
  minutes_notes_comments: {
    default: {
      en: 'Minutes comments',
      nb: 'Kommentarer til protokoll',
    },
    SUBGR: {
      en: 'Meeting notes comments',
      nb: 'Kommentarer til referat',
    },
    'SUBGR-DD': {
      en: 'Meeting notes comments',
      nb: 'Kommentarer til referat',
    },
  },

  CommentsPDFInviteDiscussion1: {
    default: {
      en: 'Please go to the meeting to check the draft PDF of the minutes. If you have suggestions for changes, you can write this directly into the draft by clicking on "Show comments" at the top of the document. You can also go to "Comments" at the bottom of the meeting card.',
      nb: 'Vennligst gå til møtet for å kontrollere PDF-utkastet til protokoll. Dersom du har innspill til endringer kan du skrive dette rett inn i utkastet ved å klikke på “Vis kommentarer” øverst på dokumentet. Du kan også gå til “Kommentarer” nederst på møtekortet.',
    },
    SUBGR: {
      en: 'Please go to the meeting to check the draft PDF of the meeting notes. If you have suggestions for changes, you can write this directly into the draft by clicking on "Show comments" at the top of the document. You can also go to "Comments" at the bottom of the meeting card.',
      nb: 'Vennligst gå til møtet for å kontrollere PDF-utkastet til referat. Dersom du har innspill til endringer kan du skrive dette rett inn i utkastet ved å klikke på “Vis kommentarer” øverst på dokumentet. Du kan også gå til “Kommentarer” nederst på møtekortet.',
    },
    'SUBGR-DD': {
      en: 'Please go to the meeting to check the draft PDF of the meeting notes. If you have suggestions for changes, you can write this directly into the draft by clicking on "Show comments" at the top of the document. You can also go to "Comments" at the bottom of the meeting card.',
      nb: 'Vennligst gå til møtet for å kontrollere PDF-utkastet til referat. Dersom du har innspill til endringer kan du skrive dette rett inn i utkastet ved å klikke på “Vis kommentarer” øverst på dokumentet. Du kan også gå til “Kommentarer” nederst på møtekortet.',
    },
  },

  CommentsPDFInviteDiscussion2: {
    default: {
      en: 'Here you can enter your comments and then tick "Comments to protocol" before saving.',
      nb: 'Her kan du legge inn dine kommentarer for så å huke av “Kommentarer til protokoll” før du lagrer.',
    },
    SUBGR: {
      en: 'Here you can enter your comments and then tick "Comments to meeting notes" before saving.',
      nb: 'Her kan du legge inn dine kommentarer for så å huke av “Kommentarer til referat“ før du lagrer.',
    },
    'SUBGR-DD': {
      en: 'Here you can enter your comments and then tick "Comments to meeting notes" before saving.',
      nb: 'Her kan du legge inn dine kommentarer for så å huke av “Kommentarer til referat“ før du lagrer.',
    },
  },

  InviteToHearingSubject: {
    default: {
      en: 'You have received a draft copy of the minutes for your comments from',
      nb: 'Du har mottatt utkast til protokoll på høring fra',
    },
    SUBGR: {
      en: 'You have received a draft copy of the meeting notes for your comments from',
      nb: 'Du har mottatt utkast til referat på høring fra',
    },
    'SUBGR-DD': {
      en: 'You have received a draft copy of the meeting notes for your comments from',
      nb: 'Du har mottatt utkast til referat på høring fra',
    },
  },
  LackOfInformationInTheProtocoll: {
    default: {
      en: 'There is a lack of information in the protocol. Do you want to continue with these despite the following:',
      nb: 'Det mangler informasjon i protokoll. Vil du fortsette tross for følgende mangler:',
    },
    SUBGR: {
      en: 'There is a lack of information in the meeting notes. Do you want to continue with these despite the following:',
      nb: 'Det mangler informasjon i referat. Vil du fortsette tross for følgende mangler:',
    },
    'SUBGR-DD': {
      en: 'There is a lack of information in the meeting notes. Do you want to continue with these despite the following:',
      nb: 'Det mangler informasjon i referat. Vil du fortsette tross for følgende mangler:',
    },
  },
  GenerateNewMinutesConfirmation: {
    default: {
      en: 'You are about to reset current minutes status and generate new minutes',
      nb: 'Du er i ferd med å overskrive nåværende protokoll og generere ny protokoll',
    },
    SUBGR: {
      en: 'You are about to reset current meeting notes status and generate new meeting notes',
      nb: 'Du er i ferd med å overskrive nåværende referat og generere ny referat',
    },
    'SUBGR-DD': {
      en: 'You are about to reset current meeting notes status and generate new meeting notes',
      nb: 'Du er i ferd med å overskrive nåværende referat og generere ny referat',
    },
  },

  MeetingFilesTooltip: {
    default: {
      en: 'All files belonging to a meeting in the "Meetings" module will automatically be saved. Immediately after a meeting is created, a new folder will automatically be created under the relevant meeting type (Board Meeting, Regular General Meeting, etc.). By clicking the folders, you will have access to the meetings under each calendar year. Clicking on a specific meeting, will give you access to the files saved regarding that particular meeting. These files are automatically placed in either the “Attachments” folder or the “Minutes” folder. In the “Attachments” folder, you will find all attachments except the system-generated minutes. The minutes can be found in the “Minutes” folder.',
      nb: 'Her vil alle filer som tilhører et møte i modulen «Møter», automatisk bli lagret. Umiddelbart etter at et møte opprettes, vil en ny mappe automatisk bli laget under den aktuelle møtetype (styremøte, ordinær generalforsamling osv).  Ved å klikke på en av mappene så vil man få tilgang til de møter som ligger under hvert kalenderår. Ved å klikke på et konkret møte vil man få tilgang til de filer som er lagret på det konkrete møtet. Disse filene legges automatisk i enten mappen «Vedlegg» eller «Protokoll». I mappen «Vedlegg» finner du alle vedlegg foruten den systemgenererte protokoll som portalen automatisk lager. Denne finner du i mappen «Protokoll».',
    },
    SUBGR: {
      en: 'All files belonging to a meeting in the "Meetings" module will automatically be saved. Immediately after a meeting is created, a new folder will automatically be created under the relevant meeting type. By clicking the folders, you will have access to the meetings under each calendar year. Clicking on a specific meeting, will give you access to the files saved regarding that particular meeting. These files are automatically placed in either the “Attachments” folder or the “Meeting notes” folder. In the “Attachments” folder, you will find all attachments except the system-generated minutes. The meeting notes can be found in the “Meeting notes” folder.',
      nb: 'Her vil alle filer som tilhører et møte i modulen «Møter», automatisk bli lagret. Umiddelbart etter at et møte opprettes, vil en ny mappe automatisk bli laget under den aktuelle møtetype.  Ved å klikke på en av mappene så vil man få tilgang til de møter som ligger under hvert kalenderår. Ved å klikke på et konkret møte vil man få tilgang til de filer som er lagret på det konkrete møtet. Disse filene legges automatisk i enten mappen «Vedlegg» eller «Referat». I mappen «Vedlegg» finner du alle vedlegg foruten den systemgenererte referat som portalen automatisk lager. Denne finner du i mappen «Referat».',
    },
    'SUBGR-DD': {
      en: 'All files belonging to a meeting in the "Meetings" module will automatically be saved. Immediately after a meeting is created, a new folder will automatically be created under the relevant meeting type. By clicking the folders, you will have access to the meetings under each calendar year. Clicking on a specific meeting, will give you access to the files saved regarding that particular meeting. These files are automatically placed in either the “Attachments” folder or the “Meeting notes” folder. In the “Attachments” folder, you will find all attachments except the system-generated minutes. The meeting notes can be found in the “Meeting notes” folder.',
      nb: 'Her vil alle filer som tilhører et møte i modulen «Møter», automatisk bli lagret. Umiddelbart etter at et møte opprettes, vil en ny mappe automatisk bli laget under den aktuelle møtetype.  Ved å klikke på en av mappene så vil man få tilgang til de møter som ligger under hvert kalenderår. Ved å klikke på et konkret møte vil man få tilgang til de filer som er lagret på det konkrete møtet. Disse filene legges automatisk i enten mappen «Vedlegg» eller «Referat». I mappen «Vedlegg» finner du alle vedlegg foruten den systemgenererte referat som portalen automatisk lager. Denne finner du i mappen «Referat».',
    },
  },

  Dividend: {
    default: {
      en: 'Dividend',
      nb: 'Utbytte',
    },
  },
  Additional_yield: {
    default: {
      en: 'Additional dividend',
      nb: 'Tilleggsutbytte',
    },
  },
  Extraordinary_dividends: {
    default: {
      en: 'Extraordinary dividend',
      nb: 'Ekstraordinært utbytte',
    },
  },
  Refund_of_premium: {
    default: {
      en: 'Refund of premium capital',
      nb: 'Tilbakebetaling av overkurs',
    },
  },
  Reduction_of_share_capital: {
    default: {
      en: 'Reduction and refund of par value',
      nb: 'Reduksjon og utbetaling av pålydende',
    },
  },
  Shareholder_loans: {
    default: {
      en: 'Shareholder loan',
      nb: 'Aksjonærlån',
    },
  },
};

export const texts: TranslationTextMap = Object.assign({}, textForRoles, textsOrig);
