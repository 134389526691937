import {
  NotificationItemWithHtml,
  Notification,
  notificationCodes,
  INotification,
  convertIfString,
  NotificationTemplate,
} from '@orgbrain/lib-data';
import { createStringFromTemplate } from './lib-util';
import { formatDateTimeLocale } from './formatDateTimeLocale';

function checkPlaceHoldersExist(notificationCode: NotificationTemplate, data: any) {
  const used = {};
  const keys = Object.keys(data);

  for (const placeHolder of notificationCode.placeHolders) {
    if (!keys.includes(placeHolder)) {
      console.error(`Missing placeholder ${placeHolder} in data: ${JSON.stringify(data)}`);
    }
    used[placeHolder] = true;
  }

  for (const key of keys) {
    if (!used[key]) {
      console.error(`Unused key ${key} in data: ${JSON.stringify(data)}`);
    }
  }
}

export function createHtml(
  item: INotification,
  lang,
  includetitle,
  timeZone
): NotificationItemWithHtml {
  const code = item.code;
  const notificationCode = notificationCodes[code];

  if (!notificationCode) {
    console.error(' LEGACY notification item ', JSON.stringify(item));

    return null;
  }

  const id = item._id.toString();
  const createdAt = item.createdAt;

  if (notificationCode) {
    const template = notificationCode.templates[lang];
    const title = notificationCodes[code].title[lang];
    let data: Notification = convertIfString(item.data);

    // checkPlaceHoldersExist(notificationCode, data);

    const body = createStringFromTemplate(template, data);
    const due = data.due ? formatDateTimeLocale({ dd: data.due, lang, timeZone }) : '';
    const status = item.status;

    const htmlItem: NotificationItemWithHtml = {
      id,
      status,
      title,
      due,
      originalDue: data.due,
      body,
      createdAt,
      code,
      html: '',
      list: item.list,
    };
    htmlItem.html = makeHtmlForItem(htmlItem, includetitle, lang, timeZone);

    return htmlItem;
  }

  return null;
}

const makeHtmlForItem = (item: NotificationItemWithHtml, includetitle, lang, timeZone): string => {
  let html = '';
  if (includetitle) {
    html = `<div class="notification-title" > ${item.title}</div>`;
  }

  html += `<div class="notification-body"> ${item.body}</div>
      <div class="notification-date">
        ${formatDateTimeLocale({ dd: item.createdAt, lang, timeZone })}
      </div>`;
  return html;
};

// function formatDate(dd) {
//   const d = new Date(dd);
//   return (
//     ('0' + d.getDate()).slice(-2) +
//     '-' +
//     ('0' + (d.getMonth() + 1)).slice(-2) +
//     '-' +
//     d.getFullYear() +
//     ' ' +
//     ('0' + d.getHours()).slice(-2) +
//     ':' +
//     ('0' + d.getMinutes()).slice(-2)
//   );
// }
