export enum CredentialEnum {
  COGNITO = 'COGNITO',
  SMS = 'SMS',
  BANKID = 'BANKID',
  TOTP = 'TOTP',
  TRUSTED = 'TRUSTED',
  COZONE = 'COZONE',
  MANUAL = 'MANUAL',
  SPORTSID = 'SPORTSID',
  VIPPS = 'VIPPS',
  GOOGLE = 'GOOGLE',
  AZURE = 'AZURE',
}

export type FrontendOauthConfig = {
  client_id: string;
  redirectPath: string;
  auth: string;
  authURL: string;
  acr_values?: string;
  scopes?: string;
  hideInSettings: boolean; //
  hideInLogon: boolean; //
  prompt?: string; // select_account
};

export type BackendOauthConfig = {
  client_id: string;
  tokenURL: string;
  userInfoURL: string;
  // redirectPath: string;
  // auth: string;
  // authURL: string;
  acr_values?: string;
  secret: string; // This is the name of the environment variable that holds the secret
  scope?: string;
  allowEdit: boolean;
};

export type IdDisplayInfo = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  provider: CredentialEnum;
  name: string;
  id: string;
};

export type IdcredentialInfo = {
  provider: CredentialEnum;
  // img: string;
  logo?: string; // For onboard heade
  backGround?: string; // For onboard
  label: { en: string; nb: string };
  moduleId: string;
  moduleText: { en: string; nb: string };
  welcomeText?: { en: string; nb: string };
  isGenericOauth?: boolean;
  logonText?: string;
  logonImg?: string;
  logonWidth?: number;
  displayInfo: (record: any, user: any) => string;
  id2displayInfo: (id_token) => Partial<IdDisplayInfo>;
  allowEdit: boolean;
};
// Oath stuff in  here please

/*
Google
Microsoft
Idrettens ID
Vipps
Norwegian BankiD
*/

export const idCredentialInfos: IdcredentialInfo[] = [
  {
    provider: CredentialEnum.COGNITO,
    logonImg: 'assets/orgbrainlogomini.svg',

    label: { en: 'Orgbrain Password', nb: 'Passord' },
    moduleId: 'cognitoModuleId',
    moduleText: {
      nb: `Her kan du starte en prosess for å endre passordet på din Orgbrain-konto. 
      Dette passordet brukes sammen med din e-post-adresse for å logge inn på Orgbrain.
       Passordet må være sikkert og følge de anbefalte retningslinjene for lengde og kompleksitet.`,
      en: `Here you can start a process to change the password on your Orgbrain account.
        This password is used with your email address to log in to Orgbrain.
        The password must be secure and follow the recommended guidelines for length and complexity.`,
    },
    displayInfo: (record, user) => {
      return user.email;
    },
    id2displayInfo: (id_token) => {
      return { email: id_token.email, provider: CredentialEnum.COGNITO };
    },
    allowEdit: true,
  },

  {
    provider: CredentialEnum.GOOGLE,
    // img: 'assets/svg-icons/google.svg',
    label: { en: 'Google', nb: 'Google' },
    moduleId: 'googleidModuleId',
    moduleText: {
      nb: `Her kan du koble din Google-konto til din Orgbrain-konto for enkel innlogging.
        Når du har koblet til kontoen, kan du bruke din Google-bruker for å logge inn uten behov for et eget Orgbrain-passord.
        Din Google-konto brukes også for tjenester som Gmail, YouTube, Google Drive, og mange andre.`,
      en: `Here you can connect your Google account to your Orgbrain account for easy login.
        Once you have connected the account, you can use your Google user to log in without the need for a separate Orgbrain password.
        Your Google account is also used for services such as Gmail, YouTube, Google Drive, and many others.`,
    },
    isGenericOauth: true,
    logonImg: 'assets/logon/google.svg',
    logonWidth: 24,
    displayInfo: (record, user) => {
      return record?.data?.email;
    },
    id2displayInfo: (id_token) => {
      return {
        email: id_token.email,
        name: id_token.name,
        firstName: id_token.given_name,
        lastName: id_token.family_name,
        provider: CredentialEnum.GOOGLE,
      };
    },

    allowEdit: true,
  },
  {
    provider: CredentialEnum.AZURE,
    // img: 'assets/svg-icons/microsoft.svg',
    logonImg: 'assets/svg-icons/microsoft.svg',
    logonWidth: 24,
    label: { en: 'Microsoft', nb: 'Microsoft' },
    moduleId: 'azureModuleId',
    moduleText: {
      nb: `Her kan du koble din Microsoft-konto til din Orgbrain-konto, slik at den kan brukes til å logge inn.
       Din Microsoft-konto er den samme kontoen du benytter til Windows, Microsoft 365, Outlook.com, Skype og andre Microsoft-tjenester.`,
      en: `Here you can connect your Microsoft account to your Orgbrain account so that it can be used to log in.
        Your Microsoft account is the same account you use for Windows, Microsoft 365, Outlook.com, Skype, and other Microsoft services.`,
    },
    isGenericOauth: true,
    displayInfo: (record, user) => {
      return record.data.userPrincipalName;
    },
    id2displayInfo: (id_token) => {
      return {
        email: id_token.email,
        name: id_token.name,
        firstName: id_token.given_name,
        lastName: id_token.family_name,
        provider: CredentialEnum.AZURE,
      };
    },
    allowEdit: true,
  },
  {
    provider: CredentialEnum.SPORTSID,
    // img: 'assets/svg-icons/minIdrett.png',
    logo: 'assets/logos/MI-logo.svg',
    logonImg: 'assets/png/idrettens.png',
    logonWidth: 27,
    label: { en: 'Idrettens ID', nb: 'Idrettens ID' },
    moduleId: 'sportsidModuleId',
    moduleText: {
      // en: `Here you can register the authentication using Idrettens`,
      nb: `Her kan du koble Idrettens ID til din Orgbrain-konto for enkel innlogging. 
          Norges Idrettsforbund (NIF) har vedtatt at alle klubbmedlemmer over 15 år skal være validert gjennom Idrettens ID, så over en million nordmenn har en slik ID. 
          Idrettens ID regnes som en sikker løsning med automatisk kobling mot Folkeregisteret, og er i tillegg koblet opp mot idrettens øvrige IT-systemer, noe Orgbrain benytter seg av.
          Se https://www.idrettsforbundet.no/digital/idrettens-id/brukerveiledning/ for NIF sin brukerveiledning.`,
      en: `Here you can connect Idrettens ID to your Orgbrain account for easy login.
          The Norwegian Sports Federation (NIF) has decided that all club members over 15 years should be validated through Idrettens ID, so over a million Norwegians have such an ID.
          Idrettens ID is considered a secure solution with automatic connection to the National Registry, and is also linked to the other IT systems of the sports, which Orgbrain uses.
          See https://www.idrettsforbundet.no/digital/idrettens-id/brukerveiledning/ for NIF's user guide.`,
    },

    isGenericOauth: true,
    displayInfo: (record, user) => {
      return record.data?.email || record.identityId;
    },
    id2displayInfo: (id_token) => {
      return {
        email: id_token.email,
        name: id_token.name,
        firstName: id_token.given_name,
        lastName: id_token.family_name,
        provider: CredentialEnum.SPORTSID,
      };
    },
    allowEdit: false,
  },
  {
    provider: CredentialEnum.VIPPS,
    // img: 'assets/svg-icons/VIPPS-button.svg',
    logonImg: 'assets/logon/vipps.svg',
    logonWidth: 80,
    label: { en: 'Vipps', nb: 'Vipps' },
    moduleId: 'vippsModuleId',
    moduleText: {
      // en: `Here you can register the authentication using Vipps`,
      nb: `Her kan du koble Vipps til din Orgbrain-konto, slik at du senere kan logge inn ved hjelp av Vipps. 
      For å bruke Vipps må du ha Vipps-appen installert på din mobiltelefon. 
      Dersom du i Vipps-dialogen velger å bli husket, vil neste innlogging fra samme nettleser skje uten ytterligere sikkerhetssjekk. 
      Dette er derfor ikke å anbefale dersom du ikke er sikker på at enheten og nettleseren er privat og trygg.`,
      en: `Here you can connect Vipps to your Orgbrain account so that you can later log in using Vipps.
        To use Vipps, you must have the Vipps app installed on your mobile phone.
        If you choose to be remembered in the Vipps dialog, the next login from the same browser will take place without further security checks.
        This is therefore not recommended if you are not sure that the device and browser are private and secure.`,
    },
    isGenericOauth: true,
    displayInfo: (record, user) => {
      return record.data.email;
    },
    id2displayInfo: (id_token) => {
      return {
        email: id_token.email,
        name: id_token.name,
        firstName: id_token.given_name,
        lastName: id_token.family_name,
        provider: CredentialEnum.VIPPS,
      };
    },
    allowEdit: false,
  },
  {
    provider: CredentialEnum.BANKID,
    // img: 'assets/svg-icons/BANKID-button.svg',
    label: { en: 'Norsk BankID', nb: 'Norsk BankID' },
    moduleId: 'bankidModuleId',
    moduleText: {
      // en: `Here you can register the authentication using BankID`,
      nb: `Her kan du koble norsk BankID til din Orgbrain-konto, slik at du senere kan logge inn ved hjelp av BankID. 
      BankID kan brukes enten med en kodebrikke eller via BankID-appen på din mobiltelefon.
      Appen støtter også biometrisk autentisering.`,
      en: `Here you can connect Norwegian BankID to your Orgbrain account so that you can later log in using BankID.
        BankID can be used either with a code chip or via the BankID app on your mobile phone.
        The app also supports biometric authentication.`,
    },
    isGenericOauth: true,
    logonImg: 'assets/logon/bankid.svg',
    logonWidth: 24,
    displayInfo: (record, user) => {
      return record.data.name;
    },
    id2displayInfo: (id_token) => {
      return {
        name: id_token.name,
        firstName: id_token.given_name,
        lastName: id_token.family_name,
        provider: CredentialEnum.BANKID,
      };
    },
    allowEdit: false,
  },
];

function convertToDataURL(imageData, mimeType) {
  // Convert binary data to base64

  const rawPngData = Uint8Array.from(imageData);

  const base64Image = arrayBufferToBase64(rawPngData);

  // const encoded_string = base64.b64encode(image_file.read()).decode('utf-8');

  // return 'data:image/png;base64,' + imageData;

  // const base64String = btoa(imageData);

  // Create a data URL
  return base64Image;
}

function arrayBufferToBase64(buffer: Uint8Array): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return `data:image/png;base64,${window.btoa(binary)}`;
}

export const idCredentialInfosMap: { [key in CredentialEnum]: IdcredentialInfo } =
  idCredentialInfos.reduce((acc, item) => {
    acc[item.provider] = item;
    return acc;
  }, {} as { [key in CredentialEnum]: IdcredentialInfo });

export const oauthCredentialEnums: CredentialEnum[] = idCredentialInfos.map((c) => c.provider);

export const isOauthCredential: { [key in CredentialEnum]?: boolean } = {
  [CredentialEnum.BANKID]: true,
  [CredentialEnum.SPORTSID]: true,
  [CredentialEnum.VIPPS]: true,
  [CredentialEnum.GOOGLE]: true,
  [CredentialEnum.AZURE]: true,
};

// We have accidcentally created two enums for the same thing see CredentialEnum.
// We should really use CredentialEnum but we have legacy data (user.identities USE this)
// This is a temporary solution to convert between the two.
export enum IdentityType {
  no_bankid = 'no_bankid',
  sms = 'sms',
  cozone = 'cozone',
  cognito = 'cognito',
  totp = 'totp',
  manual = 'manual', // This is a special case because we store this informtion in OrganizationUserRoles  Not in the user data}
  sportsid = 'sportsid',
  vipps = 'vipps',
  google = 'google',
  azure = 'azure',
}

export enum SetContextResultEnum {
  NONEED = 'NONEED', // No need to set the context   all OK
  OK = 'OK', // Set the context and all OK
  ORGANIZATION_NOT_IN_USER = 'ORGANIZATION_NOT_IN_USER',
  NO_ORGANIZATION_IN_URL = 'NO_ORGANIZATION_IN_URL',
}

export const mfaResetIDS = [IdentityType.sms, IdentityType.totp];

const id2Credential: { [key in IdentityType]: CredentialEnum } = {
  [IdentityType.no_bankid]: CredentialEnum.BANKID,
  [IdentityType.sms]: CredentialEnum.SMS,
  [IdentityType.cozone]: CredentialEnum.COZONE,
  [IdentityType.cognito]: CredentialEnum.COGNITO,
  [IdentityType.totp]: CredentialEnum.TOTP,
  [IdentityType.manual]: CredentialEnum.MANUAL,
  [IdentityType.sportsid]: CredentialEnum.SPORTSID,
  [IdentityType.vipps]: CredentialEnum.VIPPS,
  [IdentityType.google]: CredentialEnum.GOOGLE,
  [IdentityType.azure]: CredentialEnum.AZURE,
};

const isCredential: { [key in CredentialEnum]: boolean } = {
  [CredentialEnum.COGNITO]: true,
  [CredentialEnum.SMS]: true,
  [CredentialEnum.BANKID]: true,
  [CredentialEnum.TOTP]: true,
  [CredentialEnum.TRUSTED]: true,
  [CredentialEnum.COZONE]: true,
  [CredentialEnum.MANUAL]: true,
  [CredentialEnum.SPORTSID]: true,
  [CredentialEnum.VIPPS]: true,
  [CredentialEnum.GOOGLE]: true,
  [CredentialEnum.AZURE]: true,
};

export function makeIntoCredential(identity: any): CredentialEnum {
  const cred = id2Credential[identity];
  if (cred) {
    return cred;
  }

  if (isCredential[identity]) {
    return identity as unknown as CredentialEnum;
  }
  return undefined;
}

export function makeIntoIdentity(cred: any): IdentityType {
  if (!isCredential[cred]) {
    return cred as unknown as IdentityType;
  }

  const id = Object.keys(id2Credential).find(
    (item) => id2Credential[item] === cred
  ) as IdentityType;
  if (id) {
    return id;
  }

  return undefined;
}

export function makeIntoCredentialArray(array: any) {
  if (!array) {
    return null;
  }
  return array.map((item) => makeIntoCredential(item));
}

export type CredentialKeys = {
  [key in CredentialEnum]: string; // Note that "key in".
};

// Data required for creating a session

export class SessionDataInit {
  _id?: string;
  userId: string;
  ip: string;
  expires?: string;
  // Device
  deviceId: string;

  //  session
  sessionToken: string; // current session  (same as cookie)
  // sub: string; //  Cognito sub
  email: string; //  user email
  slidePeriod: number; // millis for sliding window
  credentials: CredentialKeys; //
  idToken: string;
  lastActivity?: Date;
  inactivityPeriod?: { [cred in CredentialEnum]: number };
  trusted: boolean;
}

export class SessionData extends SessionDataInit {
  // Device
  override deviceId: string;

  //  session

  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  // temporary stuff
}

export class DeviceData {
  _id?: string;
  userId: string;
  expand?: boolean;
  // Device
  ip: string;
  fingerprint: number;
  trusted: boolean;
  deviceToken: string;
  name: string;

  expires: string;
  //  TODO probably just need expires
  deleted?: boolean;

  // tTO pas the front end someting compact.
  sessions?: SessionData[];
  mydevice?: boolean;
  createdAt?: string;
  updatedAt?: string;
  virgin: boolean;
}

export const credentialTypeLang: { [type in CredentialEnum]: { en: string; nb: string } } = {
  [CredentialEnum.BANKID]: {
    en: 'Norwegian BankID',
    nb: 'Norsk BankID',
  },
  [CredentialEnum.SMS]: {
    en: 'SMS',
    nb: 'SMS',
  },
  [CredentialEnum.COZONE]: {
    en: 'Cozone',
    nb: 'Cozone',
  },
  [CredentialEnum.COGNITO]: {
    en: 'AWS Cognito',
    nb: 'AWS Cognito',
  },
  [CredentialEnum.TOTP]: {
    en: 'Authenticator App',
    nb: 'Authenticator app',
  },
  [CredentialEnum.MANUAL]: {
    en: 'manual',
    nb: 'manual',
  },
  TRUSTED: {
    // Probably not used
    en: 'Trusted',
    nb: 'Trusted',
  },
  SPORTSID: {
    en: 'Idretts',
    nb: 'Idretts',
  },
  [CredentialEnum.VIPPS]: {
    en: 'Norwegian Vipps',
    nb: 'Norsk Vipps',
  },
  [CredentialEnum.GOOGLE]: {
    en: 'Google',
    nb: 'Google',
  },

  [CredentialEnum.AZURE]: {
    en: 'Microsoft',
    nb: 'Microsoft',
  },
};

export const identityTypeLang: { [type in IdentityType]?: { en: string; nb: string } } =
  Object.keys(credentialTypeLang).reduce((acc, item) => {
    const id: IdentityType = makeIntoIdentity(item);
    acc[id] = credentialTypeLang[item];
    return acc;
  }, {} as { [key in IdentityType]: { en: string; nb: string } });

export class IdentityRecord {
  identityType: IdentityType;
  identityId: string;
  data: any;
  // history: any[];
  allowLogon: boolean;
  // last verification.
  stamp: string | Date;
  createdAt: string | Date;
}
