// This is used for preprod

import { CredentialEnum, FrontendOauthConfig } from '../libs/lib-data/src/lib/session';
import { sharedenvironment } from './frontend-defaults';
import { monitorUrls } from './monitorService';
import { frontendNamesStaging, serverConfigsStaging } from './systemNamesAndUrls';

const APP_BASE_URL = 'https://preprod.staging.orgbrain.ai/';

const myenvironment = {
  production: true,
  name: 'PREPROD',
  frontendList: frontendNamesStaging,
  defaultServerConfig: serverConfigsStaging,

  academyBucketName: 'orgbrain-beta-site',
  logServiceUrl: monitorUrls.preprod.url, // DEPreCAte ME
  monitorUrl: monitorUrls.preprod.url,
  debug: true,
  monitorFrontendUrl: null,
  appBase: APP_BASE_URL,

  meetingEmailRecipientDomain: 'staging-meeting.orgbrain.ai',
  // mainServerUrl: 'https://preprod-server.orgbrain.ai',
  logoURL: 'https://orgbrain-preprod-site.s3.eu-central-1.amazonaws.com/',
  vapidPubKey:
    'BFgOaLG4mMaNglU4un2hG43LpekcCp0dBDzi67T86zSEZQFzTTfT7v2M8tp1LUgsl133JvXfD6-0a47lEPfpLRE',
  bankid2FA: true,

  oauth: {
    BANKID: {
      client_id: 'urn:my:application:identifier:2386',
      redirectPath: `/auth/index.html`,
      authURL: 'https://paul-leonard-organization-test.criipto.id/oauth2/authorize',
      acr_values: 'urn:grn:authn:no:bankid:substantial',
    },

    VIPPS: {
      client_id: 'urn:my:application:identifier:2386',
      redirectPath: `/auth/index.html`,
      authURL: 'https://paul-leonard-organization-test.criipto.id/oauth2/authorize',
      acr_values: 'urn:grn:authn:no:vipps',
    },

    SPORTSID: {
      client_id: 'orgbrain-client',
      redirectPath: `/auth/index.html`,
      authURL: 'https://auth.nif.test4.buypass.no/auth/realms/nif/protocol/openid-connect/auth',
      hideInSettings: false,
    },

    GOOGLE: {
      client_id: '949220376502-utmpbejijod67l4otq3c47g9cp1jheu1.apps.googleusercontent.com',
      redirectPath: `/auth/index.html`,
      authURL: 'https://accounts.google.com/o/oauth2/auth',
      scopes: 'openid profile email',
      prompt: 'select_account',
    },

    AZURE: {
      client_id: '19a2feb7-490f-4351-9b08-61fce8363210',
      redirectPath: `/auth/index.html`,
      authURL: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
      hideInLogon: false,
      hideInSettings: false,
      prompt: 'select_account',
    },
    ALTINN: {
      client_id: '369379f3-3b03-4f9b-b897-855f9f01b5f9',
      redirectPath: `/auth/index.html`,
      authURL: 'https://login.test.idporten.no/authorize',
      prompt: 'select_account',
      scopes: 'openid+profile+altinn:instances.write',
    },
  } as { [key in CredentialEnum]: FrontendOauthConfig },

  /* 
 `https://login.test.idporten.no/authorize?` +
      `client_id=${DIGDIR_API_CLIENT_INTEGRATION_ID}&` +
      `redirect_uri=${redirectUri}&` +
      `scope=openid+profile+altinn:instances.write&` +
      `response_type=code&` +
      // `acr_values=idporten-loa-substantial&` +
      // `ui_locales=nb&` +
      // `state=${state}&` +
      // `nonce=${nonce}&` +
      `code_challenge=${codeChallenge}&` +
      `code_challenge_method=S256`;
    */

  // oauth-OLD: {
  //   BANKID: {
  //     client_id: 'urn:my:application:identifier:2386',
  //     redirectPath: `/auth.html`,
  //     auth: 'nbid',
  //     authURL: 'https://paul-leonard-organization-test.criipto.id/oauth2/authorize',
  //   },
  //   SPORTSID: {
  //     client_id: 'orgbrain-client',
  //     redirectPath: `/auth.html`,
  //     auth: 'nbid',
  //     authURL: 'https://auth.nif.test4.buypass.no/auth/realms/nif/protocol/openid-connect/auth',
  //   },
  //   VIPPS: {
  //     client_id: 'urn:my:application:identifier:2386',
  //     redirectPath: `/auth.html`,
  //     // auth: 'nbid',
  //     authURL: 'https://paul-leonard-organization-test.criipto.id/oauth2/authorize',
  //     acr_values: 'urn:grn:authn:no:vipps', // urn:grn:authn:no:vipps
  //     hide: true,
  //   },
  //   GOOGLE: {
  //     client_id: '949220376502-utmpbejijod67l4otq3c47g9cp1jheu1.apps.googleusercontent.com',
  //     redirectPath: `/auth.html`,
  //     authURL: 'https://accounts.google.com/o/oauth2/auth',
  //     acr_values: null,
  //     scopes: 'openid profile email',
  //     hide: true,
  //   },
  //   AZURE: {
  //     client_id: '19a2feb7-490f-4351-9b08-61fce8363210',
  //     redirectPath: `/auth.html`,
  //     authURL: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  //     acr_values: null,
  //     hide: true,
  //   },
  // } as { [key in CredentialEnum]: any },

  azets: {
    client_id: 'orgbrain_sso',
    redirect: `${APP_BASE_URL}`,
    authURL: 'https://idp-develop-devdb.staging.cozone.com/oauth/login',
    jwksURL: 'https://idp-develop-devdb.staging.cozone.com/api/v1/oauth2/jwks',
  },

  loguncaughtErrors: false,
  adobe_embedded_key: '5b7c54a30d974e77bbd1b4676906fb03',
};

export const environment = Object.assign(sharedenvironment, myenvironment);
