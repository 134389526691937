export type TextSyncOptions = {
  fieldName: string; //A string representing this fields unique id f.example: "MessageInputField". This is what it searches for in the database
  identifierId: string; //A extra id for cases where the user can access this field from multiple forms. Example: In conversations identiferId would be the conversationId or channelId, if you dont want this just set it to 0
};
export type TextSyncInstanceType = {
  _id?: string;
  text: string; //A string storing the text that was writen
  fieldName: string; //A string representing this fields unique id f.example: "MessageInputField". This is what it searches for in the database
  userId?: string;
  organizationId?: string;
  identifierId?: string; //A extra id for cases where the user can access this field from multiple forms. Example: In conversations identiferId would be the conversationId or channelId
};
