export type ReloadHelperPayload = {
  lastGoodUrl?: string; // added here do not set
  context: string;
  url?: string;
};

export class ReloadHelper {
  static reload(payload: ReloadHelperPayload) {
    ReloadHelper.addLastGood(payload);
    console.log(JSON.stringify(payload, null, 2));

    localStorage.removeItem('lastGoodUrl');

    window.location.href = payload.url || window.location.href;
  }

  private static addLastGood(payload: ReloadHelperPayload) {
    payload.lastGoodUrl = localStorage.getItem('lastGoodUrl');
  }

  static reloadHome(payload: ReloadHelperPayload) {
    if (payload.url) {
      console.error('reloadHome should not have a url');
    }
    payload.url = location.origin;
    ReloadHelper.reload(payload);
  }

  static logPayload(payload) {
    console.log(JSON.stringify(payload, null));
  }
}
