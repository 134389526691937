import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  LOCALE_ID,
  ViewChild,
  OnDestroy,
  DoCheck,
} from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FetchDataHelperFnParams } from '@orgbrain/lib-util';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, debounceTime, filter } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

const KEY_PAGINATION = 'UserDefinedPagination';
@UntilDestroy()
@Component({
  selector: 'orgbrain-beautiful-pagination',
  templateUrl: './beautiful-pagination.component.html',
  styleUrls: ['./beautiful-pagination.component.scss'],
})
export class BeautifulPaginationComponent implements OnInit, OnDestroy, DoCheck {
  pageIndex = 0;
  pageSizeReal = 10;
  private pageSizeInput = 10;
  private subFilter;
  private subSort;
  @Input() set pageSize(val) {
    this.pageSizeReal = val || 10;
    this.pageSizeInput = this.pageSizeReal;
  }
  isLoading = false;
  filter = '';
  private _datasource: any[] = [];
  length = 0;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  hide: boolean;
  private initDone = new BehaviorSubject(false);
  @Input() set datasource(data: any[]) {
    this._datasource = data;
    this.length = data.length;
    this.pageIndex = 0;
    this.makeFilteredDataSource();
  }
  @Input() set onlyDataSource(data: any[]) {
    this._datasource = data;
    this.length = data.length;
    this.makeFilteredDataSource();
  }
  @Input() filterControl: FormControl;
  @Input() filterFields: string[];
  private _matTableDataSource;

  @Input() fetchFn: (input: FetchDataHelperFnParams) => any;

  @Input() set matTableDataSource(matTableDataSource: MatTableDataSource<any>) {
    this._matTableDataSource = matTableDataSource;
    if (this._matTableDataSource) {
      if (this._matTableDataSource.sort) {
        this.subscribeToSort(this._matTableDataSource.sort);
      }
      if (this.filterControl) {
        if (this.subFilter) {
          this.subFilter.unsubscribe();
        }

        this.subFilter = this.filterControl.valueChanges
          .pipe(untilDestroyed(this), debounceTime(500), distinctUntilChanged())
          .subscribe((filterValue) => {
            this.filter = filterValue.trim().toLocaleLowerCase();
            this.pageIndex = 0;
            this.fetchDataWithDataSource();
          });
      }
      this.fetchDataWithDataSource();
    }
  }

  @Output() pagination = new EventEmitter<any[]>();
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  ngOnInit() {
    let pagesUserDefined = +localStorage.getItem(KEY_PAGINATION);
    if (pagesUserDefined === this.pageSizeInput) {
      pagesUserDefined = null;
      localStorage.removeItem(KEY_PAGINATION);
    }

    if (pagesUserDefined) {
      this.pageSizeReal = pagesUserDefined;
    }
    this.translatePagination();
    this.initDone.next(true);
  }

  ngDoCheck() {
    this.hide =
      this.paginator &&
      this.paginator.length < this.pageSizeReal + 1 &&
      (this.pageSizeReal < 11 || this.paginator.length < 11);
  }
  ngOnDestroy() {}

  subscribeToSort(sort: MatSort) {
    if (this.subSort) {
      this.subSort.unsubscribe();
    }
    this.subSort = sort.sortChange.pipe(untilDestroyed(this)).subscribe((arr) => {
      this.fetchDataWithDataSource();
    });
  }

  async fetchDataWithDataSource() {
    await firstValueFrom(this.initDone.pipe(filter((val) => val)));
    this.isLoading = true;
    const payload: FetchDataHelperFnParams = {
      pageSize: this.pageSizeReal,
      currentPage: this.pageIndex + 1,
      sortDirection: 'asc',
    };
    if (this._matTableDataSource.sort) {
      payload.sortDirection = this._matTableDataSource.sort.direction;
      payload.activeField = this._matTableDataSource.sort.active as any;
    }
    if (this.filter) {
      payload.filter = this.filter;
      if (this.filterFields) {
        payload.filterFields = this.filterFields;
      }
    }

    let data: { docs: any; totalDocs: number; page: number };
    if (this.fetchFn) {
      try {
        data = await this.fetchFn(payload).toPromise();
      } catch (err: any) {
        console.error(err);
      }
    }

    if (!data) {
      data = {
        docs: [],
        totalDocs: 0,
        page: 1,
      };
    }

    this._matTableDataSource.data = data.docs;
    this.length = data.totalDocs;
    this.pageIndex = data.page - 1;

    this.isLoading = false;
  }

  onPageEvent(pageEvent: PageEvent) {
    this.pageIndex = pageEvent.pageIndex;
    this.pageSizeReal = pageEvent.pageSize;
    localStorage.setItem(KEY_PAGINATION, this.pageSizeReal.toString());
    if (this._matTableDataSource) {
      this.fetchDataWithDataSource();
    } else {
      this.makeFilteredDataSource();
    }
  }

  makeFilteredDataSource() {
    const from = this.pageIndex * this.pageSizeReal;

    setTimeout((_) => {
      this.pagination.emit((this._datasource || []).slice(from, from + this.pageSizeReal));
    }, 0);
  }

  private translatePagination() {
    if (this.locale !== 'nb') {
      return;
    }
    setTimeout((_) => {
      document
        .querySelectorAll('.mat-mdc-paginator-page-size-label')
        .forEach((selector) => (selector.innerHTML = 'Rader per side:'));
    }, 0);
  }
}
