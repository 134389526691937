import { ZERO_WIDTH_SPACE } from './constants';

export const mentionTagRegExp = /\[@\d+_[0-9a-f]{24}\]/;
export interface MentionableUser {
  userId: string;
  name: string;
  mentionLabel: string;
  email?: string;
}
function replaceHtmlEntities(str) {
  return str.replace(/&#8203;/g, ZERO_WIDTH_SPACE);
}
function splitStringIntoArrayOftokens(string) {
  let result = [];
  string = replaceHtmlEntities(string);
  while (string.length) {
    const indexOfAtSign = string.indexOf('@');

    if (indexOfAtSign !== -1) {
      let final;
      const indexOfSpaceSign = string.indexOf(ZERO_WIDTH_SPACE);
      if (indexOfSpaceSign !== -1) {
        final = indexOfSpaceSign;
      }

      if (final !== -1) {
        const splitter = string.slice(indexOfAtSign, final);
        const splitString1 = string.slice(0, indexOfAtSign);
        const splitString2 = string.slice(indexOfAtSign + splitter.length);
        // Empty space or a word before @ sign
        result.push(splitString1);
        // The Tag itself
        result.push(splitter);
        // The rest we reassign
        string = splitString2;
      } else {
        result.push(string);
        string = '';
      }
    } else {
      result.push(string);
      string = '';
    }
  }
  return result;
}
/**
 * A 'mention label' like @John_Lennon is encoded into a 'mention tag' like [@1_da394e98...].
 */
export function mentionEncodeChatMessage(
  messageBeforeEncoding: string,
  mentionableUsers: MentionableUser[]
) {
  const mentionedUserIds: Set<string> = new Set();

  const messageSplitIntoTokens = splitStringIntoArrayOftokens(messageBeforeEncoding);

  let nUsersMentioned = 0;

  for (let i = 0; i < messageSplitIntoTokens.length; i++) {
    const currentToken = messageSplitIntoTokens[i];
    if (currentToken.startsWith('@')) {
      const mentionLabel = currentToken.substring(1);
      const mentionedUser = mentionableUsers.find(
        (mentionableUser) => mentionableUser.mentionLabel === mentionLabel
      );

      if (mentionedUser !== undefined) {
        nUsersMentioned++;
        mentionedUserIds.add(mentionedUser.userId);
        const encodedMentionTag = `[@${nUsersMentioned}_${mentionedUser.userId}]`;
        messageSplitIntoTokens[i] = encodedMentionTag;
      }
    }
  }

  return {
    mentionEncodedMessage: messageSplitIntoTokens.join(''),
    mentionedUserIds: Array.from(mentionedUserIds.keys()),
  };
}

export function mentionDecodeChatMessage(
  rawMessageBeforeDecoding: string,
  messageMentionedUserIds: string[],
  mentionableUsers: MentionableUser[],
  skipLogicalCheckUserCount?: boolean,
  currentUserId: string = null
) {
  const isNoUsersMentionedInMessage =
    !skipLogicalCheckUserCount &&
    (!messageMentionedUserIds || messageMentionedUserIds.length === 0);
  if (isNoUsersMentionedInMessage) {
    return {
      mentionDecodedMessage: rawMessageBeforeDecoding,
      mentionDecodedMessageWithHTML: rawMessageBeforeDecoding,
    };
  }

  const messageTokensMentionDecoded: string[] = [];
  const messageTokensMentionDecodedHTML: string[] = [];

  const mentionTagRegExpInCapturingParentheses = new RegExp(`(${mentionTagRegExp.source})`);
  // When using "string".split(separator), if sepearator is a regular expression that contains capturing parentheses (),
  // matched results are included in the array.
  const messageSplitIntoTokens = rawMessageBeforeDecoding.split(
    mentionTagRegExpInCapturingParentheses
  );

  for (let i = 0; i < messageSplitIntoTokens.length; i++) {
    const currentToken = messageSplitIntoTokens[i];
    let mentionDecodedToken = currentToken;
    let mentionDecodedTokenHTML = currentToken;

    if (currentToken.match(mentionTagRegExp)) {
      const indexOfUnderscore = currentToken.indexOf('_');
      const mentionTagUserId = currentToken.substring(
        indexOfUnderscore + 1,
        currentToken.length - 1
      );

      const nameOfMentionedUser =
        mentionableUsers.find((mentionableUser) => mentionableUser.userId === mentionTagUserId)
          ?.name || '';

      let highlightUser = currentUserId ? currentUserId == mentionTagUserId : false;
      mentionDecodedToken = `@${nameOfMentionedUser}`;
      mentionDecodedTokenHTML = !nameOfMentionedUser
        ? ''
        : `<span class="bold" style="display: inline-block; font-weight: 500; ${
            highlightUser ? 'color: #F57C00' : ''
          }">${mentionDecodedToken}</span>`;
    }

    messageTokensMentionDecoded.push(mentionDecodedToken);
    messageTokensMentionDecodedHTML.push(mentionDecodedTokenHTML);
  }

  return {
    mentionDecodedMessage: messageTokensMentionDecoded.join(''),
    mentionDecodedMessageWithHTML: messageTokensMentionDecodedHTML.join(''),
  };
}
