import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moneydisplay',
  standalone: true,
})
export class MoneydisplayPipe implements PipeTransform {
  transform(val: number): string {
    // Format the output to display any way you want here.
    // For instance:
    if (val !== undefined && val !== null) {
      let formatter = new Intl.NumberFormat('nb-NO', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 6,
      });
      return formatter.format(val);
    } else {
      return '';
    }
  }
}
