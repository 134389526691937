import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'orgbrain-sticky-bottom',
  templateUrl: './bottom-buttons.component.html',
  styleUrls: ['./bottom-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomButtonsComponent implements OnInit {
  @ViewChild('fixedEl') fixedEl: ElementRef;
  @Input() parentClass = '';
  @Input() fullWidthMode = false;
  @Input() alignBottomToParent = false;
  @Input() borderBottomLeftRadius;
  @Input() borderBottomRightRadius;
  showContent = false;
  constructor(private cdr: ChangeDetectorRef) {}

  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent) {
    this.aggressiveTimeoutStrategy();
  }

  @HostListener('window:touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.aggressiveTimeoutStrategy();
  }

  ngOnInit(): void {
    setTimeout(
      () => {
        this.showContent = true;
        this.cdr.detectChanges();
      },
      this.alignBottomToParent ? 350 : 0
    );
  }

  private aggressiveTimeoutStrategy() {
    const timeouts = [0, 50, 100, 250, 350, 500, 1000, 1500, 2500, 5000];
    for (const timeout of timeouts) {
      setTimeout((_) => {
        this.updateFixedChildWidth();
      }, timeout);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateFixedChildWidth();
  }

  ngAfterViewInit(): void {
    this.updateFixedChildWidth();
  }

  ngAfterViewChecked() {
    this.updateFixedChildWidth();
  }

  updateFixedChildWidth(): void {
    if (!this.fullWidthMode) {
      let relativeToEl = this.fixedEl.nativeElement.parentElement.parentElement;
      if (!this.parentClass) {
        this.fixedEl.nativeElement.style.width = relativeToEl.offsetWidth + 'px';
      } else {
        while (
          relativeToEl &&
          relativeToEl.parentElement &&
          relativeToEl.classList &&
          !relativeToEl.classList.contains(this.parentClass)
        ) {
          relativeToEl = relativeToEl.parentElement;
        }
        if (!relativeToEl) {
          return;
        }

        const rect =
          relativeToEl &&
          relativeToEl.getBoundingClientRect &&
          relativeToEl.getBoundingClientRect();
        if (rect) {
          this.fixedEl.nativeElement.style.width = relativeToEl.offsetWidth + 'px';
          this.fixedEl.nativeElement.style.left = rect.left + 'px';
          if (this.alignBottomToParent) {
            var divBottomToViewportBottom = window.innerHeight - rect.bottom;
            this.fixedEl.nativeElement.style.bottom = divBottomToViewportBottom + 'px';
          }
        } else {
          this.fixedEl.nativeElement.style.width = relativeToEl.offsetWidth + 'px';
        }
      }
    } else {
      this.fixedEl.nativeElement.style.width = '100vw';
      this.fixedEl.nativeElement.style.left = 0;
    }

    if (this.borderBottomLeftRadius) {
      this.fixedEl.nativeElement.style['border-bottom-left-radius'] = this.borderBottomLeftRadius;
    }

    if (this.borderBottomRightRadius) {
      this.fixedEl.nativeElement.style['border-bottom-right-radius'] = this.borderBottomRightRadius;
    }
  }
}
