<div class="container" [class.mt-0]="minified">
  <ul class="progressbar" [class.mt-0]="minified" [class.mb-0]="minified">
    <li
      *ngFor="let step of stepDeclarations"
      [style.width.%]="widthOfAListItem"
      class="text-breaker"
      [class.active]="currentStep === step.stepName"
      [class.hoverable]="step.isStepClickable"
      (click)="onClick(step)"
      [matTooltip]="mobilePortraitQuery.matches ? step.stepTooltip : ''"
    >
      <span style="font-size: 14px">{{ step.stepTitle }}</span>
      <span *ngIf="step.stepHint" class="small-hint">{{ step.stepHint }}</span>
    </li>
  </ul>
</div>
