export enum CriiptoCallbackEvent {
  SIGNATORY_SIGNED = 'SIGNATORY_SIGNED',
  SIGNATORY_REJECTED = 'SIGNATORY_REJECTED',
  SIGNATORY_SIGN_ERROR = 'SIGNATORY_SIGN_ERROR',
}

export enum CriiptoStatusEnum {
  'OPEN' = 'OPEN',
}
export class CriiptoCallbackData {
  'event': CriiptoCallbackEvent;
  'signatoryId': string;
  'signatureOrderId': string;
}

export class CriiptoOrderStatus {
  'signatureOrder': {
    status: CriiptoStatusEnum;
    signatories: [
      {
        id: string;
        status: CriiptoStatusEnum;
      }
    ];
  };
}
