import { PermissionEnum, Role, RoleMapType } from './rolesAndPermissions';

//Checks if one of the input roles have the permission
export const hasPermission = function (
  permission: PermissionEnum,
  roles: Role[],
  roleMap: RoleMapType
) {
  if (!roleMap) {
    // TODO_PERMISSIONS
    console.error('DEBUG');
    throw new Error(' rolemap is not set ');
  }
  roles = roles || [];
  for (const role of roles) {
    if (roleMap[role] && roleMap[role][permission]) {
      return true;
    }
  }

  return false;
};
