import { AfterViewInit, Directive, ElementRef, OnInit } from '@angular/core';
import { StylePreferencesService } from '../services/style-preferences.service';

@Directive({
  selector: '[orgbrainHideTabHeader]',
})
export class HideTabHeaderDirective implements AfterViewInit {
  constructor(private element: ElementRef, private stylePreferences: StylePreferencesService) {
    console.log('orgbrainHideTabHeader');
  }

  ngAfterViewInit() {
    const labelsList = this.element.nativeElement.getElementsByClassName('mat-mdc-tab-header');

    if (labelsList.length === 1) {
      const el = labelsList[0];
      el.style.display = 'none';
    }
  }
}
