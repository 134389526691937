/* eslint-disable @angular-eslint/component-selector */

import { Component, OnInit } from '@angular/core';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: '[orgbrain-toast]',
  styleUrls: ['./orgbrain-toastr.scss'],
  templateUrl: `./orgbrain-toastr.component.html`,

  preserveWhitespaces: false,
})
export class OrgbrainToastComponent extends Toast implements OnInit {
  progressBarMode: 'buffer' | 'determinate' | 'indeterminate' = 'buffer';
  progressBarValue;
  progressAnimation: 'increasing' | 'decreasing' = 'increasing';
  // used for demo purposes

  // constructor is only necessary when not using AoT
  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);

    if (this.options.timeOut && this.options.progressBar) {
      this.progressBarMode = 'determinate';
      this.progressBarValue = this.options.progressAnimation === 'decreasing' ? 100 : 0;

      console.log('INIT VAL', this.progressBarValue);
    }
  }

  action(event: Event) {
    event.stopPropagation();
    // this.toastPackage.triggerAction();
    this.remove();
    this.toastPackage.triggerAction();
    return false;
  }

  setProgressBarValue(progress: number) {
    this.options.progressBar = true;
    this.progressBarValue = progress;
    // console.log('SET PROGRESS BAR', progress);
  }

  get myoptions(): any {
    return this.options;
  }
  ngOnInit() {
    if (this.options.timeOut && this.options.progressBar && !this.options.disableTimeOut) {
      const dt = 100;
      let left = this.options.timeOut;

      const timer = setInterval(() => {
        left -= dt;
        if (left <= 0) {
          clearInterval(timer);
        }

        if (this.options.progressAnimation === 'decreasing') {
          this.progressBarValue = ((left / this.options.timeOut) * 100) | 0;
        } else {
          this.progressBarValue =
            (((this.options.timeOut - left) / this.options.timeOut) * 100) | 0;
        }
        console.log(this.progressBarValue);
      }, dt);
    }

    // console.log('NDLELL ngOnInit()');
  }
}

/*

import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
 animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0,
        })
      ),
      transition(
        'inactive => active',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
              opacity: 0,
            }),
            style({
              transform: 'skewX(20deg)',
              opacity: 1,
            }),
            style({
              transform: 'skewX(-5deg)',
              opacity: 1,
            }),
            style({
              transform: 'none',
              opacity: 1,
            }),
          ])
        )
      ),
      transition(
        'active => removed',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: 'translate3d(100%, 0, 0) skewX(30deg)',
              opacity: 0,
            }),
          ])
        )
      ),
    ]),
  ],

  */
