<div class="orgbrain-style">
  <div class="orgbrain-data-table w-100 my-8">
    <table mat-table [dataSource]="dataSource" class="w-100" matSort class="w-100">
      <ng-container *ngFor="let item of _tableData?.columns || []" [matColumnDef]="item.columnDef">
        <th mat-header-cell [class]="item.headerClass" *matHeaderCellDef>{{ item.header }}</th>
        <td mat-cell [class]="item.class" *matCellDef="let element">
          <div *ngIf="item.key">
            {{ element[item.key] }}
          </div>

          <button
            mat-flat-button
            *ngIf="item.button && item.button.text && item.button.click"
            [color]="item.button.colour || 'primary'"
            (click)="item.button.click(element)"
          >
            {{ item.button.text }}
          </button>

          <div *ngIf="item.metaKey">
            <p *ngIf="element[item.metaKey].text">{{ element[item.metaKey].text }}</p>

            <button
              mat-flat-button
              *ngIf="element[item.metaKey].button"
              [color]="element[item.metaKey].colour || 'primary'"
              (click)="element[item.metaKey].click(element)"
            >
              {{ element[item.metaKey].button }}
            </button>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns" class="orgbrain-footer-row"></tr> -->
    </table>

    <orgbrain-beautiful-pagination></orgbrain-beautiful-pagination>
  </div>
</div>
