<div class="lang-switch">
  <ng-container *ngIf="lang === 'en'">
    <button mat-button (click)="selectLanguage('nb')">Norsk versjon</button>
    <button mat-button color="primary" (click)="selectLanguage('en')">English version</button>
  </ng-container>
  <ng-container *ngIf="lang === 'nb'">
    <button mat-button color="primary" (click)="selectLanguage('nb')">Norsk versjon</button>
    <button mat-button (click)="selectLanguage('en')">English version</button>
  </ng-container>
</div>
