/*
 *  Copyright 2010-2016 FinTech Neo AS ( fintechneo.com )- All rights reserved
 */

import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoParams } from './info';

@Component({
  templateUrl: 'informdialog.component.html',
  styleUrls: ['dialog.component.scss'],
})
export class InfoDialogComponent {
  trustedHtml: SafeHtml;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: InfoParams,
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    sanitizer: DomSanitizer
  ) {
    this.trustedHtml = sanitizer.bypassSecurityTrustHtml(data.message);
  }

  yes() {
    this.dialogRef.close(true);
  }
}
