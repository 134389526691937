export async function sleep(t): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), t));
}

/**
 * Hack to avoid having to migrte data.
 * Converts a stringified JSON object to a regular object.
 * If the input is already an object, it returns the input unchanged.
 * If the input is not a valid JSON string or an object, it returns the input unchanged.
 *
 * @param {any} data - The data to convert.
 * @returns {any} - The converted data if it was a stringified JSON, or the original data.
 */
export function convertIfString(data) {
  if (typeof data === 'string') {
    try {
      const parsedData = JSON.parse(data);
      // Check if parsedData is an object to ensure it was a valid JSON object string
      if (parsedData && typeof parsedData === 'object') {
        return parsedData;
      }
    } catch (e) {
      console.error('Failed to parse JSON string:', e);
      return data; // Return original data if parsing fails
    }
  }

  // If data is not a string or is already an object
  return data;
}

export function addParamToUrl({
  paramName,
  paramValue,
  url,
}: {
  paramName: string;
  paramValue: string;
  url: string;
}) {
  if (url.includes('?')) {
    url += '&';
  } else {
    url += '?';
  }

  url += `${paramName}=${paramValue}`;

  return url;
}

export function getParamFromUrl({ paramName, url }: { paramName: string; url: string }) {
  let index = url.indexOf(paramName + '=');
  if (index !== -1) {
    let param = url.slice(index + paramName.length + 1);

    if (param.indexOf('&') === -1) {
      return param;
    } else {
      return param.slice(0, param.indexOf('&'));
    }
  } else {
    return '';
  }
}

export function printClassName(mess, thing) {
  // console.log(mess, thing.constructor?.name);
}
