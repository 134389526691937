// Use to see if 2 objects are equal.
// Only valid for simple objects with primitive values.

export function areObjectsEqual(obj1, obj2) {
  // Check if both are arrays, compare their contents
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;
    for (let i = 0; i < obj1.length; i++) {
      if (obj1[i].toString() !== obj2[i].toString()) {
        return false;
      }
    }
    return true;
  }

  // Check if both inputs are objects and not null
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if they have the same number of keys
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all keys and their values are the same
  return keys1.every((key) => obj1[key] === obj2[key]);
}
