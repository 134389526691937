export class InfoProgress {
  loaded: number;
  total: number;
  error?: any;
  percentage?: number;
  showProgressBar?: boolean;
  hideTextProgress?: boolean;
}

export enum ButtonStyleEnum {
  primary = 'primary',
  blank = 'blank',
  outline = 'outline',
}
export class InfoOptions {
  disp: string;
  key: string;
  style?: ButtonStyleEnum;
  tip?: string;
}
export class InfoParams {
  title: string;
  message: string;
  dismiss: string;
  progress: {
    loaded: number;
    total: number;
    error?: any;
    percentage?: number;
    showProgressBar?: boolean;
    hideTextProgress?: boolean;
  };
  error: any;
  options?: InfoOptions[];
  imageUrl?: string;
  noCancel?: boolean;
  constructor({
    title,
    message,
    dismiss,
    progress,
    error,
    options,
    imageUrl,
    noCancel,
  }: {
    title: string;
    message?: string;
    dismiss?: string;
    progress?: InfoProgress;
    error?: any;
    options?: InfoOptions[];
    imageUrl?: string;
    noCancel?: boolean;
  }) {
    this.title = title;
    this.message = message;
    this.dismiss = dismiss;
    this.progress = progress;
    this.error = error;
    this.options = options;
    this.imageUrl = imageUrl;
    this.noCancel = noCancel;
  }
}
