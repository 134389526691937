// Returns true if all the  values in objB are present in objA and are equal

export function deepHasValues(objA, objB) {
  // Check if both objects are null or undefined
  if (objA == null || objB == null) {
    return false;
  }

  // Iterate over keys in objectB
  for (let key in objB) {
    if (objB.hasOwnProperty(key)) {
      // Check if the key exists in objectA
      if (!objA.hasOwnProperty(key)) {
        return false;
      }

      // Recursively compare if the values are objects
      if (typeof objA[key] === 'object' && typeof objB[key] === 'object') {
        if (!deepHasValues(objA[key], objB[key])) {
          return false;
        }
      } else if (objA[key] !== objB[key]) {
        // Compare primitive values
        return false;
      }
    }
  }

  return true;
}
