import { cloneRoleMap } from './role-funcs';
import { PermissionDependancyArrayMap, RoleMapType } from './rolesAndPermissions';

export function supplyDependancies(
  roleMap: RoleMapType,
  permissionMap: PermissionDependancyArrayMap
) {
  const newRoleMap = cloneRoleMap(roleMap);

  let dirty = true;
  while (dirty) {
    dirty = false;
    for (const role of Object.keys(newRoleMap)) {
      for (const perm of Object.keys(newRoleMap[role])) {
        for (const needPerm of permissionMap[perm] || [])
          if (!newRoleMap[role][needPerm]) {
            newRoleMap[role][needPerm] = true;
            dirty = true;
          }
      }
    }
  }
  return newRoleMap;
}
