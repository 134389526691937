import { ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ComponentRegistryService {
  private components = new Map<string, Type<any>>();

  constructor() {}

  registerComponent(key: string, component: Type<any>) {
    this.components.set(key, component);
  }

  getComponent(key: string): Type<any> | undefined {
    return this.components.get(key);
  }

  loadComponent(
    key: string,
    dynamicContainer: ViewContainerRef,
    inputs: any
  ): ComponentRef<any> | undefined {
    const component = this.getComponent(key);
    dynamicContainer.clear();
    if (component) {
      const componentRef = dynamicContainer.createComponent(component);
      console.log(`Component with key "${key}" loaded.`);
      if (inputs) {
        this.bindInputs(componentRef, inputs);
      }
      return componentRef;
    } else {
      console.error(`Component with key "${key}" not found.`);
      return undefined;
    }
  }

  private bindInputs(componentRef: ComponentRef<any>, inputs: { [key: string]: any }) {
    const inputKeys = Object.keys(inputs);

    // Match and assign only @Input properties
    inputKeys.forEach((inputName) => {
      // eslint-disable-next-line no-prototype-builtins
      if (componentRef.instance.hasOwnProperty(inputName)) {
        componentRef.instance[inputName] = inputs[inputName];
      } else {
        console.warn(
          `Input property "${inputName}" not found on component ${componentRef.instance.constructor.name}`
        );
      }
    });
  }
}
