import {
  OptionalPermissionBooleanMap,
  OptionalRoleBooleanMap,
  PermissionBooleanMap,
  RoleBooleanMap,
} from './rolesAndPermissions';

export function removeRoles(
  roles: OptionalRoleBooleanMap,
  toRemoveArray: OptionalRoleBooleanMap[]
): RoleBooleanMap {
  const newRoleMap: RoleBooleanMap = {} as RoleBooleanMap;

  for (const key of Object.keys(roles)) {
    let keep = true;
    for (const toRemove of toRemoveArray) {
      if (toRemove[key]) {
        keep = false;
        break;
      }
    }
    if (keep) {
      newRoleMap[key] = true;
    }
  }
  return newRoleMap;
}

export function removePermissions(
  perms: OptionalPermissionBooleanMap,
  toRemoveArray: OptionalPermissionBooleanMap[]
): PermissionBooleanMap {
  const newPermsisionMap: PermissionBooleanMap = {} as PermissionBooleanMap;

  for (const key of Object.keys(perms)) {
    let keep = true;
    for (const toRemove of toRemoveArray) {
      if (toRemove[key]) {
        keep = false;
        break;
      }
    }
    if (keep) {
      newPermsisionMap[key] = true;
    }
  }
  return newPermsisionMap;
}

export function removeFalseyValues(map: any) {
  for (const key of Object.keys(map)) {
    if (!map[key]) {
      delete map[key];
    }
  }
}
