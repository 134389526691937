export type ILabel = {
  _id: string;
  organizationId: string;
  labelType: LabelTypes;
  labels: Label[];
};
export type Label = {
  _id: string;
  labelText: string;
  labelColor: string;
  labelTextColor?: string;
};
export enum LabelTypes {
  TASK_LABEL = 'TASK_LABEL',
  MESSAGE_LABEL = 'MESSAGE_LABEL',
}
