import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export class OrgbrainMatTableDataSource<T> extends MatTableDataSource<T> {
  private previousSortHeaders: { active: string; direction: string }[] = [];
  private extraSortFieldGroupMap = {};
  constructor(a, extraSortFieldGroup?: { [key: string]: string }) {
    super(a);
    this.extraSortFieldGroupMap = extraSortFieldGroup || {};
    this.sortData = (a, b) => {
      // Just check if our browser supports this functionality
      if (
        '12'.localeCompare('2', undefined, {
          numeric: true,
          sensitivity: 'base',
        }) === 1
      ) {
        return this.sortDataCustomImplementation(a, b);
      } else {
        return this.sortDataImplementation(a, b);
      }
    };
  }

  sortDataCustomImplementation: (data: T[], sort: MatSort) => T[] = (
    data: T[],
    sort: MatSort
  ): T[] => {
    const active = sort.active;
    const direction = sort.direction;
    if (!active || direction == '') {
      return data;
    }
    let loop = [active];
    // Sometimes when specific field is selected, we must make sure that array is alos sorted by some another field
    const useForceSortKeyAsPrevious = this.extraSortFieldGroupMap[active];
    if (useForceSortKeyAsPrevious) {
      loop = [useForceSortKeyAsPrevious, active];
    }

    for (const sortKey of loop) {
      const index = this.previousSortHeaders.findIndex((item) => item.active === sortKey);
      if (index !== -1) {
        this.previousSortHeaders.splice(index, 1);
      }

      this.previousSortHeaders.push({ active: sortKey, direction });
    }

    const head = [...this.previousSortHeaders].reverse();
    return data.sort((a, b) => {
      let comparatorResult;
      let directionToGo;
      for (const obj of head) {
        let valueA: any = this.sortingDataAccessor(a, obj.active);
        let valueB: any = this.sortingDataAccessor(b, obj.active);
        directionToGo = obj.direction;
        // If there are data in the column that can be converted to a number,
        // it must be ensured that the rest of the data
        // is of the same type so as not to order incorrectly.
        const valueAType = typeof valueA;
        const valueBType = typeof valueB;

        if (valueAType === 'boolean') {
          valueA = Number(valueA).toString();
        }
        if (valueBType === 'boolean') {
          valueB = Number(valueB).toString();
        }

        if (valueAType === 'number') {
          valueA += '';
        }
        if (valueBType === 'number') {
          valueB += '';
        }

        comparatorResult = (valueA || '').localeCompare(valueB || '', undefined, {
          numeric: true,
          sensitivity: 'base',
        });

        if (comparatorResult !== 0) {
          break;
        }
      }

      return comparatorResult * (directionToGo == 'asc' ? 1 : -1);
    });
  };

  /**
   * https://github.com/angular/components/blob/5d65f1dc4a719fd673b4d77f2f5bdba61d7ac523/src/material/table/table-data-source.ts#L164C1-L172C6
   * Gets a sorted copy of the data array based on the state of the MatSort. Called
   * after changes are made to the filtered data or when sort changes are emitted from MatSort.
   * By default, the function retrieves the active sort and its direction and compares data
   * by retrieving data using the sortingDataAccessor. May be overridden for a custom implementation
   * of data ordering.
   * @param data The array of data that should be sorted.
   * @param sort The connected MatSort that holds the current sort state.
   */
  sortDataImplementation: (data: T[], sort: MatSort) => T[] = (data: T[], sort: MatSort): T[] => {
    const active = sort.active;
    const direction = sort.direction;
    if (!active || direction == '') {
      return data;
    }

    return data.sort((a, b) => {
      let valueA = this.sortingDataAccessor(a, active);
      let valueB = this.sortingDataAccessor(b, active);

      // If there are data in the column that can be converted to a number,
      // it must be ensured that the rest of the data
      // is of the same type so as not to order incorrectly.
      const valueAType = typeof valueA;
      const valueBType = typeof valueB;

      if (valueAType !== valueBType) {
        if (valueAType === 'number') {
          valueA += '';
        }
        if (valueBType === 'number') {
          valueB += '';
        }
      }

      // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
      // one value exists while the other doesn't. In this case, existing value should come last.
      // This avoids inconsistent results when comparing values to undefined/null.
      // If neither value exists, return 0 (equal).
      let comparatorResult = 0;
      if (valueA != null && valueB != null) {
        // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
        if (valueA > valueB) {
          comparatorResult = 1;
        } else if (valueA < valueB) {
          comparatorResult = -1;
        }
      } else if (valueA != null) {
        comparatorResult = 1;
      } else if (valueB != null) {
        comparatorResult = -1;
      }

      return comparatorResult * (direction == 'asc' ? 1 : -1);
    });
  };
}
