<pre style="background: aliceblue">


   BankID  05012539519  Paul  (Dark Lord)


   VIPPS   47 95990251
     SSN    63055595080
      PIN    1236
   
</pre>
<!-- 
      https://developer.vippsmobilepay.com/docs/test-environment/#test-apps
      https://ra-preprod.bankidnorge.no/#/search/endUser
   -->
