import { ChatMessageType, IChatMessage } from './chatChannel';
import { BoardMember } from './boardmember.class';
import { IUser } from './user';

export type ConversationGroupType = {
  _id?: string;
  name?: string;
  channelId: string;
  memberData: {
    userId: string;
    historySpanDate: Date;
    conversationLeader: boolean;
    inMeeting?: boolean;
  }[];
  isContactGroup: boolean;
};
export type LatestMessage = {
  message: string;
  decodedMessageHTML?: string;
  type: ChatMessageType;
  receivedAt: number;
  read: string[];
  readByThisUser?: boolean;
  fromUserId: string;
  senderFirstName: string;
  senderLastName: string;
  senderLogo: string;
  channelId: string;
};

export type ConversationGroupWithMembers = ConversationGroupType & {
  members?: BoardMember[];
  latestMessage: LatestMessage | null;
  unreadMessages: number;
  membersInVideoCall: number;
  membersDataObj?: {
    [userId: string]: {
      userId: string;
      historySpanDate: Date;
      conversationLeader: boolean;
    };
  };
};
export type SearchedMessage = IChatMessage & {
  conversation: ConversationGroupWithMembers;
  senderFirstName: string;
  senderLastName: string;
  senderLogo: string;
};
export type ContactGroupType = {
  _id?: string;
  name: string;
  description: string;
  memberIds: string[];
  position: number;
  deleted: boolean;
};
export type ContactGroupTypeFrontend = ContactGroupType & {
  members: BoardMember[];
  names?: string;
};
