import { Directive, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[orgbrainAutoFocus]',
})
export class AutoFocusDirective implements AfterViewInit, OnDestroy {
  intervalId: any;
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    console.log('AutoFocusDirective.ngAfterViewInit()');
    this.intervalId = setInterval(() => {
      if (this.el.nativeElement) {
        console.log('AutoFocusDirective.ngAfterViewInit() : SET FOCUS');
        this.el.nativeElement.focus();
        // Check if the element actually gets focused
        setTimeout(() => {
          if (document.activeElement === this.el.nativeElement) {
            console.log('Focus success');
            clearInterval(this.intervalId);
          } else {
            console.log('Focus failed');
          }
        }, 300);
      }
    }, 100);
  }
  ngOnDestroy() {
    if (this.intervalId) clearInterval(this.intervalId);
  }
}
