<div [ngClass]="{ 'ml-26': !noleftmargin, 'mr-26': !norightmargin }" style="position: relative">
  <button
    *ngIf="closeButton"
    mat-icon-button
    class="close-button close-pos"
    (click)="closeEvent.emit()"
  >
    <mat-icon>'close'</mat-icon>
  </button>

  <div
    [ngClass]="{
      headcontainer: true,
      size1: size === 1,
      size2: size === 2
    }"
    style="overflow: auto"
  >
    <ng-content select="[iconleft]"></ng-content>
    <div
      class="pr-5 black-text title-font"
      [class.universal-ellipsis]="!avoidEllipsis"
      [matTooltip]="headerTooltip"
    >
      {{ header }}
      <span class="help-icon-layout" *ngIf="helpTooltip" [matTooltip]="helpTooltip">
        <mat-icon [inline]="true">help_outline</mat-icon>
      </span>

      <a
        [href]="helpLink"
        *ngIf="helpLink"
        target="_blank"
        rel="noopener"
        mat-icon-button
        style="font-size: 20px; opacity: 0.5; position: absolute"
      >
        <mat-icon [inline]="true" style="position: absolute; top: 0">help_outline</mat-icon>
      </a>
      <ng-content select="[moduleInfoOpenButton]"></ng-content>
    </div>
    <span style="flex-grow: 1"></span>

    <div class="d-flex flex-col-sm" [class.items-center]="!skipItemsCenterClassOnRightPartOfBar">
      <ng-content select="[switch]"></ng-content>

      <ng-content select="[content]"></ng-content>
      <ng-container *ngIf="buttons && buttons.length > 0">
        <span style="flex-grow: 1"></span>
        <ng-container *ngFor="let button of buttons">
          <ng-container *ngIf="!button.isButton">
            <mat-icon
              class="maticonbutton"
              *ngIf="button.matIcon"
              (click)="button.click()"
              [matTooltip]="button.toolTip"
              [id]="button.id"
            >
              {{ button.matIcon }}
            </mat-icon>
            <mat-icon
              class="svgbutton"
              *ngIf="button.svgIcon"
              [svgIcon]="button.svgIcon"
              (click)="button.click()"
              [matTooltip]="button.toolTip"
              [id]="button.id"
            >
            </mat-icon>
          </ng-container>
          <ng-container *ngIf="button.isButton">
            <button
              mat-stroked-button
              color="primary"
              (click)="button.click()"
              class="new-add-button"
              [matTooltip]="button.toolTip"
            >
              <mat-icon *ngIf="button.matIcon">
                {{ button.matIcon }}
              </mat-icon>
              <span class="mr-5" *ngIf="button.text">
                {{ button.text }}
              </span>
            </button>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-content select="[more]"> </ng-content>
    </div>

    <div class="w-100">
      <ng-content select="[random1]"></ng-content>
      <ng-content select="[random2]"></ng-content>
    </div>
    <div
      class="w-100 border-box d-flex flex-wrap align-items-center flex-start mobile-justify-content"
      [class.pt-5]="!avoidPT5"
    >
      <div class="border-box d-flex flex-wrap flex-start mobile-width">
        <ng-content select="[search]"></ng-content>
        <ng-content select="[selector]"></ng-content>
      </div>
      <div>
        <ng-content select="[addbutton]"></ng-content>
      </div>
    </div>
  </div>
</div>
