import { MatDialog } from '@angular/material/dialog';

import { MatTableDataSource } from '@angular/material/table';

import { Component, Input, ViewChild } from '@angular/core';
import { BeautifulPaginationComponent } from '../../beautiful-pagination/beautiful-pagination.component';
import { MatSort } from '@angular/material/sort';

export type OrgbrainTableMetaItem = {
  colour?: string;
  text?: string;
  button?: string;
  icon?: string;
  click?: (row: any) => void;
  disabled?: (row: any) => boolean;
};

export type OrgbrainTableColumn = {
  key?: string; // field of the row to get data
  columnDef?: string; // name of the column   (if missing use key as columnDef)
  header?: string; // header for the column   (if missing use columnDef as header)
  button?: {
    colour?: string;
    text: string;
    icon?: string;
    click: (row: any) => void;
  }; // button to show in the column
  metaKey?: string; // is this a meta key
  class?: 'centered' | ''; // class to apply to the column
  headerClass?: 'centered' | ''; // class to apply to header
};

export type OrgbrainTableData = {
  columns: OrgbrainTableColumn[];
  rows: any[]; // rows of data  with fields form displayColumns
};

@Component({
  selector: 'orgbrain-table',
  templateUrl: './orgbrain-table.component.html',
  styleUrls: ['./orgbrain-table.component.scss'],
})
export class OrgbrainTableComponent {
  @ViewChild(BeautifulPaginationComponent, { static: true })
  paginatorComponent: BeautifulPaginationComponent;

  @ViewChild('sort') sort: MatSort;
  _tableData: OrgbrainTableData;

  @Input() set tableData(data: OrgbrainTableData) {
    if (!data) {
      this.dataSource = null;
      this.displayedColumns = null;
    } else {
      this.rebuild(data);
    }
  }

  dataSource: MatTableDataSource<any>;
  displayedColumns: any;

  constructor(public dialog: MatDialog) {}

  rebuild(data: OrgbrainTableData) {
    this._tableData = data;
    for (const item of data.columns) {
      item.columnDef = item.columnDef || item.key || item.metaKey;
      item.header = item.header || item.columnDef;
    }
    this.displayedColumns = data.columns.map((item) => item.columnDef);
    console.log(this.displayedColumns);

    this.dataSource = new MatTableDataSource(data.rows);
    this.dataSource.paginator = this.paginatorComponent.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data, header) => data[header];
  }
}
