<div class="toastr-container">
  <mat-icon *ngIf="myoptions.icon" class="icon-class" style="min-width: 24px"
    >{{ myoptions.icon }}
  </mat-icon>

  <div *ngIf="options.enableHtml" role="alert" [innerHTML]="message">1</div>
  <div class="w-100 flex-direction-column" style="max-height: 200px; overflow: auto">
    <div
      *ngIf="!options.enableHtml"
      role="alert"
      [attr.aria-label]="message"
      style="font-size: 14px; color: rgba(255, 255, 255, 0.87)"
      class="text-breaker"
    >
      {{ message || title }}
    </div>

    <div *ngIf="options.progressBar" class="w-100 mt-5">
      <mat-progress-bar [mode]="progressBarMode" [value]="progressBarValue"></mat-progress-bar>
    </div>
  </div>

  <div style="flex: 1 0 auto"></div>
  <button mat-button (click)="action($event)" style="color: #0097a7 !important">
    <span>
      {{ myoptions.buttonText || 'OK' }}
    </span>
  </button>
</div>
