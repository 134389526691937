import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoParams } from '../info';
import { SharedUtilService } from '../../util.service';

@Component({
  selector: 'orgbrain-errordialog',
  templateUrl: './errordialog.component.html',
  styleUrls: ['../dialog.component.scss'],
})
export class ErrorDialogComponent {
  trustedHtml: SafeHtml;
  errorHtml: SafeHtml;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: InfoParams,
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    sanitizer: DomSanitizer,
    public util: SharedUtilService
  ) {
    this.trustedHtml = data.message && sanitizer.bypassSecurityTrustHtml(data.message);
    this.errorHtml = sanitizer.bypassSecurityTrustHtml(this.util.sensibleMessage(data.error));
  }
}
