import { Injectable } from '@angular/core';
import { printClassName } from '@orgbrain/lib-data';
import { ReloadHelper } from './reloadHelper';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  allowedLang = {
    en: true,
    nb: true,
    sv: true,
    da: true,
  };
  constructor() {
    printClassName(' ---> ', this);
  }

  getLanguage() {
    let lang = localStorage.getItem('orgbrainLang');
    if (!lang) {
      lang = getLangFromNavigator();
    }

    return lang;
  }

  switchLanguage(lang) {
    console.log(' Setting language to ' + lang);
    localStorage.setItem('orgbrainLang', lang);

    ReloadHelper.reload({ context: 'langSwitch' });
  }

  getTimeZone() {
    const tz = localStorage.getItem('orgbrainTimeZone');
    return tz || Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Oslo';
  }
}

export function useSupportedLocale(lang) {
  lang = lang || '';
  if (lang.startsWith('en-')) {
    return 'en';
  }
  const map = {
    en: 'en',
    nb: 'nb',
    'nb-NO': 'nb',
    'nb-SJ': 'nb',
    // uncomment this when we start supporting these locales
    // sv: 'sv',
    // 'sv-AX': 'sv',
    // 'sv-FI': 'sv',
    // 'sv-SE': 'sv',
    // da: 'da',
    // 'da-DK': 'da',
    // 'da-GL': 'da',
  };
  lang = map[lang] || 'nb';

  return lang;
}

export function getLangFromNavigator() {
  const lang = navigator.language || navigator['userLanguage'] || '';
  return useSupportedLocale(lang);
}
