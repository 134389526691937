import { LogLevel } from './log';

export type FrontendErrorMessage = {
  context: string;
  data: any;
};
export class OrgbrainErrorBase {
  message: string;
  level: LogLevel;
  stack: string;
  code: string;

  constructor({
    message,
    level,
    stack,
    code,
  }: {
    message: string;
    level: LogLevel;
    stack?: any;
    code?: string;
  }) {
    this.message = message;
    this.level = level;
    this.code = code;
    if (stack) {
      this.stack = stack;
    } else {
      try {
        throw new Error();
      } catch (error: any) {
        this.stack = error.stack;
      }
    }
  }
}

export class OrgbrainErrorWrapper extends OrgbrainErrorBase {
  constructor(error: any, level?: LogLevel) {
    let message = 'empty';
    error = error || {};
    const finalLevel = typeof level === 'undefined' || level === null ? LogLevel.FATAL : level;
    if (error && error.message) {
      message = error.message;
    } else if (typeof error === 'string') {
      message = error;
    } else if (error) {
      try {
        message = JSON.stringify(error);
      } catch (stringifyError) {
        message = 'Error cannot be stringified';
      }
    }

    super({ message, level: finalLevel, stack: error.stack });
  }
}

export class OrgbrainInfo extends OrgbrainErrorBase {
  constructor(message: string, code?: string) {
    super({ message, level: LogLevel.INFO, code });
  }
}

export class OrgbrainWarn extends OrgbrainErrorBase {
  constructor(message: string, code?: string) {
    super({ message, level: LogLevel.WARN, code });
  }
}

export class OrgbrainError extends OrgbrainErrorBase {
  constructor(message: string, code?: string) {
    super({ message, level: LogLevel.ERROR, code });
  }
}

export class OrgbrainFatal extends OrgbrainErrorBase {
  constructor(message: string, code?: string) {
    super({ message, level: LogLevel.FATAL, code });
  }
}

export class OrgbrainDoom extends OrgbrainErrorBase {
  constructor(message: string, code?: string) {
    super({ message, level: LogLevel.DOOM, code });
  }
}
