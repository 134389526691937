export enum TimeEnum {
  MINUTE1 = 'MINUTE1',
  MINUTE5 = 'MINUTE5',
  MINUTE10 = 'MINUTE10',
  MINUTE15 = 'MINUTE15',
  MINUTE30 = 'MINUTE30',
  HOUR1 = 'HOUR1',
  HOUR2 = 'HOUR2',
  HOUR3 = 'HOUR3',
  HOUR6 = 'HOUR6',
  HOUR12 = 'HOUR12',
  DAY1 = 'DAY1',
  DAY2 = 'DAY2',
  DAY7 = 'DAY7',
  DAY14 = 'DAY14',
  DAY30 = 'DAY30',
}

export const map: { [key in TimeEnum]: { en: string; nb: string; millis: number; key?: string } } =
  {
    MINUTE1: { en: '1 minute', nb: '1 minutt', millis: 60 * 1000 },
    MINUTE5: { en: '5 minutes', nb: '5 minutter', millis: 5 * 60 * 1000 },
    MINUTE10: { en: '10 minutes', nb: '10 minutter', millis: 10 * 60 * 1000 },
    MINUTE15: { en: '15 minutes', nb: '15 minutter', millis: 15 * 60 * 1000 },
    MINUTE30: { en: '30 minutes', nb: '30 minutter', millis: 30 * 60 * 1000 },
    HOUR1: { en: '1 hour', nb: '1 time', millis: 60 * 60 * 1000 },
    HOUR2: { en: '2 hours', nb: '2 timer', millis: 2 * 60 * 60 * 1000 },
    HOUR3: { en: '3 hours', nb: '3 timer', millis: 3 * 60 * 60 * 1000 },
    HOUR6: { en: '6 hours', nb: '6 timer', millis: 6 * 60 * 60 * 1000 },
    HOUR12: { en: '12 hours', nb: '12 timer', millis: 12 * 60 * 60 * 1000 },
    DAY1: { en: '1 day', nb: '1 dag', millis: 24 * 60 * 60 * 1000 },
    DAY2: { en: '2 days', nb: '2 dager', millis: 2 * 24 * 60 * 60 * 1000 },
    DAY7: { en: '7 days', nb: '7 dager', millis: 7 * 24 * 60 * 60 * 1000 },
    DAY14: { en: '14 days', nb: '14 dager', millis: 14 * 24 * 60 * 60 * 1000 },
    DAY30: { en: '30 days', nb: '30 dager', millis: 30 * 24 * 60 * 60 * 1000 },
  };

export const array = Object.keys(map).map((key) => {
  map[key];
  map[key].key = key;
  return map[key];
});

export function timeStr2Millis(timeString: string): number {
  try {
    const millis = map[timeString]?.millis;

    if (millis) {
      return millis;
    }

    if (timeString.startsWith('MINUTE')) {
      const minutes = +timeString.substring(6);

      return minutes * 60 * 1000;
    } else if (timeString.startsWith('HOUR')) {
      const hours = +timeString.substring(4);

      return hours * 60 * 60 * 1000;
    } else if (timeString.startsWith('DAY')) {
      const days = +timeString.substring(3);

      return days * 24 * 60 * 60 * 1000;
    } else {
      const val = parseInt(timeString);
      if (isNaN(val)) {
        console.error('timeStr2Millis: invalid timeString', timeString);
        return 0;
      }
      return val;
    }
  } catch (error) {
    console.error('timeStr2Millis: invalid timeString', timeString);
    return 0;
  }
}
