import { Component, Inject, Input, OnInit } from '@angular/core';

import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCALE_ID } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PageNotFoundDialogComponent } from '../page-not-found-dialog/page-not-found-dialog.component';

@Component({
  selector: 'orgbrain-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  @Input() dialogRef: MatDialogRef<PageNotFoundDialogComponent>;
  url: string;
  texts = {
    notfound: {
      en:
        'Page not found. Either it does not exist or you do not have permission to access it. ' +
        ' If you believe this is an error in Orgbrain please contact support.' +
        ' Otherwise click the button below to go to the home page.',
      nb:
        'Siden finnes ikke. Enten eksisterer den ikke eller så har du ikke tilgang til den. ' +
        ' Hvis du mener dette er en feil i Orgbrain, ta kontakt med support.' +
        ' Ellers klikk på knappen under for å gå til hjemmesiden.',
    },
  };

  constructor(
    public _location: Location,
    public route: ActivatedRoute,
    public router: Router,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit() {
    console.log('PAGE NOT FOUND');
    this.url = this.route.snapshot.url.toString();
    // this.mess.error(" Page not found ").subscribe(_ => {
    //   this.router.navigateByUrl("");
    // });
  }

  goHome() {
    this.router.navigateByUrl('/');
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
