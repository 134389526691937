import { PermissionEnum, Role } from './rolesAndPermissions';

// priorities to be kept in sync with
// export enum ModuleEnum {
//   meetings = 'meetings',
//   files = 'files',
//   shareholders = 'shareholders',
//   boardschool = 'boardschool',
//   admin = 'admin',
//   select = 'select',
//   landing = 'landing',
//   configure = 'configure',
//   news = 'news',
//   surveys = 'surveys',
//   templates = 'templates',
//   organization = 'organization',
// }

/*
MEETINGS
OWNERS (This is missing from the matrix you posted)
MESSAGES
DATA ROOM
TASKS
CHAT
QUESTIONNAIRE (you call it in matrix SURVEYS)
ORGBRAIN ACADEMY
BOARD SCHOOL
TEMPLATES
*/

export const permissionsPriorityArray: PermissionEnum[] = [
  PermissionEnum.meetings,
  PermissionEnum.shareholders,
  PermissionEnum.news,
  PermissionEnum.files,
  PermissionEnum.tasks,
  PermissionEnum.chat,
  PermissionEnum.surveys,
  PermissionEnum.ORGBRAIN_ACADEMY,
  PermissionEnum.school,
  PermissionEnum.templates,
  PermissionEnum.organization,
  PermissionEnum.CONVERSATIONS_LIMITED,
  PermissionEnum.BOARDMATCH_ADMIN,

  PermissionEnum.CONVERSATIONS_CREATE,
  PermissionEnum.GA_MEETING_ADMIN,
  PermissionEnum.BOARD_MEETING_ADMIN,
  PermissionEnum.TASKS_ADMIN,
  PermissionEnum.ACCESS_TO_BOARD_MEETINGS,
  PermissionEnum.ACCESS_TO_GA_MEETINGS,
  PermissionEnum.SHAREHOLDER_ADMIN,
  PermissionEnum.ADMIN,
  PermissionEnum.ORGANIZATION_ADMIN,
  PermissionEnum.CAN_VOTE,
  PermissionEnum.SELLER_ADMIN,
  PermissionEnum.SELLER,
  PermissionEnum.BUYER_ADMIN,
  PermissionEnum.BUYER,
  PermissionEnum.CREATE_DD_GROUP,
  PermissionEnum.CREATE_SUB_GROUP,
  PermissionEnum.ACCESS_TO_SECURITY_TAB,
  PermissionEnum.CAN_VOTE_BOARDMEETING,
  PermissionEnum.SHAREHOLDER,
  PermissionEnum.OWNER,
  PermissionEnum.VIEW_SHARES,
  PermissionEnum.CREATE_SIGNING_ORDER,
  PermissionEnum.SEE_CYCLIC_CALENDAR,
  PermissionEnum.SEE_FOLLOWUP_CASES,
  PermissionEnum.SEE_USER_EMAILS,
  PermissionEnum.SEE_MANAGE_SIGNATURES,
  PermissionEnum.SEE_USER_NAMES,
  PermissionEnum.NEWS_ADMIN,
  PermissionEnum.GA_NEWS_ADMIN,
  PermissionEnum.TEMPLATES_ADMIN,
  PermissionEnum.ACCESS_TO_NEWS_FOR_BOARD,
  PermissionEnum.ACCESS_TO_NEWS_FOR_SHAREHOLDERS,
  PermissionEnum.MEETING_ADMIN,
  PermissionEnum.DATAROOM_ADMIN,
  PermissionEnum.CONVERSATIONS_ADMIN,
  PermissionEnum.DATAROOM_DOWNLOAD_ZIP,
  PermissionEnum.DATAROOM_COPY_FILE,
  PermissionEnum.DATAROOM_SEE_FILE_HISTORY,
  PermissionEnum.DATAROOM_CREATE_TASK_FROM_FILE,
  PermissionEnum.DATAROOM_SEE_FILE_DETAILS,
  PermissionEnum.DATAROOM_SEE_FILE_MENU,
  PermissionEnum.DATAROOM_SEE_SHOW_DELETED,
  PermissionEnum.DATAROOM_SET_FILE_STATUS,
  PermissionEnum.SEE_ACTION_POINTS,
  PermissionEnum.VIEW_SHARE_ISSUES,
  PermissionEnum.INITIATE_BANK_ID_SIGNING,
  PermissionEnum.INITIATE_ORGBRAIN_SIGNING,
  PermissionEnum.ACCESS_TO_SETTINGS_TAB,
  PermissionEnum.SEE_ENTITY_SENSITIVE,
  PermissionEnum.OPENAI_USER,
];

export const permissionsPriorityMap = {};

let iMax = 0;
for (let i = 0; i < permissionsPriorityArray.length; i++) {
  if (permissionsPriorityMap[permissionsPriorityArray[i]]) {
    console.log(permissionsPriorityArray[i], ' Duplicate in priority array');
  }
  permissionsPriorityMap[permissionsPriorityArray[i]] = i + 1;
  iMax = i + 1;
}

for (const perm of Object.keys(PermissionEnum)) {
  if (!permissionsPriorityMap[perm]) {
    console.log(`  Missing priority for ${perm}`);
    permissionsPriorityMap[perm] = ++iMax;
  }
}

// console.log(permissionsPriorityMap);

export const rolesPriorityArray: string[] = [
  Role.CEO,
  Role.CHAIR,
  Role.DEPUTYCHAIR,
  Role.BOARDMEMBER,
  Role.DEPUTYMEMBER,
  Role.OBSERVER,
  Role.BOARDSECRETARY,
  Role.AUDITOR,
  Role.SELLER_ADMIN,
  Role.SELLER,
  Role.BUYER_ADMIN,
  Role.BUYER,
  Role.ADMIN,
  Role.USER_ADMIN,
  Role.COLLABORATOR,
  Role.BOARDMATCH_ADMIN,
  Role.CONTACTPERSON,
  Role.SCHOOL,
  Role.SHAREHOLDER_PROXY,
  Role.OWNER,
  Role.SHAREHOLDER,
  Role.ORGBRAIN_SUPPORT,
  Role.ONBOARD_SUPPORT,

  Role.ROLE_1,
  Role.ROLE_2,
  Role.ROLE_3,
  Role.ROLE_4,
  Role.ROLE_5,
  Role.ROLE_6,
];

export const rolesPriorityMap = {};

for (let i = 0; i < rolesPriorityArray.length; i++) {
  rolesPriorityMap[rolesPriorityArray[i]] = i;
}

export function getRoleWithTopPriority(roles: Role[]) {
  let index;
  if (!roles?.length) {
    return '';
  }

  for (const role of roles) {
    const indexOfRole = rolesPriorityArray.indexOf(role);
    if (!index || (indexOfRole >= 0 && index > indexOfRole)) {
      index = indexOfRole;
      if (index === 0) {
        return role;
      }
    }
  }

  return rolesPriorityArray[index] || '';
}
