import { AbstractControl } from '@angular/forms';
import { orgbrainCountryCodes, countryCodes } from '@orgbrain/lib-data';

export function phoneValidator(control: AbstractControl): { [key: string]: any } {
  const val = control.value;

  console.log(' validate ' + val);
  if (!val || val === '') {
    // if control is empty return no error
    console.log(' val is null');
    return null;
  }

  if (!val.startsWith('+')) {
    return { invalid: 'must start with +' };
  }

  // var reg = new RegExp('/^d+$/');
  var reg = new RegExp('^[0-9]+$');
  if (val.substring(1).length > 0 && !reg.test(val.substring(1))) {
    return { invalid: 'Must only contain digits after the +' };
  }

  const country = orgbrainCountryCodes.find((item) => val.startsWith(item.code));

  if (!country) {
    const wrong = countryCodes.find((item) => val.startsWith(item.code));
    if (!wrong) {
      return { invalid: 'Country code not valid' };
    } else {
      return { invalid: `SMS not supported for ${wrong.name}` };
    }
  }

  if (val.length !== country.code.length + country.size) {
    return { invalid: `Must be ${country.size} digits after country code for ${country.name}` };
  }

  console.log('phone valid OK');
  // if true, return no error (no error), else return error passed in the second parameter
  return null;
}

export function sixDigitCodeValidator(control: AbstractControl): { [key: string]: any } {
  const val = control.value;

  console.log(' validate ' + val);

  var reg = new RegExp('^[0-9]+$');
  if (val.length > 0 && !reg.test(val)) {
    return { invalid: 'Must only contain digits' };
  }

  if (val.length !== 6) {
    return { invalid: `Must be 6 digits` };
  }

  console.log('code valid OK');
  // if true, return no error (no error), else return error passed in the second parameter
  return null;
}

export function fourDigitCodeValidator(control: AbstractControl): { [key: string]: any } {
  const val = control.value;

  if (!val || val === '') {
    // if control is empty return no error
    return { invalid: `Must be 4 digits` };
  }

  console.log(' validate ' + val);

  var reg = new RegExp('^[0-9]+$');
  if (val.length > 0 && !reg.test(val)) {
    return { invalid: 'Must only contain digits' };
  }

  if (val.length !== 4) {
    return { invalid: `Must be 4 digits` };
  }

  console.log('code valid OK');
  // if true, return no error (no error), else return error passed in the second parameter
  return null;
}

export function emailCodeValidator(control: AbstractControl): { [key: string]: any } {
  const val = control.value;

  console.log(' validate ' + val);

  var reg = new RegExp('^[0-9a-z]+$');
  if (val.length > 0 && !reg.test(val)) {
    return { invalid: 'Must only contain digits and lower case letters' };
  }

  if (val.length !== 8) {
    return { invalid: `Must be 8 characters` };
  }

  console.log('code valid OK');
  // if true, return no error (no error), else return error passed in the second parameter
  return null;
}
