export const sections = {
  sections: [
    {
      id: 1,
      name: 'Selskapsformer',
    },
    {
      id: 2,
      name: 'Forvaltnings- og tilsynsansvar',
    },
    {
      id: 3,
      name: 'Sammensetning av styret',
    },
    {
      id: 4,
      name: 'Styrets leder',
    },
    {
      id: 5,
      name: 'Daglig leder og styret',
    },
    {
      id: 6,
      name: 'Innkalling til styremøte',
    },
    {
      id: 7,
      name: 'Behandling av styresaker',
    },
    {
      id: 8,
      name: 'Styreprotokoll',
    },
    {
      id: 9,
      name: 'Erstatningsansvar',
    },
    {
      id: 10,
      name: 'Årsregnskap',
    },
    {
      id: 11,
      name: 'Generalforsamling',
    },
    {
      id: 12,
      name: 'Inhabilitet',
    },
    {
      id: 13,
      name: 'Ansatterepresentanter',
    },
    {
      id: 14,
      name: 'Styreinstruks',
    },
    {
      id: 15,
      name: 'Taushetsplikt',
    },
    {
      id: 16,
      name: 'Styrehonorar',
    },
    {
      id: 17,
      name: 'GDPR for styret',
    },
    {
      id: 18,
      name: 'Styrets årsplan',
    },
    {
      id: 19,
      name: 'Styreevaluering',
    },
    {
      id: 20,
      name: 'Før du takker ja til nytt styreverv',
    },
  ],
};
