import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandseparatordisplay',
  standalone: true,
})
export class ThousandseparatordisplayPipe implements PipeTransform {
  transform(val: number): string {
    // Format the output to display any way you want here.
    // For instance:
    if (val !== undefined && val !== null) {
      let formatter = new Intl.NumberFormat('nb-NO', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 6,
      });
      let res = formatter.format(val);
      if (/.+,[0-9]{1}$/.test(res)) {
        res += '0';
      }

      return res;
    } else {
      return '';
    }
  }
}
