import { OrgAndClientLogoType } from './organization';
import { CredentialEnum, IdentityRecord } from './session';

export type IUser = {
  id?: string;
  _id?: string;

  firstName: string;
  lastName: string;
  email: string;
  // Admin controlled variable
  phone?: string;

  lang?: string;
  logo?: OrgAndClientLogoType;
  mfaMethods: CredentialEnum[]; // Therde are the user login MFA methods
  zombie?: boolean; // after transfered invite set to true,
  code?: string; // SMS code store
  codestamp?: number; //  time stamp for SMS code.
  superuser: boolean;
  superclient?: boolean;
  supportuser?: boolean;
  sendInvite?: boolean;
  acceptTerms: boolean;
  acceptMarketingEmail: boolean;
  openaiPermission?: any;
  // PJL_LATER_TODO   mode into identities
  sub: string;

  internref?: string; // Bisnode reference
  dirty?: boolean;
  mergedinto?: string; // used to transfer
  deleted?: boolean;
  temp?: any;
  tempPassword?: string;
  invited: any;
  createdAt: string;
  updatedAt: string;
  logonAttempts?: number; // back end securitity
  logonStamp?: number; // back end securitity
  addToGoogleCalendar?: boolean;
  totpSecret?: string; //  should not escape from backend
  newPhone?: string;
  identities?: IdentityRecord[];
  timeZone?: string;
};
