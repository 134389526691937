import { Injectable } from '@angular/core';
import { printClassName } from '@orgbrain/lib-data';

@Injectable({
  providedIn: 'root',
})
export class UuidService {
  constructor() {
    printClassName(' ---> ', this);
  }

  // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  generateUUID() {
    // Public Domain/MIT
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); // use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // tslint:disable-next-line:no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  dateId() {
    return new Date().toISOString();
  }
}
