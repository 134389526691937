// Use this in errors
// backendErrorCodes.GenericError.code

export const backendLogCodes = {
  CreateFile: {
    en: 'Create File',
    nb: '',
    code: '',
  },

  CreateFolder: {
    en: 'Create Folders',
    nb: '',
    code: '',
  },

  CreateMeetingFolders: {
    en: 'Create Meeting Folders',
    nb: '',
    code: '',
  },

  CreateDataRoomFolders: {
    en: 'Create Data Room Folders',
    nb: '',
    code: '',
  },

  ViewFile: {
    en: 'View file',
    nb: '',
    code: '',
  },

  HardDeleteFile: {
    en: 'Hard Delete file',
    nb: '',
    code: '',
  },

  RenameFile: {
    en: 'Rename file',
    nb: '',
    code: '',
  },
  CopyFile: {
    en: 'Copy file',
    nb: '',
    code: '',
  },

  SoftDeleteFile: {
    en: 'Soft Delete file',
    nb: '',
    code: '',
  },

  SoftRestoreFile: {
    en: 'Soft Restore file',
    nb: '',
    code: '',
  },

  ZipBulkDownload: {
    en: 'Zip Bulk Download',
    nb: '',
    code: '',
  },

  Emails: {
    en: 'Emails',
    nb: '',
    code: '',
  },
};

// Well this is naff but it helps checking.
const keys = Object.keys(backendLogCodes);

for (const key of keys) {
  backendLogCodes[key].code = key;
}
