import { Directive, ElementRef, OnInit } from '@angular/core';
import { StylePreferencesService } from '../services/style-preferences.service';

@Directive({
  selector: '[orgbrainDebugInfo]',
})
export class DebugDirective implements OnInit {
  constructor(private element: ElementRef, private stylePreferences: StylePreferencesService) {
    // console.log('orgbrainDebugInfo');
  }

  ngOnInit() {
    const show = localStorage.getItem('debugDOM');
    const style = this.element.nativeElement.style;
    if (show) {
      style.display = 'block';
      style.color = 'red';
    } else {
      style.display = 'none';
    }
  }
}
