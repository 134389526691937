export class TitleBarButton {
  svgIcon?: string;
  matIcon?: string;
  toolTip?: string;
  id?: string;
  disabled?: boolean;
  text?: string;
  color?: string;
  isButton?: boolean;
  click() {}
}
