import { Injectable } from '@angular/core';
import { printClassName } from '@orgbrain/lib-data';

declare var ClientJS;

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  client: any;

  constructor() {
    printClassName(' ---> ', this);
    this.client = new ClientJS();
  }

  getFingerprint() {
    return this.client.getFingerprint();
  }

  getDeviceName() {
    return (
      this.client.getOS() +
      '/' +
      this.client.getOSVersion() +
      ' ' +
      this.client.getBrowser() +
      '/' +
      this.client.getBrowserVersion() +
      ' ' +
      this.client.getCurrentResolution()
    );
  }
}
