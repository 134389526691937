export const tripletexCountries = [
  { id: 1, name: 'Andorra', isoAlpha2Code: 'AD' },
  { id: 2, name: 'De forente arabiske emirater', isoAlpha2Code: 'AE' },
  { id: 3, name: 'Afghanistan', isoAlpha2Code: 'AF' },
  { id: 4, name: 'Antigua og Barbuda', isoAlpha2Code: 'AG' },
  { id: 5, name: 'Anguilla', isoAlpha2Code: 'AI' },
  { id: 6, name: 'Albania', isoAlpha2Code: 'AL' },
  { id: 7, name: 'Armenia', isoAlpha2Code: 'AM' },
  { id: 8, name: 'De nederlandske antiller', isoAlpha2Code: 'AN' },
  { id: 9, name: 'Angola', isoAlpha2Code: 'AO' },
  { id: 10, name: 'Antarktis', isoAlpha2Code: 'AQ' },
  { id: 11, name: 'Argentina', isoAlpha2Code: 'AR' },
  { id: 12, name: 'Amerikansk Samoa', isoAlpha2Code: 'AS' },
  { id: 13, name: 'Østerrike', isoAlpha2Code: 'AT' },
  { id: 14, name: 'Australia', isoAlpha2Code: 'AU' },
  { id: 15, name: 'Aruba', isoAlpha2Code: 'AW' },
  { id: 16, name: 'Åland', isoAlpha2Code: 'AX' },
  { id: 17, name: 'Aserbajdsjan', isoAlpha2Code: 'AZ' },
  { id: 18, name: 'Bosnia-Hercegovina', isoAlpha2Code: 'BA' },
  { id: 19, name: 'Barbados', isoAlpha2Code: 'BB' },
  { id: 20, name: 'Bangladesh', isoAlpha2Code: 'BD' },
  { id: 21, name: 'Belgia', isoAlpha2Code: 'BE' },
  { id: 22, name: 'Burkina Faso', isoAlpha2Code: 'BF' },
  { id: 23, name: 'Bulgaria', isoAlpha2Code: 'BG' },
  { id: 24, name: 'Bahrain', isoAlpha2Code: 'BH' },
  { id: 25, name: 'Burundi', isoAlpha2Code: 'BI' },
  { id: 26, name: 'Benin', isoAlpha2Code: 'BJ' },
  { id: 27, name: 'Bermuda', isoAlpha2Code: 'BM' },
  { id: 28, name: 'Brunei', isoAlpha2Code: 'BN' },
  { id: 29, name: 'Bolivia', isoAlpha2Code: 'BO' },
  { id: 30, name: 'Brasil', isoAlpha2Code: 'BR' },
  { id: 31, name: 'Bahamas', isoAlpha2Code: 'BS' },
  { id: 32, name: 'Bhutan', isoAlpha2Code: 'BT' },
  { id: 33, name: 'Bouvetoya', isoAlpha2Code: 'BV' },
  { id: 34, name: 'Botswana', isoAlpha2Code: 'BW' },
  { id: 35, name: 'Belarus', isoAlpha2Code: 'BY' },
  { id: 36, name: 'Belize', isoAlpha2Code: 'BZ' },
  { id: 37, name: 'Canada', isoAlpha2Code: 'CA' },
  { id: 38, name: 'Kokosøyene', isoAlpha2Code: 'CC' },
  { id: 39, name: 'Kongo', isoAlpha2Code: 'CD' },
  { id: 40, name: 'Den sentralafrikanske republikk', isoAlpha2Code: 'CF' },
  { id: 41, name: 'Kongo-Brazzaville', isoAlpha2Code: 'CG' },
  { id: 42, name: 'Sveits', isoAlpha2Code: 'CH' },
  { id: 43, name: 'Elfenbenskysten', isoAlpha2Code: 'CI' },
  { id: 44, name: 'Cookøyene', isoAlpha2Code: 'CK' },
  { id: 45, name: 'Chile', isoAlpha2Code: 'CL' },
  { id: 46, name: 'Kamerun', isoAlpha2Code: 'CM' },
  { id: 47, name: 'Kina', isoAlpha2Code: 'CN' },
  { id: 48, name: 'Colombia', isoAlpha2Code: 'CO' },
  { id: 49, name: 'Costa Rica', isoAlpha2Code: 'CR' },
  { id: 50, name: 'Cuba', isoAlpha2Code: 'CU' },
  { id: 51, name: 'Kapp Verde', isoAlpha2Code: 'CV' },
  { id: 52, name: 'Christmasøya', isoAlpha2Code: 'CX' },
  { id: 53, name: 'Kypros', isoAlpha2Code: 'CY' },
  { id: 54, name: 'Tsjekkia', isoAlpha2Code: 'CZ' },
  { id: 55, name: 'Tyskland', isoAlpha2Code: 'DE' },
  { id: 56, name: 'Djibouti', isoAlpha2Code: 'DJ' },
  { id: 57, name: 'Danmark', isoAlpha2Code: 'DK' },
  { id: 58, name: 'Dominica', isoAlpha2Code: 'DM' },
  { id: 59, name: 'Den dominikanske republikk', isoAlpha2Code: 'DO' },
  { id: 60, name: 'Algerie', isoAlpha2Code: 'DZ' },
  { id: 61, name: 'Ecuador', isoAlpha2Code: 'EC' },
  { id: 62, name: 'Estland', isoAlpha2Code: 'EE' },
  { id: 63, name: 'Egypt', isoAlpha2Code: 'EG' },
  { id: 64, name: 'Vest-Sahara', isoAlpha2Code: 'EH' },
  { id: 65, name: 'Eritrea', isoAlpha2Code: 'ER' },
  { id: 66, name: 'Spania', isoAlpha2Code: 'ES' },
  { id: 67, name: 'Etiopia', isoAlpha2Code: 'ET' },
  { id: 68, name: 'Finland', isoAlpha2Code: 'FI' },
  { id: 69, name: 'Fiji', isoAlpha2Code: 'FJ' },
  { id: 70, name: 'Falklandsøyene', isoAlpha2Code: 'FK' },
  { id: 71, name: 'Mikronesiafoderasjonen', isoAlpha2Code: 'FM' },
  { id: 72, name: 'Færøyene', isoAlpha2Code: 'FO' },
  { id: 73, name: 'Frankrike', isoAlpha2Code: 'FR' },
  { id: 74, name: 'Gabon', isoAlpha2Code: 'GA' },
  { id: 75, name: 'Storbritannia', isoAlpha2Code: 'GB' },
  { id: 76, name: 'Grenada', isoAlpha2Code: 'GD' },
  { id: 77, name: 'Georgia', isoAlpha2Code: 'GE' },
  { id: 78, name: 'Fransk Guyana', isoAlpha2Code: 'GF' },
  { id: 79, name: 'Ghana', isoAlpha2Code: 'GH' },
  { id: 80, name: 'Gibraltar', isoAlpha2Code: 'GI' },
  { id: 81, name: 'Grønland', isoAlpha2Code: 'GL' },
  { id: 82, name: 'Gambia', isoAlpha2Code: 'GM' },
  { id: 83, name: 'Guinea', isoAlpha2Code: 'GN' },
  { id: 84, name: 'Guadeloupe', isoAlpha2Code: 'GP' },
  { id: 85, name: 'Ekvatorial-Guinea', isoAlpha2Code: 'GQ' },
  { id: 86, name: 'Hellas', isoAlpha2Code: 'GR' },
  { id: 87, name: 'Sør-Georgia og de sørlige Sandwichøyene', isoAlpha2Code: 'GS' },
  { id: 88, name: 'Guatemala', isoAlpha2Code: 'GT' },
  { id: 89, name: 'Guam', isoAlpha2Code: 'GU' },
  { id: 90, name: 'Guinea-Bissau', isoAlpha2Code: 'GW' },
  { id: 91, name: 'Guyana', isoAlpha2Code: 'GY' },
  { id: 92, name: 'Hongkong', isoAlpha2Code: 'HK' },
  { id: 93, name: 'Heard- og McDonald-øyene', isoAlpha2Code: 'HM' },
  { id: 94, name: 'Honduras', isoAlpha2Code: 'HN' },
  { id: 95, name: 'Kroatia', isoAlpha2Code: 'HR' },
  { id: 96, name: 'Haiti', isoAlpha2Code: 'HT' },
  { id: 97, name: 'Ungarn', isoAlpha2Code: 'HU' },
  { id: 98, name: 'Indonesia', isoAlpha2Code: 'ID' },
  { id: 99, name: 'Irland', isoAlpha2Code: 'IE' },
  { id: 100, name: 'Israel', isoAlpha2Code: 'IL' },
  { id: 101, name: 'India', isoAlpha2Code: 'IN' },
  { id: 102, name: 'Britisk territorium i Indiahavet', isoAlpha2Code: 'IO' },
  { id: 103, name: 'Irak', isoAlpha2Code: 'IQ' },
  { id: 104, name: 'Iran', isoAlpha2Code: 'IR' },
  { id: 105, name: 'Island', isoAlpha2Code: 'IS' },
  { id: 106, name: 'Italia', isoAlpha2Code: 'IT' },
  { id: 107, name: 'Jamaica', isoAlpha2Code: 'JM' },
  { id: 108, name: 'Jordan', isoAlpha2Code: 'JO' },
  { id: 109, name: 'Japan', isoAlpha2Code: 'JP' },
  { id: 110, name: 'Kenya', isoAlpha2Code: 'KE' },
  { id: 111, name: 'Kirgistan', isoAlpha2Code: 'KG' },
  { id: 112, name: 'Kambodsja', isoAlpha2Code: 'KH' },
  { id: 113, name: 'Kiribati', isoAlpha2Code: 'KI' },
  { id: 114, name: 'Komorene', isoAlpha2Code: 'KM' },
  { id: 115, name: 'Saint Kitts og Nevis', isoAlpha2Code: 'KN' },
  { id: 116, name: 'Nord-Korea', isoAlpha2Code: 'KP' },
  { id: 117, name: 'Sør-Korea', isoAlpha2Code: 'KR' },
  { id: 118, name: 'Kuwait', isoAlpha2Code: 'KW' },
  { id: 119, name: 'Caymanoyene', isoAlpha2Code: 'KY' },
  { id: 120, name: 'Kasakhstan', isoAlpha2Code: 'KZ' },
  { id: 121, name: 'Laos', isoAlpha2Code: 'LA' },
  { id: 122, name: 'Libanon', isoAlpha2Code: 'LB' },
  { id: 123, name: 'Saint Lucia', isoAlpha2Code: 'LC' },
  { id: 124, name: 'Liechtenstein', isoAlpha2Code: 'LI' },
  { id: 125, name: 'Sri Lanka', isoAlpha2Code: 'LK' },
  { id: 126, name: 'Liberia', isoAlpha2Code: 'LR' },
  { id: 127, name: 'Lesotho', isoAlpha2Code: 'LS' },
  { id: 128, name: 'Litauen', isoAlpha2Code: 'LT' },
  { id: 129, name: 'Luxembourg', isoAlpha2Code: 'LU' },
  { id: 130, name: 'Latvia', isoAlpha2Code: 'LV' },
  { id: 131, name: 'Libya', isoAlpha2Code: 'LY' },
  { id: 132, name: 'Marokko', isoAlpha2Code: 'MA' },
  { id: 133, name: 'Monaco', isoAlpha2Code: 'MC' },
  { id: 134, name: 'Moldova', isoAlpha2Code: 'MD' },
  { id: 135, name: 'Montenegro', isoAlpha2Code: 'ME' },
  { id: 136, name: 'Madagaskar', isoAlpha2Code: 'MG' },
  { id: 137, name: 'Marshalløyene', isoAlpha2Code: 'MH' },
  { id: 138, name: 'Makedonia', isoAlpha2Code: 'MK' },
  { id: 139, name: 'Mali', isoAlpha2Code: 'ML' },
  { id: 140, name: 'Myanmar', isoAlpha2Code: 'MM' },
  { id: 141, name: 'Mongolia', isoAlpha2Code: 'MN' },
  { id: 142, name: 'Macao', isoAlpha2Code: 'MO' },
  { id: 143, name: 'Nord-Marianene', isoAlpha2Code: 'MP' },
  { id: 144, name: 'Martinique', isoAlpha2Code: 'MQ' },
  { id: 145, name: 'Mauritania', isoAlpha2Code: 'MR' },
  { id: 146, name: 'Montserrat', isoAlpha2Code: 'MS' },
  { id: 147, name: 'Malta', isoAlpha2Code: 'MT' },
  { id: 148, name: 'Mauritius', isoAlpha2Code: 'MU' },
  { id: 149, name: 'Maldivene', isoAlpha2Code: 'MV' },
  { id: 150, name: 'Malawi', isoAlpha2Code: 'MW' },
  { id: 151, name: 'Mexico', isoAlpha2Code: 'MX' },
  { id: 152, name: 'Malaysia', isoAlpha2Code: 'MY' },
  { id: 153, name: 'Mosambik', isoAlpha2Code: 'MZ' },
  { id: 154, name: 'Namibia', isoAlpha2Code: 'NA' },
  { id: 155, name: 'Ny-Caledonia', isoAlpha2Code: 'NC' },
  { id: 156, name: 'Niger', isoAlpha2Code: 'NE' },
  { id: 157, name: 'Norfolk Island', isoAlpha2Code: 'NF' },
  { id: 158, name: 'Nigeria', isoAlpha2Code: 'NG' },
  { id: 159, name: 'Nicaragua', isoAlpha2Code: 'NI' },
  { id: 160, name: 'Nederland', isoAlpha2Code: 'NL' },
  { id: 161, name: 'Norge', isoAlpha2Code: 'NO' },
  { id: 162, name: 'Nepal', isoAlpha2Code: 'NP' },
  { id: 163, name: 'Nauru', isoAlpha2Code: 'NR' },
  { id: 164, name: 'Niue', isoAlpha2Code: 'NU' },
  { id: 165, name: 'New Zealand', isoAlpha2Code: 'NZ' },
  { id: 166, name: 'Oman', isoAlpha2Code: 'OM' },
  { id: 167, name: 'Panama', isoAlpha2Code: 'PA' },
  { id: 168, name: 'Peru', isoAlpha2Code: 'PE' },
  { id: 169, name: 'Fransk Polynesia', isoAlpha2Code: 'PF' },
  { id: 170, name: 'Papua Ny-Guinea', isoAlpha2Code: 'PG' },
  { id: 171, name: 'Filippinene', isoAlpha2Code: 'PH' },
  { id: 172, name: 'Pakistan', isoAlpha2Code: 'PK' },
  { id: 173, name: 'Polen', isoAlpha2Code: 'PL' },
  { id: 174, name: 'Saint-Pierre-et-Miquelon', isoAlpha2Code: 'PM' },
  { id: 175, name: 'Pitcairn', isoAlpha2Code: 'PN' },
  { id: 176, name: 'Puerto Rico', isoAlpha2Code: 'PR' },
  { id: 177, name: 'De okkuperte palestinske områdene', isoAlpha2Code: 'PS' },
  { id: 178, name: 'Portugal', isoAlpha2Code: 'PT' },
  { id: 179, name: 'Palau', isoAlpha2Code: 'PW' },
  { id: 180, name: 'Paraguay', isoAlpha2Code: 'PY' },
  { id: 181, name: 'Qatar', isoAlpha2Code: 'QA' },
  { id: 182, name: 'Réunion', isoAlpha2Code: 'RE' },
  { id: 183, name: 'Romania', isoAlpha2Code: 'RO' },
  { id: 184, name: 'Serbia', isoAlpha2Code: 'RS' },
  { id: 185, name: 'Russland', isoAlpha2Code: 'RU' },
  { id: 186, name: 'Rwanda', isoAlpha2Code: 'RW' },
  { id: 187, name: 'Saudi-Arabia', isoAlpha2Code: 'SA' },
  { id: 188, name: 'Salomonøyene', isoAlpha2Code: 'SB' },
  { id: 189, name: 'Seychellene', isoAlpha2Code: 'SC' },
  { id: 190, name: 'Sudan', isoAlpha2Code: 'SD' },
  { id: 191, name: 'Sverige', isoAlpha2Code: 'SE' },
  { id: 192, name: 'Singapore', isoAlpha2Code: 'SG' },
  { id: 193, name: 'St. Helena', isoAlpha2Code: 'SH' },
  { id: 194, name: 'Slovenia', isoAlpha2Code: 'SI' },
  { id: 195, name: 'Svalbard og Jan Mayen', isoAlpha2Code: 'SJ' },
  { id: 196, name: 'Slovakia', isoAlpha2Code: 'SK' },
  { id: 197, name: 'Sierra Leone', isoAlpha2Code: 'SL' },
  { id: 198, name: 'San Marino', isoAlpha2Code: 'SM' },
  { id: 199, name: 'Senegal', isoAlpha2Code: 'SN' },
  { id: 200, name: 'Somalia', isoAlpha2Code: 'SO' },
  { id: 201, name: 'Surinam', isoAlpha2Code: 'SR' },
  { id: 202, name: 'São Tomé and Príncipe', isoAlpha2Code: 'ST' },
  { id: 203, name: 'El Salvador', isoAlpha2Code: 'SV' },
  { id: 204, name: 'Syria', isoAlpha2Code: 'SY' },
  { id: 205, name: 'Swaziland', isoAlpha2Code: 'SZ' },
  { id: 206, name: 'Turks- og Caicosoyene', isoAlpha2Code: 'TC' },
  { id: 207, name: 'Tsjad', isoAlpha2Code: 'TD' },
  { id: 208, name: 'Sørlige franske territorier', isoAlpha2Code: 'TF' },
  { id: 209, name: 'Togo', isoAlpha2Code: 'TG' },
  { id: 210, name: 'Thailand', isoAlpha2Code: 'TH' },
  { id: 211, name: 'Tadsjikistan', isoAlpha2Code: 'TJ' },
  { id: 212, name: 'Tokelau', isoAlpha2Code: 'TK' },
  { id: 213, name: 'Østtimor', isoAlpha2Code: 'TP' },
  { id: 214, name: 'Turkmenistan', isoAlpha2Code: 'TM' },
  { id: 215, name: 'Tunisia', isoAlpha2Code: 'TN' },
  { id: 216, name: 'Tonga', isoAlpha2Code: 'TO' },
  { id: 217, name: 'Tyrkia', isoAlpha2Code: 'TR' },
  { id: 218, name: 'Trinidad og Tobago', isoAlpha2Code: 'TT' },
  { id: 219, name: 'Tuvalu', isoAlpha2Code: 'TV' },
  { id: 220, name: 'Taiwan', isoAlpha2Code: 'TW' },
  { id: 221, name: 'Tanzania', isoAlpha2Code: 'TZ' },
  { id: 222, name: 'Ukraina', isoAlpha2Code: 'UA' },
  { id: 223, name: 'Uganda', isoAlpha2Code: 'UG' },
  { id: 224, name: 'USA, mindre, utenforliggende øyer', isoAlpha2Code: 'UM' },
  { id: 225, name: 'USA', isoAlpha2Code: 'US' },
  { id: 226, name: 'Uruguay', isoAlpha2Code: 'UY' },
  { id: 227, name: 'Usbekistan', isoAlpha2Code: 'UZ' },
  { id: 228, name: 'Vatikanstaten', isoAlpha2Code: 'VA' },
  { id: 229, name: 'Saint Vincent og Grenadinene', isoAlpha2Code: 'VC' },
  { id: 230, name: 'Venezuela', isoAlpha2Code: 'VE' },
  { id: 231, name: 'Jomfruøyene (Britisk)', isoAlpha2Code: 'VG' },
  { id: 232, name: 'Jomfruøyene (USA)', isoAlpha2Code: 'VI' },
  { id: 233, name: 'Vietnam', isoAlpha2Code: 'VN' },
  { id: 234, name: 'Vanuatu', isoAlpha2Code: 'VU' },
  { id: 235, name: 'Wallis- og Futunaoyene', isoAlpha2Code: 'WF' },
  { id: 236, name: 'Samoa', isoAlpha2Code: 'WS' },
  { id: 237, name: 'Jemen', isoAlpha2Code: 'YE' },
  { id: 238, name: 'Mayotte', isoAlpha2Code: 'YT' },
  { id: 239, name: 'Sør-Afrika', isoAlpha2Code: 'ZA' },
  { id: 240, name: 'Zambia', isoAlpha2Code: 'ZM' },
  { id: 241, name: 'Zimbabwe', isoAlpha2Code: 'ZW' },
  { id: 242, name: 'Guernsey', isoAlpha2Code: 'GG' },
  { id: 243, name: 'Jersey', isoAlpha2Code: 'JE' },
  { id: 244, name: 'Serbia-Montenegro', isoAlpha2Code: 'YU' },
  { id: 245, name: 'Sør-Sudan', isoAlpha2Code: 'SS' },
  { id: 253, name: 'Kosovo', isoAlpha2Code: 'XK' },
];

export const countriesNames = tripletexCountries.reduce((total, current) => {
  total[current.name.toUpperCase()] = current.isoAlpha2Code;
  return total;
}, {});
