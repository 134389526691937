/* eslint-disable @typescript-eslint/no-explicit-any */
export enum LogLevel {
  INFO = 0, // Information - system working normally.
  WARN = 1, // Some thing went wrong but probably pilot error
  ERROR = 2, // Unexpected error  we show investigate.
  FATAL = 3,
  DOOM = 4,
}

export enum LogSourceEnum {
  FILES = 'FILES',
  LEGACY = 'LEGACY',
  VIDEO_CONFERENCING = 'VIDEO_CONFERENCING',
  MAIL = 'MAIL',
  INCOMING_ORDERS = 'INCOMING_ORDERS',
  REGISTER = 'REGISTER',
  SHARE_HOLDERS = 'SHARE_HOLDERS',
  INVESTOR = 'INVESTOR',
  BOARDMATCH = 'BOARDMATCH',
  AUTH = 'AUTH',
  SIGNICAT = 'SIGNICAT',
  SNS_EMAIL = 'SNS_EMAIL',
  SYSTEM = 'SYSTEM',
  ADMIN = 'ADMIN',
  INVITES = 'INVITES',
  SMS = 'SMS',
  CALENDAR = 'CALENDAR',
  DATABASE = 'DATABASE',
  USER_OP = 'USER_OP',
  DIRENT = 'DIRENT',
  INTEGRATIONS = 'INTEGRATIONS',
  COGNITO = 'COGNITO',
  ONBOARD = 'ONBOARD',
  TOTP = 'TOTP',
  E2E = 'E2E',
  SYSTOR = 'SYSTOR',
  UNKOWN = 'UNKOWN',
  NOTIFICATIONS = 'NOTIFICATIONS',
  MONITOR = 'MONITOR',
  WATCHDOG = 'WATCHDOG',
  PDF = 'PDF',
  SIGNING = 'SIGNING',
  ANNOTATIONS = 'ANNOTATIONS',
  REMINDERS = 'REMINDERS',
  WEB_HOOK = 'WEB_HOOK',
  PERMISSIONS = 'PERMISSIONS',
  CRIIPTO = 'CRIIPTO',
  DEFAULT = 'DEFAULT',
  GRAPHQL = 'GRAPHQL',
  BILLING = 'BILLING',
  SPORTS = 'SPORTS',
  EMAIL_SECRET_CLICK = 'EMAIL_SECRET_CLICK',
  BATCH_EMAIL = 'BATCH_EMAIL',
}

export class LogInfo {
  code: string; //   Unique code for this LogMessage.  Used to search  and language
  data?: any; //   arbitrary data payload.
  // OPTIONAL KEYS FOR SEARCHING  (keep adding as needed)
  subjectId?: string; // user effected by the action
  userId?: string;
  subjectIds?: string[];
  organizationId?: string; //  organization context
  meetingId?: string; // meeting context
  direntId?: string; //  for file operations.
  signOrderId?: string; // sign orders
}
