/*
 *  Copyright 2010-2016 FinTech Neo AS ( fintechneo.com )- All rights reserved
 */

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './workingdialog.component.html',
})
export class WorkingDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; body: string }
  ) {}
}
