export function savefile(data, filename, type) {
  const file = new Blob([data], { type: type });
  if ((window.navigator as any).msSaveOrOpenBlob)
    // IE10+
    (window.navigator as any).msSaveOrOpenBlob(file, filename);
  else {
    // Others
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = (filename || '').replace(/[/\\?\+%*:|"<>\(\)]/g, '_').replace(/_+/g, '_');
    a.textContent = 'Download ready';
    a.style.display = 'none';
    a.target = '_blank';
    a.dataset.downloadurl = [type, a.download, a.href].join(':');
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100);
  }
}
