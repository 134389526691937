import { MongoId } from './chatChannel';
import { DistributionMediums, EmailTypeEnum, INotification } from './notifications';
import { textMappings } from './services';
export class NotificationItemWithHtml {
  id: string;
  status: NotificationStatus;
  title: string;
  body: string;
  due: string;
  originalDue?: Date;
  code: NotificationCodeEnum;
  createdAt: Date;
  html; // FInal html
  list?: INotification[]; // OPTIONAL IF IT IS A COMPRESSED CHANNEL
}

export function extractTemplatePlaceholders(templates: { [key: string]: string }): string[] {
  const placeholderPattern = /\{(.*?)\}/g;
  const placeholders = new Set<string>();

  for (const key in templates) {
    const template = templates[key];
    let match;
    while ((match = placeholderPattern.exec(template)) !== null) {
      placeholders.add(match[1]);
    }
  }

  return Array.from(placeholders);
}

export enum SigningEvent {
  TASK_CREATED = 'TASK_CREATED',
  TASK_COMPLETED = 'TASK_COMPLETED',
  TASK_REJECTED = 'TASK_REJECTED',
  ORDER_CREATED = 'ORDER_CREATED',
  PACKAGING_COMPLETED = 'PACKAGING_COMPLETED',
  TASK_EXPIRED = 'TASK_EXPIRED',
}
export enum NotificationStatus {
  UNREAD = 'UNREAD',
  READ = 'READ',
}
export enum NotificationType {
  ACTION_AUTO = 'ACTION_AUTO', // requires an action that will remove notification     No bin.   Remove after time out.
  ACTION_MANUAL = 'ACTION_MANUAL', // requires an action but user must remove notification.
  INFORMATION = 'INFORMATION', // information  user
}

export enum NotificationClusterTypeEnum {
  // GENERAL = 'GENERAL',
  MEETING = 'MEETING',
  BOARDMATCH = 'BOARDMATCH',
  SIGNING = 'SIGNING',
  SHAREISSUE = 'SHAREISSUE',
  PDFMENTION = 'PDFMENTION',
  MESSAGES = 'MESSAGES',
  TASKS = 'TASKS',
  SURVEY = 'SURVEY',
  SUPPORT = 'SUPPORT',
  BOARDSCHOOL = 'BOARDSCHOOL',
  OTHER = 'OTHER',
  LEGACY = 'LEGACY',
  DUMMY = 'DUMMY', // If the code does not exist.
  UNREAD = 'CONVERSATIONS',
  CONVERSATIONS = 'CONVERSATIONS',
}

// export type HtmlNotificationItem = {
//   html: string;
//   item: INotification;
// };

export type NotificationClusterInfo = {
  name: { en: string; nb: string };
  icon: string;
};

export const notificationClusterInfo: {
  [key in NotificationClusterTypeEnum]: NotificationClusterInfo;
} = {
  [NotificationClusterTypeEnum.LEGACY]: {
    name: {
      en: 'Legacy',
      nb: 'Legacy',
    },
    icon: 'folder_open',
  },
  [NotificationClusterTypeEnum.OTHER]: {
    name: {
      en: 'Other',
      nb: 'Annet',
    },
    icon: 'notifications',
  },

  [NotificationClusterTypeEnum.MEETING]: {
    name: {
      en: 'Meetings',
      nb: 'Møter',
    },
    icon: 'groups',
  },

  [NotificationClusterTypeEnum.SIGNING]: {
    name: {
      en: 'Signing',
      nb: 'Signeringer',
    },
    icon: 'edit_document',
  },
  [NotificationClusterTypeEnum.PDFMENTION]: {
    name: {
      en: 'PDF-mention',
      nb: 'PDF-kommentarer',
    },
    icon: 'picture_as_pdf',
  },
  [NotificationClusterTypeEnum.BOARDMATCH]: {
    name: {
      en: 'Board match',
      nb: 'Styrematch',
    },
    icon: 'join_right',
  },
  [NotificationClusterTypeEnum.CONVERSATIONS]: {
    name: {
      en: 'Conversations',
      nb: 'Samtaler',
    },
    icon: 'chat',
  },
  [NotificationClusterTypeEnum.SHAREISSUE]: {
    name: {
      en: 'Share issue',
      nb: 'Kapitalforhøyelser',
    },
    icon: 'payments',
  },
  [NotificationClusterTypeEnum.MESSAGES]: {
    name: {
      en: 'Messages',
      nb: 'Meldinger',
    },
    icon: 'breaking_news',
  },
  [NotificationClusterTypeEnum.TASKS]: {
    name: {
      en: 'Tasks',
      nb: 'Oppgaver',
    },
    icon: 'task',
  },
  [NotificationClusterTypeEnum.SURVEY]: {
    name: {
      en: 'Survey',
      nb: 'Spørreskjema',
    },
    icon: 'analytics',
  },
  [NotificationClusterTypeEnum.SUPPORT]: {
    name: {
      en: 'Support',
      nb: 'Support',
    },
    icon: 'headset_mic',
  },

  [NotificationClusterTypeEnum.BOARDSCHOOL]: {
    name: {
      en: 'Board school',
      nb: 'Styreskole',
    },
    icon: 'school',
  },

  [NotificationClusterTypeEnum.DUMMY]: {
    // Title not used we use the code.
    name: {
      en: 'DUMMY - NOT USED',
      nb: 'DUMMY - NOT USED',
    },
    icon: 'bug_report',
  },
};

export class NotificationTemplate {
  title: { en: string; nb: string };
  //Remove this maybe?
  code: NotificationCodeEnum;
  data: any;
  placeHolders?: string[];
  templates?: { en: string; nb: string };
  templatesNoHtml?: { en: string; nb: string };
  type: NotificationType;
  batchClusterType: NotificationClusterTypeEnum;
  //Remove question marks
  defaultNotificationType?: DistributionMediums[];
  //can this templates notifications be customized by the user? only works with easynotif function
  isCustomizableNotifications?: boolean;

  // Email options
  emailType?: EmailTypeEnum;
  //Email title
  emailSubject?: { en: string; nb: string };
  //Bold first line
  firstLineInBold?: { en: string; nb: string };
  //smaller text in the middle
  bodyTexts?: { en: string; nb: string }[];
  //Highlighted text just above button
  callToAction?: { en: string; nb: string };
  //Buttom
  actionButtonText?: { en: string; nb: string };

  // Webpush options
  webpushMessage?: { en: string; nb: string };
  webpushTitle?: { en: string; nb: string };
}

/*  Williams table
EmissionInviteTask                      OK
SurveyInvite   OK
ChatMessageMention  OK
ChatMessageReply  OK
AnnotationPdfMention  OK
NewsNotification OK
MeetingInvitation  OK
MinutesHearing  OK
ResponsibleForActionPoint  OK
BoardmatchMessageCandidate  OK
BoardmatchMessageOrg OK
SigningTaskCreatedInviteeV2  OK
SigningOrderCompletedInviter  OK
SigningOrderExpiredInviter  OK

InviteForMeetingTime MISSING   libs/lib-backend/src/meetings/time-options/post.ts
BoardmatchInquiryCandidate MISSING   libs/lib-backend/src/boardmatch/boardmatch-functions.ts
BoardmatchInquiryOrg  MISSING   libs/lib-backend/src/boardmatch/boardmatch-functions.ts
BoardschoolUserReminder  MISSING   apps/backend-admin/src/boardschool/user.ts   MORE
BoardschoolExamCompleted  MISSING   apps/backend-admin/src/boardschool/user.ts
UserCreationReminder  MISSING    libs/lib-backend/src/organization/processOrganisationUsersNEW.ts      + MORE
OrgbrainSupportRequest     MISSING  libs/lib-backend/src/support/sendSupportNotifications.ts
SigningTaskCreatedInviter  MISSING    UNUSED
SigningTaskCompletedInviter MISSING   UNUSED
SigningTaskCompletedInvitee MISSING   UNUSED
SigningTaskRejectedInviter MISSING   UNUSED (commented out)
EmailFailedToSend MISSING   libs/lib-backend/src/aws-email/raw-email.ts  + MORE
MeetingAttendenceReminder  MISSING  UNUSED
MeetingOrganizerReminderToInvite  MISSING   UNUSED
SigningOrderCreated MISSING  UNUSED
SigningOrderCreateFailed MISSING   libs/lib-backend/src/notifications/handlers/signingOrderCreateFailed.ts


SigningTaskCreatedInviteeSignicat       DEPRECATE ?   libs/lib-backend/src/sign/SIGNICAT/reminderHandlerSignicat.ts   + MORE ?
*/

export enum NotificationCodeEnum {
  AlertToMessage = 'AlertToMessage',
  EmissionInviteTask = 'EmissionInviteTask',
  SigningTaskCreatedInviteeSignicat = 'SigningTaskCreatedInviteeSignicat',
  OrgbrainSupportRequest = 'OrgbrainSupportRequest',
  SurveyInvite = 'SurveyInvite',
  ChatMessageMention = 'ChatMessageMention',
  ChatMessageReply = 'ChatMessageReply',
  AnnotationPdfMention = 'AnnotationPdfMention',
  SigningTaskCreatedInviter = 'SigningTaskCreatedInviter',
  SigningTaskCompletedInviter = 'SigningTaskCompletedInviter',
  SigningTaskCompletedInvitee = 'SigningTaskCompletedInvitee',
  SigningTaskRejectedInviter = 'SigningTaskRejectedInviter',
  EmailFailedToSend = 'EmailFailedToSend',
  NewsNotification = 'NewsNotification',
  SigningOrderCreated = 'SigningOrderCreated',
  SigningOrderCreateFailed = 'SigningOrderCreateFailed',
  MeetingInvitation = 'MeetingInvitation',
  MeetingAttendenceReminder = 'MeetingAttendenceReminder',
  MinutesHearing = 'MinutesHearing',
  MeetingOrganizerReminderToInvite = 'MeetingOrganizerReminderToInvite',
  ResponsibleForActionPoint = 'ResponsibleForActionPoint',
  InviteForMeetingTime = 'InviteForMeetingTime',
  BoardmatchInquiryCandidate = 'BoardmatchInquiryCandidate',
  BoardmatchInquiryOrg = 'BoardmatchInquiryOrg',
  BoardmatchMessageCandidate = 'BoardmatchMessageCandidate',
  BoardmatchMessageOrg = 'BoardmatchMessageOrg',
  SigningTaskCreatedInviteeV2 = 'SigningTaskCreatedInviteeV2',
  SigningOrderCompletedInviter = 'SigningOrderCompletedInviter',
  SigningOrderExpiredInviter = 'SigningOrderExpiredInviter',
  BoardschoolUserReminder = 'BoardschoolUserReminder',
  BoardschoolExamCompleted = 'BoardschoolExamCompleted',
  UserCreationReminder = 'UserCreationReminder',
  AddedToConversation = 'AddedToConversation',
  RemovedFromConversation = 'RemovedFromConversation',
  UnreadChatMessages = 'UnreadChatMessages',
  NewCallInConversation = 'NewCallInConversation',
}

export const doBatchCode: { [key in NotificationCodeEnum]?: boolean } = {
  [NotificationCodeEnum.ChatMessageMention]: true,
  [NotificationCodeEnum.ChatMessageReply]: true,
  [NotificationCodeEnum.UnreadChatMessages]: true,
};
export const notificationCodes: { [code in NotificationCodeEnum]: NotificationTemplate } = {
  EmissionInviteTask: {
    title: {
      // TRANSLATE SigningTaskCreatedInvitee-Title
      en: 'Capitalincrease',
      nb: 'Kapitalforhøyelse',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public emissionName: string, public url) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInvitee-Template
      en: '<p> A new <a href="{url}" target="_blank">orderform</a> is now avilabel for <b>{emissionName}</b>.</p> ',
      nb: '<p> En ny <a href="{url}" target="_blank">bestillingsblankett</a> er tilgjengelig for <b>{emissionName}</b>.</p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskCreatedInvitee-TemplateNoHtml
      en: ' A new orderform is now avilabel for {emissionName}.',
      nb: ' En ny bestillingsblankett er tilgjengelig for {emissionName}.',
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.SHAREISSUE,
  },
  SigningTaskCreatedInviteeSignicat: {
    title: {
      // TRANSLATE SigningTaskCreatedInvitee-Title
      en: 'Signing Task',
      nb: 'Signeringsoppgave',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public documentName: string, public url, public inviterName) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInvitee-Template
      en: '<p> {inviterName} is requesting that you <a href="{url}" target="_blank">sign</a> the document <b>{documentName}</b>.</p> ',
      nb: '<p>{inviterName} ber deg <a href="{url}" target="_blank">signere</a> dokumentet <b>{documentName}</b>.</p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskCreatedInvitee-TemplateNoHtml
      en: '{inviterName} is requesting that you sign the document {documentName}.',
      nb: '{inviterName} ber deg signere dokumentet {documentName}.',
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.SIGNING,
  },
  OrgbrainSupportRequest: {
    title: {
      // TRANSLATE SigningTaskCreatedInvitee-Title
      en: 'Support Request',
      nb: 'Supportforespørsel',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public organizationName: string, public inviterName, public notes, public url) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInvitee-Template
      en: '<p> {inviterName} is requesting support for {organizationName}.</p> <p>{notes}. <a href="{url}" target="_blank">Click here to go to org</a>.</p>',
      nb: '<p> {inviterName} ber om support for {organizationName}.</p>  <p>{notes}. <a href="{url}" target="_blank">Klikk her for å gå til org</a>.</p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskCreatedInvitee-TemplateNoHtml
      en: '{inviterName} is requesting support for {organizationName}. ',
      nb: '{inviterName}  ber om support for {organizationName}.',
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.SUPPORT,
  },
  SurveyInvite: {
    title: {
      // TRANSLATE SigningTaskCreatedInvitee-Title
      en: 'Survey Task',
      nb: 'Spørreundersøkelse Oppgave',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public title: string, public url, public inviterName) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInvitee-Template
      en: '<p> {inviterName} is requesting that you <a href="{url}" target="_blank">to do</a> a survey: <b>{title}</b>.</p> ',
      nb: '<p>{inviterName} ber deg <a href="{url}" target="_blank">gjennomføre</a> en spørreundersøkelse: <b>{title}</b>.</p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskCreatedInvitee-TemplateNoHtml
      en: '{inviterName} is requesting that you to do a survey: {title}.',
      nb: '{inviterName} ber deg gjennomføre en spørreundersøkelse: {title}.',
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.SURVEY,
  },
  ChatMessageMention: {
    title: {
      en: 'Mentioned in chat',
      nb: 'Nevnt i chat',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(
        public url,
        public inviterName,
        public conversationName,
        public messageText,
        public orgName
      ) {}
    },
    templates: {
      en: '<p> You have been mentioned in the conversation <a href="{url}"><b>"{conversationName}"</b></a> by {inviterName}.</p> ',
      nb: '<p> Du har blitt nevnt i samtalen <a href="{url}"><b>"{conversationName}"</b></a> av {inviterName}.</p>',
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.CONVERSATIONS,
    defaultNotificationType: [
      DistributionMediums.BELL,
      DistributionMediums.EMAIL,
      DistributionMediums.PUSH,
    ],
    isCustomizableNotifications: true,
    emailType: EmailTypeEnum.GENERIC_EMAIL_WITH_NO_PREDEFINED_TEXTS,
    emailSubject: {
      en: '{orgName} - mentioned in a conversation',
      nb: '{orgName} - nevnt i en samtale',
    },
    firstLineInBold: {
      en: 'You have been mentioned in the conversation {conversationName} by {inviterName}:',
      nb: 'Du har blitt nevnt i samtalen "{conversationName}" av {inviterName}:',
    },
    bodyTexts: [
      {
        en: '',
        nb: '',
      },
      {
        en: '"{messageText}"',
        nb: '"{messageText}"',
      },
    ],
    callToAction: {
      en: '',
      nb: '',
    },
    actionButtonText: {
      en: 'View conversation',
      nb: 'Se samtalen',
    },
    webpushMessage: {
      en: '{messageText}',
      nb: '{messageText}',
    },
    webpushTitle: {
      en: '{inviterName}',
      nb: '{inviterName}',
    },
  },
  ChatMessageReply: {
    title: {
      // TRANSLATE ChatMessageReply-Title
      en: 'You have a new reply in chat',
      nb: 'Du har et nytt svar i chatten',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public url, public inviterName, public conversationName, public messageText) {}
    },
    templates: {
      // TRANSLATE ChatMessageReply-Template
      en: '<p>{inviterName} has replied to you in the conversation <a href="{url}"><b>"{conversationName}</b></a>.</p> ',
      nb: '<p>{inviterName} har svart deg i samtalen <a href="{url}"><b>"{conversationName}"</b></a>.</p>',
    },
    templatesNoHtml: {
      // TRANSLATE ChatMessageReply-TemplateNoHtml
      en: '{inviterName} has replied to you in the conversation "{conversationName}".',
      nb: '{inviterName} har svart deg i samtalen "{conversationName}".',
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.CONVERSATIONS,
    defaultNotificationType: [
      DistributionMediums.BELL,
      DistributionMediums.EMAIL,
      DistributionMediums.PUSH,
    ],
    isCustomizableNotifications: true,

    emailType: EmailTypeEnum.GENERIC_EMAIL_WITH_NO_PREDEFINED_TEXTS,
    emailSubject: {
      en: 'You have a new reply in chat',
      nb: 'Du har et nytt svar i chatten',
    },
    firstLineInBold: {
      en: '{inviterName} has replied to you in the conversation "{conversationName}".',
      nb: '{inviterName} har svart deg i samtalen "{conversationName}".',
    },
    bodyTexts: [
      {
        en: '{inviterName}:',
        nb: '{inviterName}:',
      },
      {
        en: '"{messageText}"',
        nb: '"{messageText}"',
      },
    ],
    callToAction: {
      en: '',
      nb: '',
    },
    actionButtonText: {
      en: 'View conversation',
      nb: 'Se samtalen',
    },
    webpushMessage: {
      en: '{messageText}',
      nb: '{messageText}',
    },
    webpushTitle: {
      en: '{inviterName}',
      nb: '{inviterName}',
    },
  },
  UnreadChatMessages: {
    title: {
      en: 'New message in chat',
      nb: 'Ny melding i samtaler',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(
        public url,
        public inviterName,
        public conversationName,
        public messageText,
        public orgName
      ) {}
    },
    templates: {
      // TRANSLATE ChatMessageMention-Template
      en: '<p> There are {numNotifs} new messages in the conversation <a href="{url}"><b>"{conversationName}"</b></a>.</p> ',
      nb: '<p> Du har {numNotifs} nye meldinger i samtalen <a href="{url}"><b>"{conversationName}"</b></a>.</p> ',
    },

    templatesNoHtml: {
      // TRANSLATE ChatMessageMention-TemplateNoHtml
      en: '{orgName} - Unread conversation',
      nb: '{orgName} - ulest samtale',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.CONVERSATIONS,
    defaultNotificationType: [
      DistributionMediums.BELL,
      DistributionMediums.PUSH,
      DistributionMediums.BATCH,
    ],
    isCustomizableNotifications: true,

    emailType: EmailTypeEnum.GENERIC_EMAIL_WITH_NO_PREDEFINED_TEXTS,
    emailSubject: {
      en: '{orgName} - Unread conversation',
      nb: '{orgName} - ulest samtale',
    },
    firstLineInBold: {
      en: 'You have unread content in the conversation <a href="{url}"><b>"{conversationName}"</b></a>.',
      nb: 'Du har ulest innhold i samtalen <a href="{url}"><b>"{conversationName}"</b></a>.',
    },
    bodyTexts: [
      {
        en: '',
        nb: '',
      },
      {
        en: '',
        nb: '',
      },
    ],
    callToAction: {
      en: '',
      nb: '',
    },
    actionButtonText: {
      en: '',
      nb: '',
    },
    webpushMessage: {
      en: '{messageText}',
      nb: '{messageText}',
    },
    webpushTitle: {
      en: '{inviterName}',
      nb: '{inviterName}',
    },
  },
  AnnotationPdfMention: {
    title: {
      // TRANSLATE AnnotationPdfMention-Title
      en: 'Mentioned in PDF',
      nb: 'Nevnt i PDF',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public inviterName, public url, public pdfName: string) {}
    },
    templates: {
      // TRANSLATE AnnotationPdfMention-Template
      en: '<p> {inviterName} has mentioned you in PDF <a href="{url}"><b>{pdfName}</b></a>.</p>',
      nb: '<p>{inviterName} har nevnt deg i PDF <a href="{url}"><b>{pdfName}</b></a>.</p>',
    },
    templatesNoHtml: {
      // TRANSLATE AnnotationPdfMention-TemplateNoHtml
      en: '{inviterName} has mentioned you in PDF {pdfName}.',
      nb: '{inviterName} har nevnt deg i PDF {pdfName}.',
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.PDFMENTION,
  },
  SigningTaskCreatedInviteeV2: {
    title: {
      // TRANSLATE SigningTaskCreatedInvitee-Title
      en: 'Signing Task',
      nb: 'Signeringsoppgave',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public documentName: string, public url, public inviterName) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInvitee-Template
      en: '<p> {inviterName} is requesting that you <a href="{url}" target="_blank">sign</a> the document <b>{documentName}</b>.</p> ',
      nb: '<p>{inviterName} ber deg <a href="{url}" target="_blank">signere</a> dokumentet <b>{documentName}</b>.</p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskCreatedInvitee-TemplateNoHtml
      en: '{inviterName} is requesting that you to sign the document {documentName}.',
      nb: '{inviterName} ber deg signere dokumentet {documentName}.',
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.SIGNING,
  },
  SigningTaskCreatedInviter: {
    title: {
      // TRANSLATE SigningTaskCreatedInviterTitle
      en: 'Signing Task Sent',
      nb: 'Signeringsoppgave sendt',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public documentName: string, public url, public inviteeName) {}
    },
    templates: {
      // TRANSLATE SigningTaskCreatedInviterTemplate
      en: '<p> You invited {inviteeName} to sign the document <b>{documentName}</b>. </p> ',
      nb: '<p> Du inviterte {inviteeName} til å signere dokumentet <b>{documentName}</b>. </p> ',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskCreatedInviterTemplateNoHtml
      en: 'You invited {inviteeName} to sign the document {documentName}.',
      nb: 'Du inviterte {inviteeName} til å signere dokumentet {documentName}.',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.SIGNING,
  },
  SigningTaskCompletedInviter: {
    title: {
      // TRANSLATE SigningTaskCompletedInviter-title
      en: 'Signing Task Completed',
      nb: 'Signeringsoppgave fullført',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public documentName: string, public inviteeName: string) {}
    },
    templates: {
      // TRANSLATE  SigningTaskCompletedInviter-template
      en: '<p> {inviteeName} has signed the document <b>{documentName}</b> </p> ',
      nb: '<p>{inviteeName} har signert dokumentet <b>{documentName}</b></p>',
    },
    templatesNoHtml: {
      // TRANSLATE  SigningTaskCompletedInviter-TemplateNoHtml
      en: '{inviteeName} has signed the document {documentName}.',
      nb: '{inviteeName} har signert dokumentet {documentName}.',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.SIGNING,
  },
  SigningTaskCompletedInvitee: {
    title: {
      // TRANSLATE SigningTaskCompletedInvitee-title
      en: 'Signing Task Completed',
      nb: 'Signeringsoppgave fullført',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public documentName: string) {}
    },
    templates: {
      // TRANSLATE SigningTaskCompletedInvitee-template
      en: '<p>  You have signed the document <b>{documentName}</b> </p> ',
      nb: '<p> Du har signert dokumentet <b>{documentName}</b></p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskCompletedInvitee-TemplateNoHtml
      en: 'You have signed the document {documentName}.',
      nb: 'Du har signert dokumentet {documentName}.',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.SIGNING,
  },
  SigningTaskRejectedInviter: {
    title: {
      // TRANSLATE SigningTaskRejectedInviter-title
      en: 'Signing Task Rejected',
      nb: 'Signeringsoppgave avvist',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public url: string, public documentName: string, public signerName: string) {}
    },
    templates: {
      // TRANSLATE SigningTaskRejectedInviter-template
      en: '<p> {inviteeName} has rejected to sign the document <b>{documentName}</b> </p> ',
      nb: '<p>{inviteeName} har avvist å signere dokumentet <b>{documentName}</b></p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningTaskRejectedInviter-TemplateNoHtml
      en: '{inviteeName} has rejected to sign the document {documentName}.',
      nb: '{inviteeName} har avvist å signere dokumentet {documentName}.',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.SIGNING,
  },
  EmailFailedToSend: {
    title: {
      // TRANSLATE EmailFailedToSend-title
      en: 'Email delivery failed',
      nb: 'Levering av e-post mislyktes',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public address: string, public topic: string) {}
    },
    templates: {
      // TRANSLATE EmailFailedToSend-template
      en: '<p>Email {topic} has not been delivered to <b>{address}</b></p>',
      nb: '<p>E-post {topic} er ikke levert til <b>{address}</b></p>',
    },
    templatesNoHtml: {
      // TRANSLATE EmailFailedToSend-TemplateNoHtml
      en: 'Email {topic} has not been delivered to {address}.',
      nb: 'E-post {topic} er ikke levert til {address}.',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.DUMMY,
  },
  NewsNotification: {
    title: {
      // TRANSLATE NewsNotification-title
      en: 'News update',
      nb: 'Nyhetsoppdatering',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public topic, public url: string) {}
    },
    templates: {
      // TRANSLATE NewsNotification-Template
      en: '<p> {topic}. To read more <a href="{url}" target="_blank">click<a> </p> ',
      nb: '<p>{topic}. For å lese mer <a href="{url}" target="_blank">klikk<a></p></a>',
    },
    templatesNoHtml: {
      // TRANSLATE NewsNotification-TemplateNoHtml
      en: '{topic}. To read more click.',
      nb: '{topic}. For å lese mer klikk.',
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.MESSAGES,
  },
  SigningOrderCreated: {
    title: {
      // TRANSLATE  SigningOrderCreated-title
      en: 'Signing Request Created',
      nb: 'Signeringsordre opprettet',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public documentName: string, public url: string) {}
    },
    templates: {
      // TRANSLATE  SigningOrderCreated-template
      en: '<p>A signing request for <b>{documentName}</b> has been created</p> ',
      nb: '<p>Det er opprettet en signeringsordre for <b>{documentName}</b></p> ',
    },
    templatesNoHtml: {
      // TRANSLATE  SigningOrderCreated-TemplateNoHtml
      en: 'A signing request for {documentName} has been created.',
      nb: 'Det er opprettet en signeringsordre for {documentName}.',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.SIGNING,
  },
  SigningOrderCompletedInviter: {
    title: {
      // TRANSLATE SigningOrderCompletedInviter-title
      en: 'Signing Request Completed',
      nb: 'Signeringsordre fullført',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public documentName: string) {}
    },
    templates: {
      // TRANSLATE SigningOrderCompletedInviter-template
      en: '<p>A signing order for <b>{documentName}</b> has completed </p> ',
      nb: '<p>En signeringsordre for <b>{documentName}</b> er fullført </p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningOrderCompletedInviter-TemplateNoHtml
      en: 'A signing order for {documentName} has completed.',
      nb: 'En signeringsordre for {documentName} er fullført.',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.SIGNING,
  },
  SigningOrderCreateFailed: {
    title: {
      // TRANSLATE SigningOrderCreateFailed-title
      en: 'Signing Request Failed to Create',
      nb: 'Signeringsordre kan ikke opprettes',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      documentName: string;
    },
    templates: {
      // TRANSLATE SigningOrderCreateFailed-template
      en: '<p>There was a system problem trying to create a signing request for <b>{documentName}</b></p>',
      nb: '<p>En signeringsordre for <b>{documentName}</b> kan ikke opprettes</p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningOrderCreateFailed-TemplateNoHtml
      en: 'There was a system problem trying to create a signing request for {documentName}.',
      nb: 'En signeringsordre for {documentName} kan ikke opprettes.',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.SIGNING,
  },
  SigningOrderExpiredInviter: {
    title: {
      // TRANSLATE  SigningOrderExpiredInviter-title
      en: 'Signing Request Expired',
      nb: 'Signeringsordre utløpt',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public documentName: string) {}
    },
    templates: {
      // TRANSLATE SigningOrderExpiredInviter-template
      en: '<p>A signing request for <b>{documentName}</b> expired</p> ',
      nb: '<p>En signeringsordre for <b>{documentName}</b> utløpt</p>',
    },
    templatesNoHtml: {
      // TRANSLATE SigningOrderExpiredInviter-TemplateNoHtml
      en: 'A signing request for {documentName} expired.',
      nb: 'En signeringsordre for {documentName} utløpt.',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.SIGNING,
  },
  MeetingInvitation: {
    title: {
      en: 'Meeting invite',
      nb: 'Møteinvitasjon',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public url: string, public title: string, public date: string) {}
    },
    templates: {
      en: '<p>{title} on <b><a href="{url}">{date}</a></b></p> ',
      nb: '<p>{title} i <b><a href="{url}">{date}</a></b></p>',
    },
    templatesNoHtml: {
      en: '{title} on {date}',
      nb: '{title} i {date}',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.MEETING,
  },
  MeetingAttendenceReminder: {
    title: {
      en: 'Minutes Reminder',
      nb: '',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor() {}
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.MEETING,
  },
  MinutesHearing: {
    title: {
      en: 'Minutes for hearing',
      nb: 'Protokoll på høring',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor() {}
    },
    templates: {
      en: 'You received <b><a href="{url}">minutes for hearing</a></b>',
      nb: 'Du mottok <b><a href="{url}">protokoll på høring</a></b>',
    },
    templatesNoHtml: {
      en: 'You received minutes for hearing',
      nb: 'Du mottok protokoll på høring',
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.MEETING,
  },
  MeetingOrganizerReminderToInvite: {
    title: {
      en: 'Meeting Organizer Reminder To Invite',
      nb: 'Møtearrangør påminnelse om å invitere',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor() {}
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.MEETING,
  },
  ResponsibleForActionPoint: {
    title: {
      en: 'New task created',
      nb: 'Ny oppgave lagt til',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      title: string;
      url: string;
    },
    templates: {
      en: '<p>A task that you are responsible for: <b><a href="{url}">{title}</a></b> has been created</p> ',
      nb: '<p>En oppgave du er ansvarlig for: <b><a href="{url}">{title}</a></b> har blitt lagt til</p>',
    },
    templatesNoHtml: {
      en: 'A task that you are responsible for: {title} has been created.',
      nb: 'En oppgave du er ansvarlig for: {title} har blitt lagt til.',
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.TASKS,
  },
  BoardschoolUserReminder: {
    title: {
      en: 'Reminder: Board school',
      nb: 'Påminnelse: Styreskolen',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      url: string;
    },
    templates: {
      en: '<p>Click <b><a href="{url}">to navigate</a></b> to the boardschool.</p> ',
      nb: '<p>Klikk <b><a href="{url}">på knappen</a></b> for å gå til Styreskolen.</p>',
    },
    templatesNoHtml: {
      en: 'Click to navigate</a></b> to the boardschool.',
      nb: 'Klikk for å gå til Styreskolen.',
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.BOARDSCHOOL,
  },
  BoardschoolExamCompleted: {
    title: {
      en: 'Gratulerer med bestått eksamen!',
      nb: 'Congratulations on passing the exam!',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      url: string;
    },
    templates: {
      en: '<p>You can download your certificate by clicking <b><a href="{url}">here</a></b>.</p> ',
      nb: '<p>Du kan laste ned sertifiseringsbeviset ditt ved å klikke <b><a href="{url}">her</a></b>.</p>',
    },
    templatesNoHtml: {
      en: 'You can download your certificate.',
      nb: 'Du kan laste ned sertifiseringsbeviset ditt.',
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.BOARDSCHOOL,
  },
  UserCreationReminder: {
    title: {
      en: '',
      nb: '',
    },
    code: '' as NotificationCodeEnum,
    data: class {},
    templates: {
      en: '',
      nb: '',
    },
    templatesNoHtml: {
      en: '',
      nb: '',
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.OTHER,
  },
  InviteForMeetingTime: {
    title: {
      en: textMappings.InvitationToVoteForMeetingTime.en,
      nb: textMappings.InvitationToVoteForMeetingTime.nb,
    },
    code: '' as NotificationCodeEnum,
    data: class {
      title: string;
      url: string;
    },
    templates: {
      en: `<p><b><a href="{url}">${textMappings.VoteTimeButtonText.en}</a></b> {title}</p>`,
      nb: `<p><b><a href="{url}">${textMappings.VoteTimeButtonText.nb}</a></b> {title}</p>`,
    },
    templatesNoHtml: {
      en: `${textMappings.VoteTimeButtonText.en} {title}`,
      nb: `${textMappings.VoteTimeButtonText.nb} {title}`,
    },
    type: NotificationType.ACTION_AUTO,
    batchClusterType: NotificationClusterTypeEnum.MEETING,
  },
  BoardmatchInquiryCandidate: {
    title: {
      en: 'Recived organization inquiry',
      nb: 'Motatt organisasjons henvendelse',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor() {}
    },
    templates: {
      en: '<p><b>Your profile</b> has received an inquiry</p>',
      nb: '<p><b>Profilen din</b> har motatt en henvendelse</p>',
    },
    templatesNoHtml: {
      en: 'Your profile has received an inquiry',
      nb: 'Profilen din har motatt en henvendelse',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.BOARDMATCH,
  },
  BoardmatchInquiryOrg: {
    title: {
      en: 'Recived candidate inquiry',
      nb: 'Motatt kandidat henvendelse',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public title: string, public url: string) {}
    },
    templates: {
      en: '<p>The ad <b><a href="{url}">{title}</a></b> that you are responsible for has received an inquiry.</p>',
      nb: '<p>Annonsen <b><a href="{url}">{title}</a></b> som du er ansvarlig for har fått en henvendelse.</p>',
    },
    templatesNoHtml: {
      en: 'The ad {title} that you are responsible for has received an inquiry.',
      nb: 'Annonsen {title} som du er ansvarlig for har fått en henvendelse.',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.BOARDMATCH,
  },
  BoardmatchMessageCandidate: {
    title: {
      en: 'Recived organization message',
      nb: 'Motatt organisasjons melding',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public url: string) {}
    },
    templates: {
      en: '<p><b>Your profile</b> has received an message</p>',
      nb: '<p><b>Profilen din</b> har motatt en melding</p>',
    },
    templatesNoHtml: {
      en: 'Your profile has received an inquiry',
      nb: 'Profilen din har motatt en melding',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.BOARDMATCH,
  },
  BoardmatchMessageOrg: {
    title: {
      en: 'Recived candidate message',
      nb: 'Motatt kandidat melding',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(public title: string, public url: string) {}
    },
    templates: {
      en: '<p>The ad <b><a href="{url}">{title}</a></b> that you are responsible for has received an message.</p>',
      nb: '<p>Annonsen <b><a href="{url}">{title}</a></b> som du er ansvarlig for har fått en melding.</p>',
    },
    templatesNoHtml: {
      en: 'The ad {title} that you are responsible for has received an message.',
      nb: 'Annonsen {title} som du er ansvarlig for har fått en melding.',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.BOARDMATCH,
  },
  AddedToConversation: {
    title: {
      en: 'Added to conversation',
      nb: 'Lagt til i samtale',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(
        public conversationName: string,
        public inviterName: string,
        public url: string
      ) {}
    },
    templates: {
      en: '<p>You were added in the conversation <b><a href="{url}">"{conversationName}"</a></b></p>',
      nb: '<p>Du ble lagt til i samtalen <b><a href="{url}">"{conversationName}"</a></b></p>',
    },
    templatesNoHtml: {
      en: 'You were added in the conversation "{conversationName}"',
      nb: 'Du ble lagt til i samtalen "{conversationName}"',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.CONVERSATIONS,
    defaultNotificationType: [DistributionMediums.BELL, DistributionMediums.PUSH],
    isCustomizableNotifications: true,

    emailType: EmailTypeEnum.GENERIC_EMAIL_WITH_NO_PREDEFINED_TEXTS,
    emailSubject: {
      en: 'Added to conversation',
      nb: 'Lagt til i samtale',
    },
    firstLineInBold: {
      en: '{inviterName} added you to the conversation "{conversationName}"',
      nb: '{inviterName} har lagt deg til i samtalen "{conversationName}"',
    },
    bodyTexts: [
      {
        en: '',
        nb: '',
      },
      {
        en: '',
        nb: '',
      },
    ],
    callToAction: {
      en: '',
      nb: '',
    },
    actionButtonText: {
      en: 'View conversations',
      nb: 'Se samtaler',
    },
    webpushTitle: {
      en: 'Added to "{conversationName}"',
      nb: 'Lagt til i "{conversationName}"',
    },
    webpushMessage: {
      en: '{inviterName} added you to the conversation "{conversationName}"',
      nb: '{inviterName} har lagt deg til i samtalen "{conversationName}"',
    },
  },
  RemovedFromConversation: {
    title: {
      en: 'Removed from conversation',
      nb: 'Fjernet fra samtale',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(
        public conversationName: string,
        public inviterName: string,
        public url: string
      ) {}
    },
    templates: {
      en: '<p>You were removed from the conversation <b><a href="{url}">"{conversationName}"</a></b></p>',
      nb: '<p>Du ble fjernet fra samtalen <b><a href="{url}">"{conversationName}"</a></b></p>',
    },
    templatesNoHtml: {
      en: 'You were removed from the conversation "{conversationName}"',
      nb: 'Du ble fjernet fra samtalen "{conversationName}"',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.CONVERSATIONS,
    defaultNotificationType: [DistributionMediums.BELL, DistributionMediums.PUSH],
    isCustomizableNotifications: true,

    emailType: EmailTypeEnum.GENERIC_EMAIL_WITH_NO_PREDEFINED_TEXTS,
    emailSubject: {
      en: 'Removed from conversation',
      nb: 'Fjernet fra samtale',
    },
    firstLineInBold: {
      en: '{inviterName} removed you from the conversation "{conversationName}"',
      nb: '{inviterName} har lagt deg til i samtalen "{conversationName}"',
    },
    bodyTexts: [
      {
        en: '',
        nb: '',
      },
      {
        en: '',
        nb: '',
      },
    ],
    callToAction: {
      en: '',
      nb: '',
    },
    actionButtonText: {
      en: 'View conversations',
      nb: 'Se samtaler',
    },

    webpushTitle: {
      en: 'Removed from "{conversationName}"',
      nb: 'Fjernet fra "{conversationName}"',
    },
    webpushMessage: {
      en: '{inviterName} removed you from the conversation "{conversationName}"',
      nb: '{inviterName} har fjernet deg fra samtalen "{conversationName}"',
    },
  },
  AlertToMessage: {
    title: {
      en: 'Alerted to message',
      nb: 'Varslet om melding',
    },
    templates: {
      en: '<p> {senderName} has alerted everyone in the conversation <a href="{url}" target="_blank">{conversationName}</a> about an important message</p> ',
      nb: '<p> {senderName} har varslet alle i samtalen <a href="{url}" target="_blank">{conversationName}</a> om en viktig melding</p> ',
    },
    data: class {
      constructor(
        public url: string,
        public conversationName: string,
        public senderName: string,
        public messageText: string
      ) {}
    },
    code: '' as NotificationCodeEnum,
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.CONVERSATIONS,
    defaultNotificationType: [
      DistributionMediums.BELL,
      DistributionMediums.EMAIL,
      DistributionMediums.PUSH,
    ],
    isCustomizableNotifications: false,

    emailType: EmailTypeEnum.GENERIC_EMAIL_WITH_NO_PREDEFINED_TEXTS,
    emailSubject: {
      en: 'Alerted to message',
      nb: 'Varslet om melding',
    },
    firstLineInBold: {
      en: '{senderName} has alerted everyone in the conversation {conversationName} about an important message',
      nb: '{senderName} har varslet alle i samtalen {conversationName} om en viktig melding',
    },
    bodyTexts: [
      {
        en: '{senderName}:',
        nb: '{senderName}:',
      },
      {
        en: '"{messageText}"',
        nb: '"{messageText}"',
      },
    ],
    callToAction: {
      en: '',
      nb: '',
    },
    actionButtonText: {
      en: 'View conversation',
      nb: 'Se samtalen',
    },
    webpushMessage: {
      en: '{messageText}',
      nb: '{messageText}',
    },
    webpushTitle: {
      en: '{senderName}',
      nb: '{senderName}',
    },
  },
  NewCallInConversation: {
    title: {
      en: 'New call in conversation',
      nb: 'Nytt videomøte i samtale',
    },
    code: '' as NotificationCodeEnum,
    data: class {
      constructor(
        public conversationName: string,
        public inviterName: string,
        public url: string
      ) {}
    },
    templates: {
      en: '<p>{inviterName} has started a new call in the conversation <b><a href="{url}">"{conversationName}"</a></b></p>',
      nb: '<p>{inviterName} har startet et nytt videomøte i samtalen <b><a href="{url}">"{conversationName}"</a></b></p>',
    },
    templatesNoHtml: {
      en: '{inviterName} has started a new call in the conversation "{conversationName}"',
      nb: '{inviterName} har startet et nytt videomøte i samtalen "{conversationName}"',
    },
    type: NotificationType.INFORMATION,
    batchClusterType: NotificationClusterTypeEnum.CONVERSATIONS,
    defaultNotificationType: [DistributionMediums.BELL, DistributionMediums.PUSH],
    isCustomizableNotifications: true,

    emailType: EmailTypeEnum.GENERIC_EMAIL_WITH_NO_PREDEFINED_TEXTS,
    emailSubject: {
      en: 'New call in conversation',
      nb: 'Nytt videomøte i samtale',
    },
    firstLineInBold: {
      en: '{inviterName} has started a new call in the conversation "{conversationName}"',
      nb: '{inviterName} har startet et videomøte i samtalen "{conversationName}"',
    },
    bodyTexts: [
      {
        en: '',
        nb: '',
      },
      {
        en: '',
        nb: '',
      },
    ],
    callToAction: {
      en: '',
      nb: '',
    },
    actionButtonText: {
      en: 'View conversation',
      nb: 'Se samtalen',
    },

    webpushTitle: {
      en: 'New call in "{conversationName}"',
      nb: 'Nytt videomøte i "{conversationName}"',
    },
    webpushMessage: {
      en: '{inviterName} has started a new call in the conversation "{conversationName}"',
      nb: '{inviterName} har startet et nytt videomøte i samtalen "{conversationName}"',
    },
  },
};

export function getTextFieldFromTemplate(variables: TemplateVariables, locale: string) {
  return (field: { nb: string; en: string }) => {
    if (locale in field)
      return field[locale].replace(/{(.*?)}/g, (_, placeholder) => {
        return variables[placeholder.trim()] || '';
      });
  };
}
//This type is only here to force devs to make own templateVariables class
export interface TemplateVariables {
  url: string;
}
export interface AlertToMessageVariables extends TemplateVariables {
  senderName: string;
  conversationName: string;
  messageText: string;
}
export interface ChatMessageMentionVariables extends TemplateVariables {
  inviterName: string;
  conversationName: string;
  messageText: string;
  orgName: string;
}
export interface ChatAddedAndRemovedVariables extends TemplateVariables {
  inviterName: string;
  conversationName: string;
}

// Well this is naff but it helps checking.
const keys = Object.keys(notificationCodes);
for (const key of keys) {
  notificationCodes[key].code = key;
  notificationCodes[key].placeHolders = extractTemplatePlaceholders(
    notificationCodes[key].templates
  );
  // console.log('notificationCodes placeholders', key, notificationCodes[key].placeHolders);
}
// This should match the notificationSchema  (lib-mongoose)
// LINK  ../../../../libs/lib-mongoose/src/lib/notification-schema.ts#NOTIFICATION_SCHEMA
// ANCHOR[id=NOTIFICATION_CLASS]
export class Notification {
  id: string;
  due: Date; //  time stamp for when tasks is due  or just the date to order in the list.
  userId: string; // userId of who we are notifying
  code: string; //   Unique code for this Notifiction type -- used for language templates -- decoding data
  data: string; //  Stringified  artbirtary data specific to  code
  status: NotificationStatus; //
  expiresAfter: number; // unix time diff relative to due date
  activiateBefore: number;
  deleted: boolean; //  soft delete flag
  // OPTIONAL KEYS FOR SEARCHING  (keep adding as needed)
  organizationId: string; //  organization context
  meetingId: string; // meeting context
  direntId: string; //  for file operations.
  signOrderId: string; // sign orders  mongodb record id
  requestId: String; //  signicat reference for order
  taskId: String; //  reference for a signing task
  createdAt: Date; // mongodb  auto created
  updatedAt: Date;
}

export type IWebPushNotifications = {
  user: MongoId;
  webPushEnabled: boolean;
  subscriptions: any;
  debug: any;
};
