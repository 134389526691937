export enum RecordEnum {
  ORGBRAIN_SUPPORT = 'ORGBRAIN_SUPPORT',
  OPENAI_PERMISSION = 'OPENAI_PERMISSION',
}

export type Record = {
  code: RecordEnum; //   Unique code for this RecordMessage.  Used to search
  data: any; //   arbitrary data

  // Context  from request
  referer?: string; //  Referrer URL from incoming req.
  agent?: string; // agent from req.  ( web browser etc)
  url?: string; // original URL in the message
  ip?: string;
  userId?: string; // initiator userId  req.user_data._id
  sessionToken?: string;
  credentials?: string;
  notes?: string;

  // OPTIONAL KEYS FOR SEARCHING  (keep adding as needed)
  subjectIds?: string[]; // Users that are affected
  organizationId?: string; //  organizations
  meetingId?: string; // meeting
  direntIds?: string[]; //  for file operations.
  signOrderId?: string; // sign orders
  createdAt?: Date;
};
