/*
 *  Copyright 2010-2016 FinTech Neo AS ( fintechneo.com )- All rights reserved
 */

import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrgBrainDialogService } from './ogbrainDialog.service';

@Component({
  template: ` <mat-spinner></mat-spinner> `,
})
export class ProgressDialogComponent {
  static _orgbrainDialog: OrgBrainDialogService;
  constructor(public orgbrainDialog: OrgBrainDialogService) {
    ProgressDialogComponent._orgbrainDialog = orgbrainDialog;
  }

  static progressDialogRef: MatDialogRef<ProgressDialogComponent> = null;

  static open(dialog: MatDialog) {
    if (!ProgressDialogComponent.progressDialogRef) {
      ProgressDialogComponent.progressDialogRef = ProgressDialogComponent.progressDialogRef =
        dialog.open(ProgressDialogComponent, {
          backdropClass:
            ProgressDialogComponent._orgbrainDialog?.defaultBackdrop() || 'darkerBackdrop',
        });
    }
  }

  static close() {
    if (ProgressDialogComponent.progressDialogRef) {
      ProgressDialogComponent.progressDialogRef.close();
      ProgressDialogComponent.progressDialogRef = null;
    }
  }
}
