import { Injectable } from '@angular/core';
import { printClassName } from '@orgbrain/lib-data';

@Injectable({
  providedIn: 'root',
})
export class MongooseUtilService {
  constructor() {
    printClassName(' ---> ', this);
  }

  dateFromObjectId = function (objectId: string): Date {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  };
}
