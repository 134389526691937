// Get the dte from mongodb ID.
import { companyTypes } from '@orgbrain/lib-data';

import * as moment from 'moment-timezone';
import 'moment/locale/nb';

export type FetchDataHelperFnParams = {
  pageSize: number;
  sortDirection?: 'asc' | 'desc';
  currentPage?: number;
  filter?: string;
  activeField?: string;
  filterFields?: string[];
  clientId?: string;
  processed?: boolean;
};

export const specialCharCompanyNameRegexp = /[.,/|\\!@#$&*(_+?:-]/;

export const dateFromObjectId = function (objectId: string): Date {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

export function mergeUserAttributesDEPRECATED(
  data: any /* CognitoUser */,
  attributesKey?: string /* User */
): any {
  const ret: any = {};
  if (!attributesKey) {
    attributesKey = 'Attributes';
  }
  data[attributesKey].forEach((item) => {
    let key = item.Name;
    if (item.Name === 'given_name') {
      key = 'firstName';
    }

    if (item.Name === 'family_name') {
      key = 'lastName';
    }

    ret[key] = item.Value;
  });

  delete data[attributesKey];

  const ret2: any = {
    ...data,
    ...ret,
  };

  return ret2;
}

export class MyTimer {
  // Deprecated ?
  start: number;
  stamp: number;
  prefix: string;

  constructor(prefix?: string) {
    this.prefix = prefix;
    this.stamp = this.start = Date.now();
  }

  ellapsed(): number {
    this.stamp = Date.now();
    return this.stamp - this.start;
  }

  delta(): number {
    const last = this.stamp;
    this.stamp = Date.now();
    return this.stamp - last;
  }

  logDelta(message) {
    const val = this.delta();
    this._disp(`${this.prefix} ${message}  delta:`, val);
  }

  logEllapsed(message) {
    const val = this.ellapsed();
    this._disp(`${this.prefix} ${message}  Tot: `, val);
  }

  _disp(message, val) {
    const date = new Date(this.stamp);
    const t = date.toLocaleTimeString();

    const RED = `\x1b[31m`;
    const GREEN = `\x1b[32m`;
    const DEF = `\x1b[00m`;
    let col = GREEN;
    if (val > 500) {
      col = RED;
    }
    console.log(`${t} ${message}  ${col} ${val} ${DEF}`);
  }
}

export function nameMatcher(aStr: string, bStr: string): boolean {
  const a = aStr.trim().toLowerCase();
  const b = bStr.trim().toLowerCase();

  if (b === a) return true;

  const aToks = a.split(' ');
  const bToks = b.split(' ');

  return false;
}

export function createStringFromTemplate(template, variables): string {
  if (!template) {
    return '';
  }
  variables = variables || {};
  return template.replace(new RegExp('{([^{]+)}', 'g'), function (_unused, varName) {
    //  return variables[varName] || "Missing variable: '" + varName + "'";
    return variables[varName] || '';
  });
}

export function createStringFromTemplateDollar(template, variables): string {
  if (!template) {
    return '';
  }
  variables = variables || {};
  return template.replace(new RegExp('\\$\\{([^${}]+)\\}', 'g'), function (_unused, varName) {
    return variables[varName] || "Missing variable: '" + varName + "'";
  });
}

//stackoverflow.com/questions/8493195/how-can-i-parse-a-csv-string-with-javascript-which-contains-comma-in-data/41563966

// convert cvs file   text to a 2D array
export function csvToArray(text, sep): string[][] {
  let p = '';
  let row = [''];
  const ret = [row];
  let i = 0;
  let r = 0;
  let s = !0;
  let l;
  for (l of text) {
    if ('"' === l) {
      if (s && l === p) row[i] += l;
      s = !s;
    } else if (sep === l && s) l = row[++i] = '';
    else if ('\n' === l && s) {
      if ('\r' === p) row[i] = row[i].slice(0, -1);
      row = ret[++r] = [(l = '')];
      i = 0;
    } else row[i] += l;
    p = l;
  }
  return ret;
}

export const capitalizeFLetter = (str) => {
  str = str.trim();
  const toks = str.split(' ');
  let ret = '';
  toks.forEach((tok) => {
    tok = tok.trim();
    ret = ret + ' ' + tok.charAt(0).toUpperCase() + tok.slice(1).toLowerCase();
  });
  return ret.trim();
};

export const capitalizeFLettersAfterHyphen = (str) => {
  str = str.trim();
  //   console.log(`1 str >${str}<`);
  const toks = str.split(' ');
  let ret = '';
  toks.forEach((tok) => {
    if (tok) {
      tok = tok.trim();
      //   console.log(`2 tok  >${tok}<`);
      const toksHyphen = tok.split('-');
      if (toksHyphen.length === 1) {
        ret = ret + ' ' + tok.charAt(0).toUpperCase() + tok.slice(1).toLowerCase();
      } else {
        const newNames = [];
        toksHyphen.forEach((tokhyp) => {
          tokhyp = tokhyp.trim();
          //   console.log(`3  tokhyp >${tokhyp}<`);
          newNames.push(tokhyp.charAt(0).toUpperCase() + tokhyp.slice(1).toLowerCase());
        });
        ret = ret + ' ' + newNames.join('-');
      }
    }
  });
  return ret.trim();
};

export const capitalizeAndSplitFullname = (namefull) => {
  namefull = namefull.trim();
  const ii = namefull.indexOf(' ');
  const lastName = capitalizeFLettersAfterHyphen(namefull.substring(0, ii));
  const firstName = capitalizeFLettersAfterHyphen(namefull.substring(ii));
  return { firstName, lastName };
};

export const capitalizeVanilla = (word) => {
  word = word.trim();
  if (word.length <= 1) {
    return word.toUpperCase();
  }
  let result = word.charAt(0).toUpperCase();
  const rest = word.slice(1).toLowerCase();
  result = result + rest;
  return result;
};

export const correctlyCapitalizeSpecialWord = (token) => {
  if (!token) return;

  if (token.length <= 1) {
    return token.toUpperCase();
  }
  let result = token.charAt(0).toUpperCase();
  const rest = token.slice(1).toLowerCase();
  const index = rest.search(specialCharCompanyNameRegexp);
  if (index === -1) {
    result = result + rest;
  } else {
    const part2 = correctlyCapitalizeSpecialWord(rest.slice(index + 1)) || '';
    result = result + rest.slice(0, index + 1) + part2;
  }
  return result;
};

export const capitalizeCompany = (nameAllCaps) => {
  nameAllCaps = nameAllCaps || '';
  const companyTypesMap = {};
  for (const type of companyTypes) {
    companyTypesMap[type.type] = true;
  }

  let tokens: string[] = nameAllCaps.split(' ').filter((item) => !!item.trim());

  tokens = tokens.map((token, index) => {
    if (token.search(specialCharCompanyNameRegexp) !== -1) {
      return correctlyCapitalizeSpecialWord(token);
    } else if (index === tokens.length - 1 && companyTypesMap[token]) {
      return token;
    } else {
      return token.charAt(0).toUpperCase() + token.slice(1).toLowerCase();
    }
  });

  return tokens.join(' ');
};

export function splitFullName(name: string): { firstName: string; lastName: string } {
  const namefull = name.trim();
  const ii = namefull.indexOf(' ');
  const lastName = capitalizeFLetter(namefull.substring(0, ii));
  const firstName = capitalizeFLetter(namefull.substring(ii));

  return { firstName, lastName };
}

// temp password
export function randomStuff(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function randomNumbers(length) {
  let result = '';
  const characters = '0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function randomUpper(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function randomLower(length) {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export class RateLimiter {
  history = [];
  paused: boolean;

  constructor(public n: number, public interval) {}

  async pause(): Promise<void> {
    const now = Date.now();
    if (this.history.length < this.n) {
      this.history.push(now);
    } else {
      const waitTime = this.history[0] + this.interval - now;
      this.history.splice(0, 1);
      if (waitTime > 0) {
        this.history.push(now + waitTime);
        this.paused = true;
        await new Promise<void>((resolve) => setTimeout(() => resolve(), waitTime));
        this.paused = false;
      } else {
        this.paused = false;
        this.history.push(now);
      }
    }
  }
}

const DUMMY_WHITE_LIST = ['paul@orgbrain.ai'];

export function createDummyEmail({
  prefix,
  email,
  domain,
}: {
  prefix: string;
  email: string;
  domain: string;
}) {
  if (DUMMY_WHITE_LIST.includes(email)) {
    return email;
  }

  if (email.trim().endsWith('>')) {
    const toks = email.split(/<|>/);
    const newemail1 = toks[1].replace('+', '');
    const ii = newemail1.indexOf('@');
    const newEmail = toks[0] + '<' + prefix + newemail1.substring(0, ii + 1) + domain + '>';
    return newEmail;
  } else {
    const newemail1 = email.replace('+', '');
    const ii = newemail1.indexOf('@');
    const newEmail = prefix + newemail1.substring(0, ii + 1) + domain;
    return newEmail;
  }
}

export function dateFromLifeTime(daysToLive: number, dateActiveTo?: Date): Date {
  dateActiveTo = dateActiveTo || new Date();
  const eventUnix = dateActiveTo.getTime() + 24 * 60 * 60 * 1000 * daysToLive - 60 * 60 * 1000;
  const event = new Date(eventUnix);
  return event;
}

export function timezoneAwareDateFormatter({
  date,
  timezone,
  locale,
  formatPattern,
  addHoursDurationHack,
}: {
  date: Date | string;
  timezone?: string;
  locale: string;
  // 1.  'short': equivalent to 'M/d/yy, h:mm a' (6/15/15, 9:03 AM).
  // 2.  'medium': equivalent to 'MMM d, y, h:mm:ss a' (Jun 15, 2015, 9:03:01 AM).
  // 3.  'long': equivalent to 'MMMM d, y, h:mm:ss a z' (June 15, 2015 at 9:03:01 AM GMT+1).
  // 4.  'full': equivalent to 'EEEE, MMMM d, y, h:mm:ss a zzzz' (Monday, June 15, 2015 at 9:03:01 AM GMT+01:00).
  // 5.  'shortDate': equivalent to 'M/d/yy' (6/15/15).
  // 6.  'mediumDate': equivalent to 'MMM d, y' (Jun 15, 2015).
  // 7.  'longDate': equivalent to 'MMMM d, y' (June 15, 2015).
  // 8.  'fullDate': equivalent to 'EEEE, MMMM d, y' (Monday, June 15, 2015).
  // 9.  'shortTime': equivalent to 'h:mm a' (9:03 AM).
  // 10. 'mediumTime': equivalent to 'h:mm:ss a' (9:03:01 AM).
  // 11. 'longTime': equivalent to 'h:mm:ss a z' (9:03:01 AM GMT+1).
  // 12. 'fullTime': equivalent to 'h:mm:ss a zzzz' (9:03:01 AM GMT+01:00).
  // And more... https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
  formatPattern?: string;
  // This is a (temporary) solution for places where time is being set to 00:00 Norwegian time. Could cause problems before we implement Timezones.
  // Imaging someone sets a Task due date while travelling to Sofia, Bulgaria.
  // Result - time in Oslo becomes 23:00 of the previous day.
  // So if addDurationHack is set to 3 hours, we let the person safely travel 3 timezones from Olso and still use Tasks Due Date.
  // I feel shame for writing this bit of code...
  // We need to properly implement timezones
  addHoursDurationHack?: number;
}) {
  formatPattern = formatPattern || 'Do MMM YYYY, HH:mm';
  timezone = timezone || 'Europe/Oslo';
  locale = locale || 'nb';
  moment.locale(locale);
  const momentTime = moment(date);
  if (Number.isInteger(addHoursDurationHack)) {
    momentTime.add(addHoursDurationHack, 'hours');
  }
  const localTime = momentTime.tz(timezone).format(formatPattern);
  return localTime;
}

export function dateFromLifeTimeLocale(
  daysToLive: number,
  lang: 'en' | 'nb',
  dateActiveTo?: Date
): string {
  const event = dateFromLifeTime(daysToLive, dateActiveTo);

  return timezoneAwareDateFormatter({
    date: event,
    locale: lang,
    timezone: 'Europe/Oslo',
    formatPattern: 'Do MMM YYYY, HH:mm',
  });
}

// Find any element using an API like document.querySelector even if it's not on a page yet!
// Use this function only if you are 100% sure that this element exists - otherwise the function will not resolve
export function getElementByQuerySelector(selector): any {
  let counter = 0;
  const getter = (resolveFn) => {
    if (counter > 25) {
      resolveFn(null);
      return;
    }
    counter++;

    const el = document.querySelector(selector);
    if (el) {
      resolveFn(el);
    } else {
      setTimeout((_) => {
        getter(resolveFn);
      }, 250);
    }
  };
  return new Promise((res) => {
    getter(res);
  });
}

export function smartTrimHelper(val, from?, to?) {
  from = from || 68;
  to = to || from + 10;
  val = val || '';
  let result = '';
  let resultTemp = '';
  const tokens = val.split(' ');

  for (const tok of tokens) {
    resultTemp += tok;
    if (resultTemp.length > to) {
      break;
    }
    resultTemp += ' ';
    result = resultTemp;

    if (resultTemp.length > from) {
      break;
    }
  }
  result = result.trim();
  if (!result.length) {
    result = val.slice(0, from);
  }

  if (result.trim() < val.trim()) {
    result += '...';
  }

  return result;
}
